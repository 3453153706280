import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DefaultImage } from '../../infrastructure/AppConstants';

export class EardrumOption extends Component {
    static displayName = EardrumOption.name;
    constructor(props) {
        super(props);
        let leftValue = "-1";
        let rightValue = "-1";
        let value = this.props.value;
        if (value) {
            var vals = value.split(';');
            if (vals.length == 2) {
                leftValue = vals[0];
                rightValue = vals[1];
            }
        }
        this.state = { leftValue: leftValue, rightValue: rightValue };
        this.handleLeftChange = this.handleLeftChange.bind(this);
        this.handleRightChange = this.handleRightChange.bind(this);
    }

    handleLeftChange(e) {
        let checked = e.target.checked;
        const target = e.target;
        const value = target.value;
        const name = target.name;
        let leftValue = value;
        if (!checked) {
            leftValue = '-1';
        }

        this.setState({ leftValue: leftValue }, () => {
            var handle = this.props.onSelectValueChanged;
            if (handle) {
                var v = this.state.leftValue + ';' + this.state.rightValue;
                handle(v);
            }
        });

    }

    handleRightChange(e) {
        let checked = e.target.checked;
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let rightValue = value;
        if (!checked) {
            rightValue = '-1';
        }

        this.setState({ rightValue: rightValue }, () => {
            var handle = this.props.onSelectValueChanged;
            if (handle) {
                var v = this.state.leftValue + ';' + this.state.rightValue;
                handle(v);
            }
        });

    }

    rendRow(length, option, enclosureTypes, handleLeftChange, handleRightChange, leftValue, rightValue) {
        return (<tr>
            <td rowSpan={length} className="border border-gray-600">
                <span><FormattedMessage id="HardEardrumMaterial" /></span>
            </td>
            <td className="border border-gray-600">
                <span>{option.color}</span>
            </td>
            {enclosureTypes.map(e =>
                <td className="border border-gray-600 text-center leading-none" colSpan="2">
                    <div className="grid grid-cols-2 justify-items-center">
                        <input type="checkbox" onChange={handleLeftChange} value={e.id} checked={e.id === leftValue}></input>
                        <input type="checkbox" onChange={handleRightChange} value={e.id} checked={e.id === rightValue}></input>
                    </div>
                </td>

            )}
        </tr>);
    }

    render() {
        let enclosureTypes = this.props.enclosureTypes;
        console.log(enclosureTypes);
        if (!enclosureTypes) {
            return null;
        }
        let hardEardrumColorOptions = this.props.hardEardrumColorOptions;
        let softEardrumColorOptions = this.props.softEardrumColorOptions;
        let leftValue = this.state.leftValue;
        let rightValue = this.state.rightValue;
        let handleLeftChange = this.handleLeftChange;
        let handleRightChange = this.handleRightChange;

        return (
            <table className="border-collapse w-full h-full text-sm">
                <tbody>
                    <tr>
                        <td className={(this.props.title === 'RIC' ? "bg-blue-300" :
                            "bg-red-300") + " border border-gray-600 w-10 font-bold"}>
                            <span>{this.props.title}</span>
                    </td>
                        <td colSpan={enclosureTypes.length * 2 + 1} className="border border-gray-200 bg-gray-100 px-1 font-bold">
                            <span><FormattedMessage id={this.props.desc} /></span>
                    </td>
                </tr>
                <tr>
                        <td className="border border-gray-200">
                            <span><FormattedMessage id="Material"/></span>
                    </td>
                        <td className="border border-gray-200 pl-1">
                            <span><FormattedMessage id="Color" /></span>
                    </td>
                        {enclosureTypes.map((e, index) =>
                            <td colSpan="2" key={ index} className="border border-gray-200 p-0">
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                            <td colSpan="2">
                                                <img src={e.imageUrl} onError={(e) => { e.target.src = DefaultImage }} className="max-w-10 max-h-10 -my-0 mx-auto" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="text-center">
                                            <span>{e.name}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="flex items-center justify-center">
                                                    <span className="mx-auto"><FormattedMessage id="Left" /></span>
                                                    <span className="mx-auto text-white inline-block bg-blue-500 rounded-full h-4 w-4 lg:h-4 lg:w-4 flex items-center justify-center">L</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex items-center justify-center">
                                                    <span className="mx-auto"><FormattedMessage id="Right" /></span>
                                                    <span className="mx-auto text-white inline-block bg-red-500 rounded-full h-4 w-4 lg:h-4 lg:w-4 flex items-center justify-center">R</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    )}
                </tr>
                {
                    hardEardrumColorOptions.map(function (o, index) {
                        if (index === 0) {
                            //return this.rendRow(hardEardrumColorOptions.length, o, enclosureTypes, this.handleLeftChange, this.handleRightChange, this.state.leftValue, this.state.rightValue);
                            return (<tr key={ index}>
                                <td rowSpan={hardEardrumColorOptions.length} className="border border-gray-200">
                                    <span><FormattedMessage id="HardEardrumMaterial" /></span>
                                </td>
                                <td className="border border-gray-200 pl-1">
                                    <span>{o.color}</span>
                                </td>
                                {enclosureTypes.map((e,index) =>
                                    <td key={ index} className="border border-gray-200 text-center leading-none" colSpan="2">
                                        <div className="grid grid-cols-2 justify-items-center">
                                            <input type="checkbox" onChange={handleLeftChange} value={"0" + ',' + e.id + ',' + o.id} checked={("0" + ',' + e.id + ',' + o.id) === leftValue}></input>
                                            <input type="checkbox" onChange={handleRightChange} value={"0" + ',' + e.id + ',' + o.id} checked={("0" + ',' + e.id + ',' + o.id) === rightValue}></input>
                                        </div>
                                    </td>

                                )}


                            </tr>);
                        }
                        else {
                            return (<tr key={ index}>
                                <td className="border border-gray-200 pl-1">
                                    <span>{o.color}</span>
                                </td>
                                {enclosureTypes.map((e,index) =>
                                    <td key={index} className="border border-gray-200 text-center leading-none" colSpan="2">
                                        <div className="grid grid-cols-2 justify-items-center">
                                            <input type="checkbox" onChange={handleLeftChange} value={"0" + ',' + e.id + ',' + o.id} checked={("0" + ',' + e.id + ',' + o.id) === leftValue}></input>
                                            <input type="checkbox" onChange={handleRightChange} value={"0" + ',' + e.id + ',' + o.id} checked={("0" + ',' + e.id + ',' + o.id) === rightValue}></input>
                                        </div>
                                    </td>

                                )}
                            </tr>);
                        }

                    })
                }
                {
                    softEardrumColorOptions.map(function (o, index) {
                        if (index === 0) {
                            return (<tr key={ index}>
                                <td rowSpan={softEardrumColorOptions.length} className="border border-gray-200">
                                    <span><FormattedMessage id="SoftEardrumMaterial" /></span>
                                </td>
                                <td className="border border-gray-200 pl-1">
                                    <span>{o.color}</span>
                                </td>
                                {enclosureTypes.map((e,index) =>
                                    <td key={ index} className="border border-gray-200 text-center leading-none" colSpan="2">
                                        <div className="grid grid-cols-2 justify-items-center">
                                            <input type="checkbox" onChange={handleLeftChange} value={"1" + ',' + e.id + ',' + o.id} checked={("1" + ',' + e.id + ',' + o.id) === leftValue}></input>
                                            <input type="checkbox" onChange={handleRightChange} value={"1" + ',' + e.id + ',' + o.id} checked={("1" + ',' + e.id + ',' + o.id) === rightValue}></input>
                                        </div>
                                    </td>

                                )}
                            </tr>);
                        }
                        else {
                            return (<tr key={ index}>
                                <td className="border border-gray-200 pl-1">
                                    <span>{o.color}</span>
                                </td>
                                {enclosureTypes.map((e, index) =>
                                    <td key={ index} className="border border-gray-200 text-center leading-none" colSpan="2">
                                        <div className="grid grid-cols-2 justify-items-center">
                                            <input type="checkbox" onChange={handleLeftChange} value={"1" + ',' + e.id + ',' + o.id} checked={("1" + ',' + e.id + ',' + o.id) === leftValue}></input>
                                            <input type="checkbox" onChange={handleRightChange} value={"1" + ',' + e.id + ',' + o.id} checked={("1" + ',' + e.id + ',' + o.id) === rightValue}></input>
                                        </div>
                                    </td>

                                )}
                            </tr>);
                        }

                    })
                    }
                </tbody>
            </table>

        );
    }
}