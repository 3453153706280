import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { OrderTypeSelectionDialog } from './OrderTypeSelectionDialog';
import authService from '../api-authorization/AuthorizeService'

export class CreateOrderButton extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false, isAllow: false };
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    async componentDidMount() {
        if (this.props.module && this.props.action) {
            const isAllow = await authService.isAllow(this.props.module, this.props.action);
            //console.log(isAllow);
            this.setState({
                isAllow: isAllow
            });
        }
    }
    onSubmit(id) {
        this.setState({ show: false });
        this.props.history.push("/order/EditOrder/" + id);
    }

    onClose() {
        this.setState({ show: false });
    }

    handleButtonClick(e) {
        this.setState({ show: true });
    }



    render() {
        var url = this.props.url;
        return this.state.isAllow && (
           /* <div>*/
                <button onClick={this.handleButtonClick} className="hover:bg-blue-500 hover:text-blue-800 group flex items-center rounded-md bg-blue-600 text-white text-sm font-medium px-4 py-2 w-20">
                    <svg className="group-hover:text-blue-600 text-blue-100 mr-2" width="12" height="20" fill="currentColor">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z" />
                    </svg>
                    <FormattedMessage id="Create" />
                    <OrderTypeSelectionDialog show={this.state.show} onClose={this.onClose} onSubmit={this.onSubmit} />
                </button>
             
          /*  </div>*/
        );
    }
}

