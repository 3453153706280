import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { BaseBarcode } from '../../controls/BaseBarcode';
import { EardrumOptions } from './EardrumOptions';
import { EardrumCustomerInfo } from './EardrumCustomerInfo';
import  WsaLogo from '../../Images/WSA.png';
//import './print.css';

export class OtherEarmoldView extends Component {   
    constructor(props) {
        super(props);                
    }    

    render() {
        let data = this.props.model;        
        if (!data) {
            return null;
        }

        return (
            <div id="pEarmoldDiv" className="bg-white shadow overflow-hidden sm:rounded-sm text-sm">
                <div className="w-full">
                    <form>
                        <div className="px-8 py-6" id="orderForm">
                            <div className="w-full overflow-hidden">
                                <div className="grid grid-cols-3">
                                    <div>
                                        <BaseBarcode value={data.orderNum} />
                                    </div>
                                    <div className="text-center text-4xl font-bold">
                                        <span className="text-purple-600 mr-2">WSA</span><span className="text-gray-500"><FormattedMessage id="EardrumOrderTitle" /></span>
                                    </div>

                                    <div className="flex justify-between">
                                        <div className="w-48">
                                            <div className="bg-gray-900 text-white px-2 font-bold"><FormattedMessage id="OrderNum" /></div>
                                            <div className="bg-gray-200 h-8 px-1 pt-1">{data.infoId}</div>
                                        </div>
                                        <div><img className="mt-2" src={WsaLogo} /></div>
                                    </div>
                                </div>

                            </div>
                            <EardrumCustomerInfo model={data.eardrumCustomerInfo} dealerInfo={data.dealerInfo} />
                            <EardrumOptions model={data.eardrumInfo}></EardrumOptions>
                        </div>


                    </form>
                </div>
            </div>
        );

    }

}
