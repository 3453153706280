import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Chart from 'react-apexcharts';


export class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: 'apexchart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
            }]
        }
    }

    render() {
        return (<div className="pt-6 px-4">
            <div className="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
                <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                    <Chart options={this.state.options} series={this.state.series} type="bar" width={500} height={320} />
                </div>
            </div>
            <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
            </div>
            </div>);
    }
}