import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class SalesCodeComboBox extends Component {

    static displayName = SalesCodeComboBox.name;

    constructor(props) {

        super(props);


        this.state = { items: props.items, selectedValue: props.selectedValue };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {

        var handle = this.props.onSelectValueChanged;
        if (handle) {

            this.setState({ selectedValue: e });

            handle(e)

        }

    }

    render() {

        const items = this.props.items;
        if (!items || items.length <= 0) {
            return <label className="mx-3 place-self-center text-yellow-600  h-8"><FormattedMessage id="SelectDevliverCode"/></label>;
        }

        return (

            <select className="mx-3 w-36 h-8" {...this.props} value={this.state.selectedValue} onChange={this.handleChange}>

                {items.map(item => <option value={item}>{item}</option>)}

            </select>

        );

    }

}
