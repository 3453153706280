import React, { Component } from 'react';
import { OrderForm } from './OrderForm';

export class EditOrder extends Component {
    static displayName = EditOrder.name;

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <OrderForm showLoading={this.showLoading} closeLoading={this.closeLoading} isNew={false} id={this.props.match.params["id"]} />
        );
    }
}
