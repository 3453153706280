import { PrinterIcon } from '@heroicons/react/outline';
import domtoimage from 'dom-to-image';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from "jspdf";
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from "react-to-print";
import { AuthorityActions, AuthorityModules } from '../api-authorization/ApiAuthorizationConstants';
import  authService  from '../api-authorization/AuthorizeService';
import { BackButton } from '../controls/BackButton';
import Toast from '../controls/BaseToast';
import { Loading } from '../controls/Loading';
import { OrderStatus, ProductBrandTemplate, ProductCategory } from '../infrastructure/AppConstants';
import { OtherCustomerMadeView } from "./CustomerMade/OtherCustomerMadeView";
import { OtherEarmoldView } from "./eardrum/OtherEarmoldView";
import orderService, { OrderCommitAction, OrderResultStatus } from './OrderService';
import ScanFileDialog from './ScanFileDialog';
import { WidexCustomMade } from './widex/CustomMade';
import { WidexEardrum } from './widex/Eardrum';

export class OrderForm extends Component {
    static displayName = OrderForm.name;

    constructor(props) {
        super(props);
        this.state = { status: -1, model: null, isAllowEdit: false, isAllowExport: false, isAllowRetreat: false, open: false, buttonContainerWidthStyle: "" };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleExportPdf = this.handleExportPdf.bind(this);
        this.openUploadScanFile = this.openUploadScanFile.bind(this);
        this.closeScanFileDialog = this.closeScanFileDialog.bind(this);
       
    }

    async componentDidMount() {
        var id = this.props.id;
        if (id) {
            this.locaData(id);
        }
        const edit = await authService.isAllow(AuthorityModules.Order, AuthorityActions.Edit);
        const exportPdf = await authService.isAllow(AuthorityModules.Order, AuthorityActions.Export);
        const retreat = await authService.isAllow(AuthorityModules.Order, AuthorityActions.Retreat);
        this.setState({
            isAllowEdit: edit, isAllowExport: exportPdf, isAllowRetreat: retreat
        });
        this.timeObj = setTimeout(this.handleResize,1000)
        window.addEventListener("resize", this.handleResize);
    }


    handleResize = () => {
        const widthStyle = { width: 'calc(100% - ' + document.getElementById("sidebar").clientWidth + 'px)' }
      //  console.log(widthStyle)
        this.setState({ buttonContainerWidthStyle: widthStyle })
    }
    async locaData(id) {
        var order = await orderService.getOrderInfoFromServer(id);
        this.setState({ model: order });

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        clearTimeout(this.timeObj);
    }


    hadleRoleChange(event) {
        event.preventDefault();
        const target = event.target;
        const value = target.value;

        this.setState({
            selectedRoleId: value
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        var name = e.target.name;
        var action = OrderCommitAction.Save;
        switch (name) {
            case 'Submit':
                // action = OrderCommitAction.Submit;
                break;
            case 'Retreat':
                action = OrderCommitAction.Retreat;
                break;
        }

        await orderService.commit(action);
        window.history.back(-1);
    }

    async openUploadScanFile(e) {
        e.preventDefault();
        // console.log(this.state.model);
        const order = this.state.model;
        if (order.productBrand == ProductBrandTemplate.Widex) {
            if (order.productCategory == ProductCategory.CustomMade) {
                var result = this.widexCustomMade.onCheck();
                if (!result.status) {
                    Toast.warn(result.message);
                    return;
                }
            } else if (order.productCategory == ProductCategory.Eardrum) {
                var result = this.widexEardrum.onCheck();
                if (!result.status) {
                    Toast.warn(result.message);
                    return;
                }
            }
        }
        else if (order.productBrand == ProductBrandTemplate.WSA) {
            var name = e.target.name;
            var action = OrderCommitAction.Save;
            switch (name) {
                case 'Submit':
                    action = OrderCommitAction.Submit;
                    break;
                case 'Retreat':
                    action = OrderCommitAction.Retreat;
                    break;
            }
            let result = await orderService.commit(action, this.scanFileDialog.state.files);
            if (result.status == OrderResultStatus.Fail && result.message) {
                Toast.warn(result.message);
                return;
            }
        }
        this.scanFileDialog.open();
    }
    closeScanFileDialog(e) {
        this.scanFileDialog.close();
    }
    handleExportPdf(e) {
        e.preventDefault();
        this.loadingRef.showLoading();
        const name = this.state.model.orderNum + ".pdf";
        const close = this.loadingRef.closeLoading;
        const productCategory = this.state.model.productCategory;
        let orientation = this.state.model.productBrand == ProductBrandTemplate.Widex ? "portrait" : 'landscape';

        if (this.state.model.productBrand == ProductBrandTemplate.Widex) {
            domtoimage.toPng(document.getElementById('orderForm'), {
                filter: function (node) {
                    try {
                        if (node.nodeType === 1) {
                            if (node.tagName === "INPUT" && ("" + node.type).toLowerCase() === "checkbox") {
                                if (node.checked) {
                                    node.setAttribute('checked', true);
                                } else {
                                    node.removeAttribute('checked');
                                }
                            }
                            else if (node.tagName === "SELECT" && node.selectedIdx != -1) {
                                var options = node.childNodes; // Assumption!
                                var optionCount = 0;
                                var selectedIdx = node.selectedIndex;
                                for (var i = 0; i < options.length; i++) {
                                    var option = options[i]; // Maybe not an option
                                    if (option.tagName === "OPTION") {
                                        if (optionCount === selectedIdx) {
                                            options[i].setAttribute('selected', true);
                                        }
                                        else {
                                            options[i].removeAttribute('selected');
                                        }
                                        optionCount++;
                                    }
                                }
                            }
                        }
                    } catch (e) {

                    }

                    return true;
                }
            }).then(function (dataUrl) {
                const pdf = new jsPDF({
                    orientation: orientation
                });
                pdf.addImage(dataUrl, 'PNG', 0, 0);
                pdf.save(name);
                close();
            }).catch(function (error) {
                console.error('oops, something went wrong!', error);
                close();
            });
        }
        else {
            htmlToImage.toCanvas(document.getElementById('orderForm'), {
                filter: function (node) {
                    try {
                        if (node.nodeType === 1) {
                            if (node.tagName === "SELECT" && node.selectedIdx != -1) {
                                var options = node.childNodes; // Assumption!
                                var optionCount = 0;
                                var selectedIdx = node.selectedIndex;
                                for (var i = 0; i < options.length; i++) {
                                    var option = options[i]; // Maybe not an option
                                    if (option.tagName === "OPTION") {
                                        if (optionCount === selectedIdx) {
                                            options[i].setAttribute('selected', true);
                                        }
                                        else {
                                            options[i].removeAttribute('selected');
                                        }
                                        optionCount++;
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        console.error('toCanvas, filter ex:', e);
                    }

                    return true;
                }
            })
                .then(function (canvas) {
                    try {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF({
                            orientation: 'landscape',
                        });
                        const imgProps = pdf.getImageProperties(imgData);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                        if (productCategory == ProductCategory.Eardrum) {
                            pdf.addPage();
                            var page2Img = new Image()
                            page2Img.src = "/eardrum.png";
                            //  console.log(page2Img);
                            pdf.addImage(page2Img, 'PNG', 0, 0, pdfWidth, pdfHeight);
                        }

                        pdf.save(name);
                    }
                    catch (e) {
                        console.error('toCanvas, to pdf ex:', e);
                    }

                    close();
                });

        }


    }
    handleBack(e) {
        e.preventDefault();
        window.history.back(-1);
    }

    renderButtonBar(data) {
        if (data == null||!this.state.buttonContainerWidthStyle) { return null; }
        var attachFile = [];
        if (data.attachmentFile) {
            attachFile = data.attachmentFile.split(',');
        }
        switch (data.status) {
            case OrderStatus.Draft:
            case OrderStatus.Retreat:
                return (
                    <div className="bg-gray-50 text-center w-full fixed bottom-0 py-4" style={this.state.buttonContainerWidthStyle}>
                        {this.state.isAllowEdit && <button name="Save" onClick={this.handleSubmit}
                            className="inline-block mx-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        ><FormattedMessage id="Save" /></button>}
                        {this.state.isAllowEdit && <button name="Submit" onClick={this.openUploadScanFile}
                            className="inline-block mx-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        ><FormattedMessage id="Submit" /></button>}
                        <BackButton />
                    </div>
                );
            case OrderStatus.Submitted:
                return (<div className="bg-gray-50 text-center fixed bottom-0 w-full  py-4" style={this.state.buttonContainerWidthStyle}>
                    {this.state.isAllowRetreat && <button name="Retreat" onClick={this.handleSubmit}
                        className="mx-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    ><FormattedMessage id="Retreat" /></button>}
                    <BackButton />
                    {attachFile.map((item, index) => { return (<a key={index} className="mr-5 text-blue-600 text-sm" href={item}>{index + 1}.{item.substring(item.lastIndexOf('/') + 1)}</a>) })}
                    <Loading onRef={(ref) => { this.loadingRef = ref }} />
                </div>);
            case OrderStatus.Finish:
                return (<div className="bg-gray-50 text-center fixed bottom-0 w-full  py-4" style={this.state.buttonContainerWidthStyle}>
                    {this.state.isAllowExport && <button name="ExportPdf" onClick={this.handleExportPdf}
                        className="mx-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    ><FormattedMessage id="ExportPdf" /></button>}
                    <BackButton />
                    {attachFile.map((item, index) => { return (<a className="mr-5 text-blue-600 text-sm" href={item}>{index + 1}.{item.substring(item.lastIndexOf('/') + 1)}</a>) })}
                    <Loading onRef={(ref) => { this.loadingRef = ref }} />
                </div>);

        }
        return null;
    }

    renderOtherCustomerMadeView(data, buttonBar) {
        return (
            <div id="container" className="">
                <div className="flex justify-end pt-1 px-2">
                    <ReactToPrint
                        trigger={() => <div className="fixed cursor-pointer mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full hover:bg-gray-300 sm:mx-0 sm:h-10 sm:w-10" title="print">
                            <PrinterIcon className="h-6 w-6 hover:text-indigo-600  text-gray-400" aria-hidden="true" title="Print" /></div>}
                        content={() => this.componentRef}
                        pageStyle="@page { size: landscape; margin:0px; } "
                        onBeforeGetContent={() => {
                            
                            return htmlToImage.toCanvas(document.getElementById('pDiv'), {
                                filter: function (node) {
                                    try {
                                        if (node.nodeType === 1) {
                                            if (node.tagName === "INPUT" && ("" + node.type).toLowerCase() === "checkbox") {
                                                if (node.checked) {
                                                    node.setAttribute('checked', true);
                                                } else {
                                                    node.removeAttribute('checked');
                                                }
                                            }
                                            else if (node.tagName === "INPUT" && ("" + node.type).toLowerCase() === "radio") {
                                                if (node.checked) {
                                                    node.setAttribute('checked', true);
                                                } else {
                                                    node.removeAttribute('checked');
                                                }
                                            }
                                            else if (node.tagName === "SELECT" && node.selectedIdx != -1) {
                                                var options = node.childNodes; // Assumption!
                                                var optionCount = 0;
                                                var selectedIdx = node.selectedIndex;
                                                for (var i = 0; i < options.length; i++) {
                                                    var option = options[i]; // Maybe not an option
                                                    if (option.tagName === "OPTION") {
                                                        if (optionCount === selectedIdx) {
                                                            options[i].setAttribute('selected', true);
                                                        }
                                                        else {
                                                            options[i].removeAttribute('selected');
                                                        }
                                                        optionCount++;
                                                    }
                                                }
                                            }
                                        }
                                    } catch (e) {

                                    }

                                    return true;
                                }
                            }).then(function (canvas) {                                   
                                    var div = document.getElementById('printPreview');
                                    div.appendChild(canvas);
                                    
                                });
                        }}
                        onAfterPrint={() => {
                            var div = document.getElementById('printPreview');
                            div.innerHTML = "";                            
                        }}
                    />
                </div>
                <OtherCustomerMadeView model={data} />
                <div ref={(el) => (this.componentRef = el)} id="printPreview">                   
                </div>
                {buttonBar}
                <ScanFileDialog onRef={(ref) => { this.scanFileDialog = ref }} id={this.props.id} open={this.state.open} onClose={this.closeScanFileDialog}></ScanFileDialog>
            </div>
        );
    }

    OtherEardrumView(data, buttonBar) {
        return (
            <div id="container">
                <div className="flex justify-end pt-1 px-2">
                    <ReactToPrint
                        trigger={() => <div className="fixed cursor-pointer mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full hover:bg-gray-300 sm:mx-0 sm:h-10 sm:w-10" title="print">
                            <PrinterIcon className="h-6 w-6 hover:text-indigo-600  text-gray-400" aria-hidden="true" title="Print" /></div>}
                        content={() => this.componentRef}
                        pageStyle="@page { size: landscape; margin:0px; } "
                        onBeforeGetContent={() => {

                            return htmlToImage.toCanvas(document.getElementById('pEarmoldDiv'), {
                                filter: function (node) {
                                    try {
                                        if (node.nodeType === 1) {
                                            if (node.tagName === "INPUT" && ("" + node.type).toLowerCase() === "checkbox") {
                                                if (node.checked) {
                                                    node.setAttribute('checked', true);
                                                } else {
                                                    node.removeAttribute('checked');
                                                }
                                            }
                                            else if (node.tagName === "SELECT" && node.selectedIdx != -1) {
                                                var options = node.childNodes; // Assumption!
                                                var optionCount = 0;
                                                var selectedIdx = node.selectedIndex;
                                                for (var i = 0; i < options.length; i++) {
                                                    var option = options[i]; // Maybe not an option
                                                    if (option.tagName === "OPTION") {
                                                        if (optionCount === selectedIdx) {
                                                            options[i].setAttribute('selected', true);
                                                        }
                                                        else {
                                                            options[i].removeAttribute('selected');
                                                        }
                                                        optionCount++;
                                                    }
                                                }
                                            }
                                        }
                                    } catch (e) {

                                    }

                                    return true;
                                }
                            }).then(function (canvas) {
                                var div = document.getElementById('printPreview');
                                div.appendChild(canvas);

                            });
                        }}
                        onAfterPrint={() => {
                            var div = document.getElementById('printPreview');
                            div.innerHTML = "";
                        }}
                    />
                </div>
                <OtherEarmoldView model={data} />
                {buttonBar}
                <ScanFileDialog onRef={(ref) => { this.scanFileDialog = ref }} id={this.props.id} open={this.state.open} onClose={this.closeScanFileDialog}></ScanFileDialog>
                <div ref={(el) => (this.componentRef = el)} id="printPreview">
                </div>
            </div>
        );
    }

    WidexEardrumView(data, buttonBar) {
        return (
            <div id="container">
                <div className="flex justify-end pt-1 px-6">
                    <ReactToPrint
                        trigger={() => <div className="fixed cursor-pointer mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full hover:bg-gray-300 sm:mx-0 sm:h-10 sm:w-10" title="print">
                            <PrinterIcon className="h-6 w-6 hover:text-indigo-600  text-gray-400" aria-hidden="true" title="Print" /></div>}
                        content={() => this.componentRef}
                        pageStyle="@page { size: portrait; margin:0px; } "
                    />
                </div>
                <WidexEardrum ref={(el) => (this.componentRef = el)} model={data} onRef={(ref) => { this.widexEardrum = ref }} />
                {buttonBar}
                <ScanFileDialog onRef={(ref) => { this.scanFileDialog = ref }} id={this.props.id} open={this.state.open} onClose={this.closeScanFileDialog}></ScanFileDialog>
            </div>
        );
    }

    WidexCustomerMadeView(data, buttonBar) {
        return (
            <div id="container">
                <div className="flex justify-end pt-1 px-6">
                    <ReactToPrint
                        trigger={() => <div className="fixed cursor-pointer mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full hover:bg-gray-300 sm:mx-0 sm:h-10 sm:w-10" title="print">
                            <PrinterIcon className="h-6 w-6 hover:text-indigo-600  text-gray-400" aria-hidden="true" title="Print" /></div>}
                        content={() => this.componentRef}
                        pageStyle="@page { size: portrait; margin:0px; } "
                    />
                </div>

                <WidexCustomMade ref={(el) => (this.componentRef = el)} model={data} onRef={(ref) => { this.widexCustomMade = ref }} />

                {buttonBar}
                <ScanFileDialog onRef={(ref) => { this.scanFileDialog = ref }} id={this.props.id} open={this.state.open} onClose={this.closeScanFileDialog}></ScanFileDialog>
            </div>
        );
    }

    render() {
        const data = this.state.model;
        if (data == null) {
            return null;
        }
        let buttonBar = this.renderButtonBar(data);
        if (data.productBrand == ProductBrandTemplate.WSA) {

            if (data.productCategory == ProductCategory.Eardrum) {
                return this.OtherEardrumView(data, buttonBar);
            }
            else {
                return this.renderOtherCustomerMadeView(data, buttonBar);
            }
        }
        else if (data.productBrand == ProductBrandTemplate.Widex) {

            if (data.productCategory == ProductCategory.Eardrum) {
                return this.WidexEardrumView(data, buttonBar);
            }
            else {
                return this.WidexCustomerMadeView(data, buttonBar);
            }
        }
    }
}
