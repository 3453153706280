import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import authService from '../api-authorization/AuthorizeService'

export class Profile extends Component {
    static displayName = Profile.name;

    constructor(props) {
        super(props);
        this.state = { name: '', fullName: '', phone: '', email: '' };
    }

    componentDidMount() {
        var id = this.props.match.params["id"];
        if (id) {
            this.locaData(id);
        }
    }
    async locaData(id) {
        const token = await authService.getAccessToken();
        fetch('account/getaccount/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    name: data.name,
                    fullName: data.fullName,
                    phone: data.cellPhone,
                    email: data.email
                });
            });
    }

    render() {

        return (
            <div className="max-w-7xl mx-auto py-6 sm:px-3 lg:px-4">
                <div className="max-w-4xl mx-auto">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="grid grid-cols-3">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900"><FormattedMessage id="PersonalInformationHead1" /></h3>
                            </div>
                            <div className="px-4 py-5 sm:px-6 right">
                            </div>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500"><FormattedMessage id="AccountName" /></dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.state.name}</dd>
                                </div>
                                <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500"><FormattedMessage id="Fullname" /></dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.state.fullName}</dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500"><FormattedMessage id="Email" /></dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.state.email}</dd>
                                </div>
                                <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500"><FormattedMessage id="Phone" /></dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.state.phone}</dd>
                                </div>

                            </dl>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
