import React, { Component } from 'react';
import { Contact } from './partial/Contact';
import { Dealer } from './partial/Dealer';
import { Address } from './partial/Address';
import { OrderNum } from './partial/OrderNum';
import { Customer } from './partial/Customer';
import { Signature } from './partial/Signature';
import orderService, { DirectionConsts } from '../OrderService';
import { ProductOptionCategory, CheckMessageId } from '../../infrastructure/AppConstants';
import authService from '../../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
//import './print.css';

export class WidexEardrum extends Component {
    static displayName = WidexEardrum.name;

    constructor(props) {
        super(props);
        this.state = {
            model: this.props.model,
            spiracleOptions: [],
            spiracleSizeOptions: [],
            otherMadeOptions: [],
            leftMachineType: '',
            leftMachineCode: '',
            rightMachineType: '',
            rightMachineCode: '',
            bteEardrumMaterialOptions: [],
            bteEardrumStyleOptions: [],
            bteEarphoneDiameterOptions: [],
            bteEarphoneSizeOptions: [],
            bteEarphoneOtherSize: '',
            bteEarphoneStyleOptions: [],
            receiverMaterialOptions: [],
            receiverStyleOptions: [],
            soundOutletTypeOptions: [],
            receiverPowerOptions: [],
            earHookSizeOptions: [],
            earHookOtherSize: ''
        }
        this.onSpiracleChange = this.onSpiracleChange.bind(this);
        this.onSpiracleSizeChange = this.onSpiracleSizeChange.bind(this);
        this.onOtherMadeChange = this.onOtherMadeChange.bind(this);
        this.onBteEardrumMaterialChange = this.onBteEardrumMaterialChange.bind(this);
        this.onBteEardrumStyleChange = this.onBteEardrumStyleChange.bind(this);
        this.onBteEarphoneDiameterChange = this.onBteEarphoneDiameterChange.bind(this);
        this.onBteEarphoneSizeChange = this.onBteEarphoneSizeChange.bind(this);
        this.onBteEarphoneStyleChange = this.onBteEarphoneStyleChange.bind(this);
        this.onChange = this.onChange.bind(this);

        this.onReceiverMaterialChange = this.onReceiverMaterialChange.bind(this);
        this.onReceiverStyleChange = this.onReceiverStyleChange.bind(this);
        this.onSoundOutletTypeChange = this.onSoundOutletTypeChange.bind(this);
        this.onReceiverPowerChange = this.onReceiverPowerChange.bind(this);
        this.onEarHookSizeChange = this.onEarHookSizeChange.bind(this);
        this.sortByName = this.sortByName.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
        this.loadWidexCustomizedOptions();
        this.updateBteMachineState();

        this._subscription = orderService.subscribe(() => this.orderCommitting());

    }
    onCheck() {
        const dealer = this.dealerChild.state;
        if (!dealer.deliverAccountCode || !dealer.deliverAccountCode.label) {
            return { status: false, message: CheckMessageId.DeliverAccountCodeRequired }
        }
        const address = this.addressChild.state;
        if (!address.address) {
            return { status: false, message: CheckMessageId.AddressRequired }
        }
        const customer = this.customerChild.state;
        if (!customer.name) {
            return { status: false, message: CheckMessageId.CustomerNameRequired }
        }
        if (!customer.age) {
            return { status: false, message: CheckMessageId.CustomerAgeRequired }
        }
        if (!this.state.leftMachineType) {
            return { status: false, message: CheckMessageId.LeftMachineTypeRequired }
        }
        if (!this.state.leftMachineCode) {
            return { status: false, message: CheckMessageId.LeftMachineCodeRequired }
        }
        if (!this.state.rightMachineType) {
            return { status: false, message: CheckMessageId.RightMachineTypeRequired }
        }
        if (!this.state.rightMachineCode) {
            return { status: false, message: CheckMessageId.RightMachineCodeRequired }
        }
        var bteEardrumMaterialOptions = this.state.bteEardrumMaterialOptions;
        if (bteEardrumMaterialOptions.filter(x => x.leftSelected).length == 0 || bteEardrumMaterialOptions.filter(x => x.rightSelected).length == 0)
        {
            return { status: false, message: CheckMessageId.BTEEardrumMaterialRequired }
        }
        var bteEardrumStyleOptions = this.state.bteEardrumStyleOptions;
        if (bteEardrumStyleOptions.filter(x => x.leftSelected).length == 0 || bteEardrumStyleOptions.filter(x => x.rightSelected).length == 0) {
            return { status: false, message: CheckMessageId.BTEEardrumStyleRequired }
        }

        var bteEarphoneDiameterOptions = this.state.bteEarphoneDiameterOptions;
        if (bteEarphoneDiameterOptions.filter(x => x.leftSelected).length == 0 || bteEarphoneDiameterOptions.filter(x => x.rightSelected).length == 0) {
            return { status: false, message: CheckMessageId.BTEEarphoneDiameterRequired }
        }
        var bteEarphoneSizeOptions = this.state.bteEarphoneSizeOptions;
        if (bteEarphoneSizeOptions.filter(x => x.selected).length == 0) {
            return { status: false, message: CheckMessageId.BTEEarphoneSizeRequired }
        }
        var bteEarphoneStyleOptions = this.state.bteEarphoneStyleOptions;
        if (bteEarphoneStyleOptions.filter(x => x.leftSelected).length == 0 || bteEarphoneStyleOptions.filter(x => x.rightSelected).length == 0) {
            return { status: false, message: CheckMessageId.BTEEarphoneStyleRequired }
        }
        var receiverMaterialOptions = this.state.receiverMaterialOptions;
        if (receiverMaterialOptions.filter(x => x.leftSelected).length == 0 || receiverMaterialOptions.filter(x => x.rightSelected).length == 0) {
            return { status: false, message: CheckMessageId.ReceiverMaterialRequired }
        }
        var receiverPowerOptions = this.state.receiverPowerOptions;
        if (receiverPowerOptions.filter(x => x.leftSelected).length == 0 || receiverPowerOptions.filter(x => x.rightSelected).length == 0) {
            return { status: false, message: CheckMessageId.ReceiverPowerRequired }
        }
        var receiverStyleOptions = this.state.receiverStyleOptions;
        if (receiverStyleOptions.filter(x => x.leftSelected).length == 0 || receiverStyleOptions.filter(x => x.rightSelected).length == 0) {
            return { status: false, message: CheckMessageId.ReceiverStyleRequired }
        }
        var earHookSizeOptions = this.state.earHookSizeOptions;
        if (earHookSizeOptions.filter(x => x.selected).length == 0) {
            return { status: false, message: CheckMessageId.EarHookSizeRequired }
        }
        var otherMadeOptions = this.state.otherMadeOptions;
        if (otherMadeOptions.filter(x => x.leftSelected).length == 0 || otherMadeOptions.filter(x => x.rightSelected).length == 0) {
            return { status: false, message: CheckMessageId.OtherMadeRequired }
        }
        return { status: true}
    }
    async loadWidexCustomizedOptions() {
        const token = await authService.getAccessToken();
        const response = await fetch('ProductOption/GetWidexEardrumOptions', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        const [otherMade, spiracleType, spiracleSize, bteEardrumMaterial, bteEardrumStyle, bteEarphoneDiameter, bteEarphoneSize, bteEarphoneStyle, receiverMaterial, receiverStyle, soundOutletType, receiverPower, earHookSize] = [[], [], [], [], [], [], [], [], [], [], [], [], []];

        data.filter(x => x.optionCategory == ProductOptionCategory.BTEEardrumMaterial).forEach((item) => {
            bteEardrumMaterial.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.BTEEardrumStyle).forEach((item) => {
            bteEardrumStyle.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.BTEEarphoneDiameter).forEach((item) => {
            bteEarphoneDiameter.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        const bteEarphoneSizeTemp = [];
        data.filter(x => x.optionCategory == ProductOptionCategory.BTEEarphoneSize).forEach((item) => {
            if (isNaN(parseFloat(item.name))) {
                bteEarphoneSizeTemp.push({ name: item.name, value: item.id, selected: false });
            } else {
                bteEarphoneSize.push({ name: item.name, value: item.id, selected: false });
            }
        });
        bteEarphoneSize.sort(this.sortByName);
        bteEarphoneSizeTemp.forEach((item) => {
            bteEarphoneSize.push(item);
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.BTEEarphoneStyle).forEach((item) => {
            bteEarphoneStyle.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.ReceiverMaterial).forEach((item) => {
            receiverMaterial.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.ReceiverStyle).forEach((item) => {
            receiverStyle.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.SoundOutletType).forEach((item) => {
            soundOutletType.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.ReceiverPower).forEach((item) => {
            receiverPower.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        const earHookSizeTemp = [];
        data.filter(x => x.optionCategory == ProductOptionCategory.EarHookSize).forEach((item) => {
            if (isNaN(parseFloat(item.name))) {
                earHookSizeTemp.push({ name: item.name, value: item.id, selected: false });
            } else {
                earHookSize.push({ name: item.name, value: item.id, selected: false });
            }
        });
        earHookSize.sort(this.sortByName);
        earHookSizeTemp.forEach((item) => {
            earHookSize.push(item);
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.EardrumOtherMade).forEach((item) => {
            otherMade.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        data.filter(x => x.optionCategory == ProductOptionCategory.EardrumSpiracle).forEach((item) => {
            spiracleType.push({ name: item.name, value: item.id, leftSelected: false, rightSelected: false });
        });
        const spiracleSizeTemp = [];
        data.filter(x => x.optionCategory == ProductOptionCategory.EardrumSpiracleSize).forEach((item) => {
            if (isNaN(parseFloat(item.name))) {
                spiracleSizeTemp.push({ name: item.name, value: item.id, selected: false });
            } else {
                spiracleSize.push({ name: item.name, value: item.id, selected: false });
            }
        });
        spiracleSize.sort(this.sortByName);
        spiracleSizeTemp.forEach((item) => {
            spiracleSize.push(item);
        });

        this.setState({
            spiracleOptions: spiracleType,
            spiracleSizeOptions: spiracleSize,
            otherMadeOptions: otherMade,
            bteEardrumMaterialOptions: bteEardrumMaterial,
            bteEardrumStyleOptions: bteEardrumStyle,
            bteEarphoneDiameterOptions: bteEarphoneDiameter,
            bteEarphoneSizeOptions: bteEarphoneSize,
            bteEarphoneStyleOptions: bteEarphoneStyle,
            receiverMaterialOptions: receiverMaterial,
            receiverStyleOptions: receiverStyle,
            soundOutletTypeOptions: soundOutletType,
            receiverPowerOptions: receiverPower,
            earHookSizeOptions: earHookSize
        });
        this.updateSpiracleTypeState();
        this.updateSpiracleSizeState();
        this.updateOtherCustomMadeState();
        this.updateBteEardrumMaterialState();
        this.updateBteEardrumStyleState();
        this.updateBteEarphoneDiameterState();
        this.updateBteEarphoneStyleState();
        this.updateBteEarphoneSizeState();
        this.updateReceiverMaterialState();
        this.updateReceiverStyleState();
        this.updateSoundOutletTypeState();
        this.updateReceiverPowerState();
        this.updateEarHookSizeState();
    }
    sortByName(a, b) {
        return parseFloat(a.name) - parseFloat(b.name)
    }
    orderCommitting() {
        const dealer = this.dealerChild.state;
        const customer = this.customerChild.state;
        const address = this.addressChild.state;
        const order = this.state.model;
        order.customerInfo.customerName = customer.name;
        order.customerInfo.customerAge = customer.age;
        order.hearingLossData = customer.hearingLeft.join(',') + ";" + customer.hearingRight.join(',');
        order.specialRequirements = customer.specialRequirements;
        order.dealerInfo.deliverAccountCode = dealer.deliverAccountCode ? dealer.deliverAccountCode.label : "";
        order.dealerInfo.address = address.address;
        order.dealerInfo.phone = address.phone;
        order.dealerInfo.addressee = address.addressee;
        order.dealerInfo.widexFitterName = dealer.fitter;
        order.dealerInfo.widexFitterPhone = dealer.fitterPhone;
        order.widexOptions.customerEarOther = customer.customerEarOther;
        order.widexOptions.customerHave = customer.customerHaveSelected + "," + customer.customerHaveType + "," + customer.customerHaveCode;
        order.widexOptions.customerEarDesc = customer.customerEarDescOptions.filter(x => x.selected).map(x => { return x.value }).join(',');
        order.widexOptions.customerWearingHistory = customer.customerWearingHistoryOptions.filter(x => x.selected).map(x => { return x.value }).join(',');

        order.widexOptions.spiracleSize = this.state.spiracleSizeOptions.filter(x => x.selected).map(x => { return x.value }).join(',');
        order.widexOptions.spiracleType = this.state.spiracleOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.spiracleOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.otherMadeOptions = this.state.otherMadeOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.otherMadeOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.bteType = this.state.leftMachineType + "," + this.state.leftMachineCode + ";"
            + this.state.rightMachineType + "," + this.state.rightMachineCode;
        order.widexOptions.bteEardrumMaterial = this.state.bteEardrumMaterialOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.bteEardrumMaterialOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.bteEardrumStyle = this.state.bteEardrumStyleOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.bteEardrumStyleOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');

        order.widexOptions.bteEarphoneDiameter = this.state.bteEarphoneDiameterOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.bteEarphoneDiameterOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.bteEarphoneStyle = this.state.bteEarphoneStyleOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.bteEarphoneStyleOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.bteEarphoneSize = this.state.bteEarphoneSizeOptions.filter(x => x.selected).map(x => { return x.value }).join(',');

        order.widexOptions.receiverMaterial = this.state.receiverMaterialOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.receiverMaterialOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.receiverStyle = this.state.receiverStyleOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.receiverStyleOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.receiverPower = this.state.receiverPowerOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.receiverPowerOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.soundOutletType = this.state.soundOutletTypeOptions.filter(x => x.leftSelected).map(x => { return x.value }).join(',') + ";" + this.state.soundOutletTypeOptions.filter(x => x.rightSelected).map(x => { return x.value }).join(',');
        order.widexOptions.earHookSize = this.state.earHookSizeOptions.filter(x => x.selected).map(x => { return x.value }).join(',');
        orderService.updateWidexOrder(order);
    }
    componentWillUnmount() {
        orderService.unsubscribe(this._subscription);
    }
    onChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if (name == "bteEarphoneOtherSize") {
            const temp = this.state.bteEarphoneSizeOptions.map(x => { if (x.name == "其他") { x.value = value; } return x; })
            this.setState({
                bteEarphoneSizeOptions: temp
            });
        }
        if (name == "earHookOtherSize") {
            const temp = this.state.earHookSizeOptions.map(x => { if (x.name == "其他") { x.value = value; } return x; })
            this.setState({
                earHookSizeOptions: temp
            });
        }
    }
    onOtherMadeChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            otherMadeOptions: this.state.otherMadeOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onSpiracleChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            spiracleOptions: this.state.spiracleOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onSpiracleSizeChange(e, changedIndex) {
        const { checked } = e.target;
        this.setState({
            spiracleSizeOptions: this.state.spiracleSizeOptions.map((item, i) => {
                item.selected = (i == changedIndex ? checked : false)
                return item;
            })
        });
    }
    onBteEarphoneSizeChange(e, changedIndex) {
        const { checked } = e.target;
        this.setState({
            bteEarphoneSizeOptions: this.state.bteEarphoneSizeOptions.map((item, i) => {
                item.selected = (i == changedIndex ? checked : false)
                return item;
            })
        });
    }
    onBteEarphoneDiameterChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            bteEarphoneDiameterOptions: this.state.bteEarphoneDiameterOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onBteEarphoneStyleChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            bteEarphoneStyleOptions: this.state.bteEarphoneStyleOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onBteEardrumMaterialChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            bteEardrumMaterialOptions: this.state.bteEardrumMaterialOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onBteEardrumStyleChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            bteEardrumStyleOptions: this.state.bteEardrumStyleOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onReceiverMaterialChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            receiverMaterialOptions: this.state.receiverMaterialOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onReceiverStyleChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            receiverStyleOptions: this.state.receiverStyleOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onSoundOutletTypeChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            soundOutletTypeOptions: this.state.soundOutletTypeOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onReceiverPowerChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            receiverPowerOptions: this.state.receiverPowerOptions.map((item, i) => {
                if (direction == DirectionConsts.Left) {
                    item.leftSelected = (i == changedIndex ? checked : false)
                } else if (direction == DirectionConsts.Right) {
                    item.rightSelected = (i == changedIndex ? checked : false)
                }
                return item;
            })
        });
    }
    onEarHookSizeChange(e, changedIndex, direction) {
        const { checked } = e.target;
        this.setState({
            earHookSizeOptions: this.state.earHookSizeOptions.map((item, i) => {
                item.selected = (i == changedIndex ? checked : false)
                return item;
            })
        });
    }
    updateReceiverMaterialState() {
        const widex = this.state.model.widexOptions;
        if (!widex.receiverMaterial) { return; }
        const arr = widex.receiverMaterial.split(';');
        const temp = this.state.receiverMaterialOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ receiverMaterialOptions: temp });
    }
    updateReceiverStyleState() {
        const widex = this.state.model.widexOptions;
        if (!widex.receiverStyle) { return; }
        const arr = widex.receiverStyle.split(';');
        const temp = this.state.receiverStyleOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ receiverStyleOptions: temp });
    }
    updateSoundOutletTypeState() {
        const widex = this.state.model.widexOptions;
        if (!widex.soundOutletType) { return; }
        const arr = widex.soundOutletType.split(';');
        const temp = this.state.soundOutletTypeOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ soundOutletTypeOptions: temp });
    }
    updateReceiverPowerState() {
        const widex = this.state.model.widexOptions;
        if (!widex.receiverPower) { return; }
        const arr = widex.receiverPower.split(';');
        const temp = this.state.receiverPowerOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ receiverPowerOptions: temp });
    }
    updateEarHookSizeState() {
        const widex = this.state.model.widexOptions;
        if (!widex.earHookSize) { return; }
        let bit = false;
        const temp = this.state.earHookSizeOptions.map((item, index) => {
            if (index == this.state.earHookSizeOptions.length - 1 && !bit) {
                item.selected = true;
                this.setState({
                    earHookOtherSize: widex.earHookSize
                });
            } else {
                if (item.value == widex.earHookSize) {
                    item.selected = true;
                    bit = true;
                } else {
                    item.selected = false;
                }
            }

            return item;
        });
        this.setState({ earHookSizeOptions: temp });
    }

    updateBteEarphoneDiameterState() {
        const widex = this.state.model.widexOptions;
        if (!widex.bteEarphoneDiameter) { return; }
        const arr = widex.bteEarphoneDiameter.split(';');
        const temp = this.state.bteEarphoneDiameterOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ bteEarphoneDiameterOptions: temp });
    }
    updateBteEarphoneStyleState() {
        const widex = this.state.model.widexOptions;
        if (!widex.bteEarphoneStyle) { return; }
        const arr = widex.bteEarphoneStyle.split(';');
        const temp = this.state.bteEarphoneStyleOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ bteEarphoneStyleOptions: temp });
    }
    updateBteEarphoneSizeState() {
        const widex = this.state.model.widexOptions;
        if (!widex.bteEarphoneSize) { return; }
        let bit = false;
        const temp = this.state.bteEarphoneSizeOptions.map((item, index) => {
            if (index == this.state.bteEarphoneSizeOptions.length - 1 && !bit) {
                item.selected = true;
                this.setState({
                    bteEarphoneOtherSize: widex.bteEarphoneSize
                });
            } else {
                if (item.value == widex.bteEarphoneSize) {
                    item.selected = true;
                    bit = true;
                } else {
                    item.selected = false;
                }
            }

            return item;
        });
        this.setState({ bteEarphoneSizeOptions: temp });
    }
    updateOtherCustomMadeState() {
        const widex = this.state.model.widexOptions;
        if (!widex.otherMadeOptions) { return; }
        const arr = widex.otherMadeOptions.split(';');
        const temp = this.state.otherMadeOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ otherMadeOptions: temp });
    }
    updateBteEardrumMaterialState() {
        const widex = this.state.model.widexOptions;
        if (!widex.bteEardrumMaterial) { return; }
        const arr = widex.bteEardrumMaterial.split(';');
        const temp = this.state.bteEardrumMaterialOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ bteEardrumMaterialOptions: temp });
    }
    updateBteEardrumStyleState() {
        const widex = this.state.model.widexOptions;
        if (!widex.bteEardrumStyle) { return; }
        const arr = widex.bteEardrumStyle.split(';');
        const temp = this.state.bteEardrumStyleOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ bteEardrumStyleOptions: temp });
    }
    updateSpiracleTypeState() {
        const widex = this.state.model.widexOptions;
        if (!widex.spiracleType) { return; }
        const arr = widex.spiracleType.split(';');
        const temp = this.state.spiracleOptions.map((item, index) => {
            item.leftSelected = (item.value == arr[0] ? true : false);
            item.rightSelected = (item.value == arr[1] ? true : false);
            return item;
        })
        this.setState({ spiracleOptions: temp });
    }
    updateSpiracleSizeState() {
        const widex = this.state.model.widexOptions;
        if (!widex.spiracleSize) { return; }
        const temp = this.state.spiracleSizeOptions.map((item, index) => {
            item.selected = (item.value == widex.spiracleSize ? true : false);
            return item;
        })
        this.setState({ spiracleSizeOptions: temp });
    }
    updateBteMachineState() {
        const widex = this.state.model.widexOptions;
        if (!widex.bteType) { return; }
        const arr = widex.bteType.split(';');
        this.setState({
            leftMachineType: arr[0].split(',')[0],
            leftMachineCode: arr[0].split(',')[1],
            rightMachineType: arr[1].split(',')[0],
            rightMachineCode: arr[1].split(',')[1]
        });
    }
    render() {
        return (
            <form className="bg-white shadow m-auto my-4" style={{ width: 1100 }} >
            <div className="ml-2 pb-2" id="orderForm" style={{ fontSize: 14 }}>
                <OrderNum orderNum={this.state.model.orderNum} logoUrl={this.state.model.brandLogoUrl} title="EardrumOrderTitle" />
                <div className="mt-2">
                    <Dealer onRef={(ref) => { this.dealerChild = ref }} model={this.state.model.dealerInfo} />
                    <Address onRef={(ref) => { this.addressChild = ref }} model={this.state.model.dealerInfo} />
                </div>
                <div className="flex items-start" style={{ flexFlow: 'row nowrap' }}>
                        <div className="-mt-4" style={{ width: '49%' }}>
                            <div className="border align-top pb-3 w-full">
                            <div className="font-black p-2"><FormattedMessage id="ConventionalEarBackMachineEardrum" /></div>
                            <BteEardrumMaterial options={this.state.bteEardrumMaterialOptions} onBteEardrumMaterialChange={this.onBteEardrumMaterialChange} />
                            <BteEardrumMaterialStyle options={this.state.bteEardrumStyleOptions} onBteEardrumStyleChange={this.onBteEardrumStyleChange} />
                        </div>
                            <div className="border mt-2 pb-3">
                            <div className="font-black p-2"><FormattedMessage id="SlimTubeMachineEarplugs" /></div>
                            <div className="inline-block align-top">
                                <BteEarphoneDiameter options={this.state.bteEarphoneDiameterOptions} onBteEarphoneDiameterChange={this.onBteEarphoneDiameterChange} />
                                <div className="text-center p-2"><FormattedMessage id="SoundTubeLength" /></div>
                                <div className="ml-2">
                                    {this.state.bteEarphoneSizeOptions.map((item, index) => {
                                        return (
                                            <span className="" key={index}>
                                                <input type="checkbox" checked={item.selected} onChange={e => this.onBteEarphoneSizeChange(e, index)} className="w-4 h-4 mr-2 -mt-2 align-middle" /><span className="mr-2">{item.name}</span>
                                                {this.state.bteEarphoneSizeOptions.length - 1 == index ? <input type="text" name="bteEarphoneOtherSize" onChange={this.onChange} value={this.state.bteEarphoneOtherSize} className="border pl-2 w-24 mt-2" /> : ""}
                                            </span>
                                        )
                                    })}
                                    <br />

                                </div>
                            </div>
                            <BteEarphoneStyle options={this.state.bteEarphoneStyleOptions} onBteEarphoneStyleChange={this.onBteEarphoneStyleChange} />
                        </div>
                        <div className=" border mt-2 pb-3">
                                <div className="font-black p-2"><FormattedMessage id="CustomExternalReceivers" /></div>
                                <div className="inline-block align-top" style={{width:'49%'}}>
                                <ReceiverMaterial options={this.state.receiverMaterialOptions} onReceiverMaterialChange={this.onReceiverMaterialChange} />
                                <ReceiverStyle options={this.state.receiverStyleOptions} onReceiverStyleChange={this.onReceiverStyleChange} />
                                <SoundOutletType options={this.state.soundOutletTypeOptions} onSoundOutletTypeChange={this.onSoundOutletTypeChange} />
                            </div>
                                <div className="inline-block" style={{ width: '49%' }}>
                                <ReceiverPower options={this.state.receiverPowerOptions} onReceiverPowerChange={this.onReceiverPowerChange} />
                                <div className="text-center w-full mb-1 mt-2 font-black"><FormattedMessage id="EarHookLength" /></div>
                                <div className="ml-2">
                                    {this.state.earHookSizeOptions.map((item, index) => {
                                        return (
                                            <span className="" key={index}>
                                                <input type="checkbox" checked={item.selected} onChange={e => this.onEarHookSizeChange(e, index)} className="w-4 h-4 mr-2 -mt-2 align-middle" /><span className="mr-2">{item.name}</span>
                                                {this.state.earHookSizeOptions.length - 1 == index ? <input type="text" className="border pl-2 w-24 mt-2" value={this.state.earHookOtherSize} name="earHookOtherSize" onChange={this.onChange} /> : ""}
                                            </span>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>

                        <div className="border inline-block w-full mt-2">
                            <Spiracle options={this.state.spiracleOptions} onSpiracleChange={this.onSpiracleChange} />
                            <SpiracleSize options={this.state.spiracleSizeOptions} onSpiracleSizeChange={this.onSpiracleSizeChange} />
                        </div>
                    </div>
                    <Customer partial={() => this.partial(this.onOtherMadeChange, this.onChange)} onRef={(ref) => { this.customerChild = ref }} model={this.state.model} />
                </div>
                <Signature />
                <Contact />
                </div >
            </form>
        );
    }

    partial(onOtherMadeChange, onChange) {
        return (
            <div className="mt-2">
                <table className="border-r border-b border-separate inline-table w-full" style={{ borderSpacing: '0' }}>
                    <tbody>
                        <tr>
                            <td colSpan="4" className="border-l border-t p-2 font-black text-center"><FormattedMessage id="EarBackMachineModel" /></td>
                        </tr>
                        <tr>
                            <td className="border-l border-t p-2 text-blue-500 w-20"><FormattedMessage id="LeftEarModel" /></td>
                            <td className="border-l border-t p-2 relative">
                                <input type="text" name="leftMachineType" onChange={onChange} value={this.state.leftMachineType} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 text-red-500 w-20"><FormattedMessage id="RightEarModel" /></td>
                            <td className="border-l border-t p-2 relative">
                                <input type="text" name="rightMachineType" onChange={onChange} value={this.state.rightMachineType} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-l border-t p-2 text-blue-500"><FormattedMessage id="LeftFuselageNumber" /></td>
                            <td className="border-l border-t p-2 relative">
                                <input type="text" name="leftMachineCode" onChange={onChange} value={this.state.leftMachineCode} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 text-red-500"><FormattedMessage id="RightFuselageNumber" /></td>
                            <td className="border-l border-t p-2 relative">
                                <input type="text" name="rightMachineCode" onChange={onChange} value={this.state.rightMachineCode} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <OtherMadeItem options={this.state.otherMadeOptions} onOtherMadeChange={onOtherMadeChange} />
            </div>
        );
    }
}

const ReceiverMaterial = ({ options, onReceiverMaterialChange }) => {
    return (
        <div>
            <div className="text-center w-full mb-1 font-bold"><FormattedMessage id="MaterialSelection" /></div>
            <Template options={options} onChange={onReceiverMaterialChange} className="ml-2" />
        </div>
    )
}
const ReceiverStyle = ({ options, onReceiverStyleChange }) => {
    return (
        <div>
            <div className="text-center w-full mb-1 font-bold"><FormattedMessage id="StyleSelection" /></div>
            <Template options={options} onChange={onReceiverStyleChange} className="ml-2" />
        </div>
    )
}
const SoundOutletType = ({ options, onSoundOutletTypeChange }) => {
    return (
        <div>
            <div className="text-center w-full mb-1 font-bold"><FormattedMessage id="SoundOutletType" /></div>
            <Template options={options} onChange={onSoundOutletTypeChange} className="ml-2" />
        </div>
    )
}
const ReceiverPower = ({ options, onReceiverPowerChange }) => {
    return (
        <div>
            <div className="text-center w-full mb-1 font-bold"><FormattedMessage id="ReceiverPower" /></div>
            <Template options={options} onChange={onReceiverPowerChange} className="ml-2" />
        </div>
    )
}
const BteEarphoneDiameter = ({ options, onBteEarphoneDiameterChange }) => {
    return (
        <div>
            <div className="text-center w-full mb-1 font-bold"><FormattedMessage id="AcousticTubeDiameter" /></div>
            <Template options={options} onChange={onBteEarphoneDiameterChange} className="ml-2" />
        </div>
    )
}
const BteEarphoneStyle = ({ options, onBteEarphoneStyleChange }) => {
    return (
        <div className="inline-block">
            <div className="text-center w-full mb-1 font-bold"><FormattedMessage id="StyleSelection" /></div>
            <Template options={options} onChange={onBteEarphoneStyleChange} className="ml-2" />
        </div>
    )
}
const BteEardrumMaterial = ({ options, onBteEardrumMaterialChange }) => {
    return (
        <div className="inline-block align-top">
            <div className="text-center w-full mb-1 font-bold"><FormattedMessage id="MaterialSelection" /></div>
            <Template options={options} onChange={onBteEardrumMaterialChange} className="ml-2" />
        </div>
    )
}
const BteEardrumMaterialStyle = ({ options, onBteEardrumStyleChange }) => {
    return (
        <div className="inline-block align-top">
            <div className="text-center w-full mb-1 font-bold"><FormattedMessage id="StyleSelection" /></div>
            <Template options={options} onChange={onBteEardrumStyleChange} className="ml-2" />
        </div>
    )
}
const OtherMadeItem = ({ options, onOtherMadeChange }) => {
    return (
        <div className="border inline-block pb-2 mt-2 w-full">
            <div className="font-black p-2 font-bold"><FormattedMessage id="OtherMadeOption" /></div>
            <Template options={options} onChange={onOtherMadeChange} className="ml-2" />
        </div>
    )
}
const SpiracleSize = ({ options, onSpiracleSizeChange }) => {
    return (
        <div>
            <div className="font-black p-2 mt-2"><FormattedMessage id="ChooseSpiracleSize" /></div>
            <div className="mb-3">
                {options.map((item, index) => (
                    <span className="" key={index}>
                        <input type="checkbox" checked={item.selected} onChange={e => onSpiracleSizeChange(e, index)} className="w-4 h-4 mr-1 ml-1" />{item.name}
                    </span>
                )
                )}

            </div>
        </div>
    )
}
const Spiracle = ({ options, onSpiracleChange }) => {
    return (
        <div className="">
            <div className="font-black p-2"><FormattedMessage id="SpiracleType" /></div>
            <Template options={options} onChange={onSpiracleChange} className="w-1/2 inline-block pl-5" />
        </div>
    )
}

const Template = ({ options, onChange, className }) => {
    return (
        <div>
            {
                options.map((item, index) => (
                    <div className={className} key={index}>
                        <input type="checkbox" checked={item.leftSelected} onChange={e => onChange(e, index, DirectionConsts.Left)} className="w-4 h-4 align-middle mr-1" /><FormattedMessage id="Left" />
                        <input type="checkbox" checked={item.rightSelected} onChange={e => onChange(e, index, DirectionConsts.Right)} className="w-4 h-4 align-middle mr-1 ml-1" /><FormattedMessage id="Right" />
                        <span className="ml-1">{item.name}</span>
                    </div>
                ))
            }
        </div>
    )
}