import React, { Component, Fragment } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react';
import { BaseTextBox } from '../controls/BaseTextBox';
import Toast from '../controls/BaseToast'

export class SalesAccountCodeDialog extends Component {
    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.bindSalesAccountCode = this.bindSalesAccountCode.bind(this);
        this.state = { inputSalesAccountCode: "", inputSalesAdmin: "", inputSalesclerk: "", inputRegion: "", dealerId: this.props.dealerId, salesAccountCode: null, errMessage: '' }
    }

    componentDidMount() {
        this.props.onRef(this);
    }
    onChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

    }

    bindSalesAccountCode(salesAccountCode) {
        this.setState({
            inputSalesAccountCode: salesAccountCode.code,
            inputSalesAdmin: salesAccountCode.salesAdmin,
            inputSalesclerk: salesAccountCode.salesclerk,
            inputRegion: salesAccountCode.region,
            salesAccountCode: salesAccountCode
        });
    }
    onClose() {
        this.props.onClose();
        this.clearForm();
    }
    clearForm() {
        this.setState({
            inputSalesAccountCode: "", inputSalesAdmin: "", inputSalesclerk: "", inputRegion: "", salesAccountCode: null, errMessage: ''
        });
    }
    async handleOnSubmit(e) {
        e.preventDefault();
        const code = this.state.inputSalesAccountCode;
        const salesAdmin = this.state.inputSalesAdmin;
        const salesclerk = this.state.inputSalesclerk;
        const region = this.state.inputRegion;
        const dealerId = this.state.dealerId;
        const salesAccountCode = this.state.salesAccountCode;
        if (!code) {
              this.setState({ errMessage: 'Required' });
             return;
        }
        const token = await authService.getAccessToken();
        let response = null;
        if (salesAccountCode) {
            response = await fetch('dealer/EditSalesAccountCode', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'id': salesAccountCode.id,
                    'code': code,
                    'dealerId': dealerId,
                    'salesAdmin': salesAdmin,
                    'salesclerk': salesclerk,
                    'region': region
                })
            })
        } else {
            response = await fetch('dealer/CreateSalesAccountCode', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'code': code,
                    'dealerId': dealerId,
                    'salesAdmin': salesAdmin,
                    'salesclerk': salesclerk,
                    'region': region
                })
            })
        }

        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    this.onClose();
                    this.props.populateListData();
                }
                else {
                    Toast.warn(data.message);
                   
                }
            }
        }
        else {
            console.error('response.status:' + response.status)
        }

      

    }
    render() {
        const open = this.props.open;
        this.state.dealerId = this.props.dealerId;
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    open={open}
                    onClose={this.onClose}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <form onSubmit={this.handleOnSubmit} style={{ width: '100vw' }}>
                                <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="">
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900"><FormattedMessage id="SaleAccountCode" /></Dialog.Title>
                                                <div className="mt-4">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="SaleAccountCode" /></label>
                                                    <FormattedMessage id="SaleAccountCode">
                                                        {placeholder =>
                                                            <BaseTextBox errmessage={this.state.errMessage} classnames="w-3/5 mt-1" type="text" name="inputSalesAccountCode" placeholder="" value={this.state.inputSalesAccountCode} onChange={this.onChange} />
                                                        }
                                                    </FormattedMessage>

                                                </div>
                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="SalesAdmin" /></label>
                                                    <FormattedMessage id="SalesAdmin" >
                                                        {placeholder =>
                                                            <BaseTextBox classnames="w-3/5 mt-1" type="text" name="inputSalesAdmin" placeholder="" value={this.state.inputSalesAdmin} onChange={this.onChange} />
                                                        }
                                                    </FormattedMessage>

                                                </div>

                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Salesclerk" /></label>
                                                    <FormattedMessage id="Salesclerk" >
                                                        {placeholder =>
                                                            <BaseTextBox classnames="w-3/5 mt-1" type="text" name="inputSalesclerk" placeholder="" value={this.state.inputSalesclerk} onChange={this.onChange} />
                                                        }</FormattedMessage>

                                                </div> <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Region" /></label>
                                                    <FormattedMessage id="Region" >
                                                        {placeholder =>
                                                            <BaseTextBox classnames="w-3/5 mt-1" type="text" name="inputRegion" placeholder="" value={this.state.inputRegion} onChange={this.onChange} />
                                                        }</FormattedMessage>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={this.onClose}><FormattedMessage id="cancel" /></button>
                                        <button type="submit"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"><FormattedMessage id="Submit" /></button>

                                    </div>
                                </div>
                            </form>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root >
        )
    }
}