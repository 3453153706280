import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


export class Pager extends Component {

    constructor(props) {
        super(props);

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.state = { display: 5 };
    }

    handleNextPage(e) {
        e.preventDefault();
        var handler = this.props.onPageChange;
        if (handler) {
            var newPage = this.props.currentPage + 1;
            if (newPage > this.props.pageCount) {
                newPage = this.props.pageCount;
            }
            handler(newPage);
        }
    }

    handlePreviousPage(e) {
        e.preventDefault();
        var handler = this.props.onPageChange;
        if (handler) {
            var newPage = this.props.currentPage - 1;
            if (newPage < 0) {
                newPage = 0;
            }
            handler(newPage);
        }
    }

    handlePageChange(e) {
        e.preventDefault();
        var handler = this.props.onPageChange;
        if (handler) {
            var newPage = parseInt(e.target.innerText);
            handler(newPage - 1);
        }
    }

    render() {
        let display = this.state.display;
        let content = [];
        let { currentPage, pageCount, totalCount } = this.props;
        if (pageCount < 1) { return null; }
        pageCount = pageCount - 1;
        var minPage = display + 1;
        if (pageCount > minPage && currentPage > 0) {
            content.push(
                <li key="previous">
                    <button onClick={this.handlePreviousPage}
                        className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple" aria-label="Previous">
                        <svg aria-hidden="true" className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.41 0z" clipRule="evenodd" fillRule="evenodd"></path>
                        </svg>
                    </button>
                </li>
            );
        }
        const pageObj = { start: currentPage, end: pageCount };
        if (pageCount > minPage) {
            var ne_half = Math.floor(display / 2.0);
            pageObj.start = Math.max(currentPage - ne_half, 0);
            pageObj.end = Math.min(currentPage + ne_half, pageCount);
        }
        if (pageObj.start > 0) {
            content.push(
                <li key="1" >
                    <button onClick={this.handlePageChange}
                        key="1"
                        className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
                    >1
                    </button>
                </li>)
        }
        if (pageObj.start > 1) {
            content.push(
                <li key="pre_ellipsis" >
                    <span>...</span>
                </li>)
        }
        for (var i = pageObj.start; i <= pageObj.end; i++) {
            if (i == currentPage) {
                content.push(
                    <li key={i + 1}>
                        <button className="px-3 py-1 text-white transition-colors duration-150 bg-blue-600 border border-r-0 borderblue-600 rounded-md focus:outline-none focus:shadow-outline-purple">
                            {i + 1}
                        </button>
                    </li>
                );
            }
            else {
                content.push(
                    <li key={i + 1} >
                        <button onClick={this.handlePageChange}
                            key={i + 1}
                            className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
                        >
                            {i + 1}
                        </button>
                    </li>
                );
            }
        }
        if (pageObj.end < pageCount-1) {
            content.push(
                <li key="next_ellipsis" >
                    <span>...</span>
                </li>
            )
        }
        if (pageObj.end < pageCount) {
            content.push(
                <li key={pageCount+1} >
                    <button onClick={this.handlePageChange}
                        key={pageCount + 1}
                        className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
                    >
                        {pageCount + 1}
                    </button>
                </li>
            );
        }
        if (pageCount > minPage && currentPage < pageCount) {
            content.push(
                <li key="next">
                    <button onClick={this.handleNextPage}
                        className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple" aria-label="Next">
                        <svg className="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 001-1.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                        </svg>
                    </button>
                </li>
            );
        }

        return (
            <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                <span className="flex items-center col-span-3"><FormattedMessage id="Showing" /> {currentPage * 10 + 1} - {(currentPage + 1) * 10} <FormattedMessage id="Of" /> {totalCount} </span>
                <span className="col-span-2"></span>
                <span className="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
                    <nav aria-label="Table navigation">
                        <ul className="inline-flex items-center">
                            {content}
                        </ul>
                    </nav>
                </span>
            </div>
        );
    }
}
