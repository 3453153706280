import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class Customer extends Component {
    static displayName = Customer.name;

    constructor(props) {
        super(props);
        this.state = {
            model: this.props.model,
            name: '', age: '', specialRequirements: '', hearingLeft: [0, 0, 0, 0, 0, 0, 0], hearingRight: [0, 0, 0, 0, 0, 0, 0],
            customerEarDescOptions: [{ name: '中耳炎', value: '中耳炎', selected: false }, { name: '油性耳道', value: '油性耳道', selected: false }, { name: '耳道坍塌', value: '耳道坍塌', selected: false }, { name: '耳道畸形', value: '耳道畸形', selected: false }, { name: '耳道敏感', value: '耳道敏感', selected: false }, { name: '鼓膜穿孔', value: '鼓膜穿孔', selected: false }, { name: '耳道术后', value: '耳道术后', selected: false }, { name: '其他', value: '其他', selected: false }],
            customerEarOther: "",
            customerWearingHistoryOptions: [{ name: '无（首次选配）', value: '无（首次选配）', selected: false }, { name: '耳背式助听器', value: '耳背式助听器', selected: false }, { name: '外置受话器助听器', value: '外置受话器助听器', selected: false }, { name: '定制耳内机', value: '定制耳内机', selected: false }],
            customerHaveOptions: [{ name: "请选择", value: '' }, { name: '唯听', value: '唯听' }, { name: '科塞奇', value: '科塞奇' }, { name: '西嘉/西门子', value: '西嘉/西门子' }, { name: '力斯顿', value: '力斯顿' }, { name: '奥德声', value: '奥德声' }, { name: '惠耳', value: '惠耳' }, { name: '峰力', value: '峰力' }, { name: '奥迪康', value: '奥迪康' }, { name: '瑞声达', value: '瑞声达' }, { name: '斯达克', value: '斯达克' }, { name: '优利康', value: '优利康' }, { name: '丽声', value: '丽声' }, { name: '欧仕达', value: '欧仕达' }, { name: '康聆声', value: '康聆声' }, { name: '其它', value: '其它' }],
            customerHaveSelected: '',
            customerHaveType: '',
            customerHaveCode:''
        };
        this.onChange = this.onChange.bind(this);
        this.onCustomerEarDescChange = this.onCustomerEarDescChange.bind(this);
        this.onCustomerWearingHistoryChange = this.onCustomerWearingHistoryChange.bind(this);
    }
    componentDidMount() {
        this.props.onRef(this);
        this.updateCustomerEarDescState();
        this.updateHearingLossDataState();
        this.updateCustomerWearingHistoryState();
        this.updateCustomerHaveState();
        const model = this.state.model;
        this.setState({
            name: model.customerInfo.customerName??"",
            age: model.customerInfo.customerAge??"",
            specialRequirements: model.specialRequirements??""
        });
    }
    onChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        if (name && name.split('-').length == 2) {
            const arr = name.split('-');
            let temp;
            if (arr[0] == 'left') {
                temp = this.state.hearingLeft;
                temp[arr[1]] = value;
                this.setState({ hearingLeft: temp });
            }
            else if (arr[0] == 'right') {
                temp = this.state.hearingRight;
                temp[arr[1]] = value;
                this.setState({ hearingRight: temp });
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }
    onCustomerEarDescChange(e, changedIndex) {
        const { checked } = e.target;
        this.setState({
            customerEarDescOptions: this.state.customerEarDescOptions.map((item, i) => {
                if (i == changedIndex) {
                    item.selected = checked;
                }
                return item;
            })
        });
    }
    onCustomerWearingHistoryChange(e, changedIndex) {
        const { checked } = e.target;
        this.setState({
            customerWearingHistoryOptions: this.state.customerWearingHistoryOptions.map((item, i) => {
                item.selected = (i == changedIndex ? checked : false)
                return item;
            })
        });
    }
    updateHearingLossDataState() {
        const model = this.state.model;
        const hearingLossData = model.hearingLossData;
        if (hearingLossData) {
            const hearingLeft = hearingLossData.split(';')[0].split(',');
            const hearingRight = hearingLossData.split(';')[1].split(',');
            this.setState({
                hearingLeft: hearingLeft,
                hearingRight: hearingRight
            });
        }
    }
    updateCustomerEarDescState() {
        const widex = this.state.model.widexOptions;
        if (!widex.customerEarDesc) { return; }
        const arr = widex.customerEarDesc.split(',');
        const temp = this.state.customerEarDescOptions.map((item, index) => {
            item.selected = (arr.filter(x=>x==item.value).length>0? true : false);
            return item;
        })
        this.setState({ customerEarDescOptions: temp, customerEarOther: widex.customerEarOther });
    }
    updateCustomerWearingHistoryState() {
        const widex = this.state.model.widexOptions;
        if (!widex.customerWearingHistory) { return; }
        const temp = this.state.customerWearingHistoryOptions.map((item, index) => {
            item.selected = (item.value == widex.customerWearingHistory ? true : false);
            return item;
        })
        this.setState({ customerWearingHistoryOptions: temp });
    }
    updateCustomerHaveState() {
        const widex = this.state.model.widexOptions;
        if (!widex.customerHave) { return;}
        const arr = widex.customerHave.split(',');
        this.setState({ customerHaveSelected: arr[0], customerHaveType: arr[1], customerHaveCode:arr[2] });
    }
    render() {
        return (

            <div className="mt-2 ml-2" style={{ width:'49%' }}>
                <table className="border-r border-b border-separate inline-table align-top" style={{ borderSpacing: '0' }}>
                    <tbody>
                        <tr>
                            <td width="20%" className="border-l border-t p-2"><FormattedMessage id="CustomerName" /></td>
                            <td className="border-l border-t p-2">
                                <input type="text" name="name" value={this.state.name} onChange={this.onChange} className="p-2 left-0  top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td width="20%" className="border-l border-t p-2"><FormattedMessage id="CustomerAge" /></td>
                            <td className="border-l border-t p-2">
                                <input type="text" name="age" value={this.state.age} onChange={this.onChange}  className="p-2 left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                        </tr>
                    </tbody>

                </table>
                <table className="border-r border-b border-separate w-full inline-table mt-2" style={{ borderSpacing: '0' }}>
                    <tbody>
                        <tr>
                            <td className="border-l border-t p-2"></td>
                            <td className="border-l border-t p-2 relative">125</td>
                            <td className="border-l border-t p-2">250</td>
                            <td className="border-l border-t p-2 relative">500</td>
                            <td className="border-l border-t p-2 relative">1K</td>
                            <td className="border-l border-t p-2 relative">2K</td>
                            <td className="border-l border-t p-2 relative">4K</td>
                            <td className="border-l border-t p-2 relative">8K</td>
                        </tr>
                        <tr>
                            <td className="border-l border-t p-2 text-blue-500"><FormattedMessage id="LeftEar"/></td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="left-0" onChange={this.onChange} value={this.state.hearingLeft[0]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="left-1" onChange={this.onChange} value={this.state.hearingLeft[1]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="left-2" onChange={this.onChange} value={this.state.hearingLeft[2]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="left-3" onChange={this.onChange} value={this.state.hearingLeft[3]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="left-4" onChange={this.onChange} value={this.state.hearingLeft[4]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="left-5" onChange={this.onChange} value={this.state.hearingLeft[5]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="left-6" onChange={this.onChange} value={this.state.hearingLeft[6]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-l border-t p-2 text-red-500"><FormattedMessage id="RightEar" /></td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="right-0" onChange={this.onChange} value={this.state.hearingRight[0]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="right-1" onChange={this.onChange} value={this.state.hearingRight[1]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="right-2" onChange={this.onChange} value={this.state.hearingRight[2]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="right-3" onChange={this.onChange} value={this.state.hearingRight[3]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="right-4" onChange={this.onChange} value={this.state.hearingRight[4]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="right-5" onChange={this.onChange} value={this.state.hearingRight[5]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                            <td className="border-l border-t p-2 relative">
                                <input type="number" name="right-6" onChange={this.onChange} value={this.state.hearingRight[6]} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                            </td>
                        </tr>
                    </tbody>

                </table>
                {this.props.partial && this.props.partial()}
                <div className="border inline-block align-top mt-2 pb-2">
                    <div className="font-black p-2"><FormattedMessage id="UserEarDesc"/></div>
                    <div className="ml-3">
                        {this.state.customerEarDescOptions.map((item, index) => (
                            <div key={index} className="inline-block w-1/3">
                                <input type="checkbox" checked={item.selected} onChange={(e) => this.onCustomerEarDescChange(e, index)} className="w-4 h-4 mr-2" />{item.name}
                            </div>
                        ))}
                        <div className="inline-block w-1/3">
                            <input type="text" name="customerEarOther" value={this.state.customerEarOther} onChange={this.onChange} className="border -ml-14 pl-2" />
                        </div>
                    </div>
                </div>
                <div className="inline-block mt-2 w-full">
                    <table className="border-b border-r w-full">
                        <tbody>
                            <tr>
                                <td colSpan="6" className="border-l border-t p-2">
                                    <span className="font-black p-2"><FormattedMessage id="UserWearingHistory"/></span>
                                    <div className="ml-3">
                                        {this.state.customerWearingHistoryOptions.map((item, index) => (
                                            <span key={index} className="mr-2">
                                                <input type="checkbox" checked={item.selected} onChange={(e) => this.onCustomerWearingHistoryChange(e, index)} className="w-4 h-4 align-middle mr-2" />{item.name}
                                            </span>
                                        ))
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="border-l border-t" style={{ width:'18%' }}><FormattedMessage id="CurrentBrand" /></td>
                                <td className="relative border-l border-t" style={{ width: '23%' }}>
                                    <select onChange={this.onChange} name="customerHaveSelected" className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" value={this.state.customerHaveSelected} >
                                        {
                                            this.state.customerHaveOptions.map((item, index) => (
                                                <option value={item.value} key={ index}>{item.name}</option>
                                                ))
                                        }
                                    </select>
                                </td>
                                <td className="border-l border-t" style={{ width: '9%' }}><FormattedMessage id="Model"/></td>
                                <td className="relative border-l border-t" style={{ width: '18%' }}>
                                    <input type="text" name="customerHaveType" value={this.state.customerHaveType} onChange={this.onChange}  className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                                </td>
                                <td className="border-l border-t" style={{ width: '14%' }}><FormattedMessage id="SerialNo" /></td>
                                <td className="relative border-l border-t" style={{ width: '18%' }}>
                                    <input type="text" name="customerHaveCode" value={this.state.customerHaveCode} onChange={this.onChange} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="inline-block w-full">
                    <table className="w-full border-b border-r">
                        <tbody>
                            <tr>
                                <td className="border-l border-t font-black p-2"><FormattedMessage id="SpecialRequirements"/></td>
                            </tr>
                            <tr>
                                <td className="relative border-l border-t h-60">
                                    <textarea onChange={this.onChange} value={this.state.specialRequirements} name="specialRequirements" className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full resize-none" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}