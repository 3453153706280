import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderTextBox } from '../OrderTextBox'
import orderService from '../OrderService';


export class HearingLossData extends Component {
    static displayName = HearingLossData.name;
    constructor(props) {
        super(props);
        var data = props.data;
        var left =  [0, 0, 0, 0, 0, 0, 0];
        var right = [0, 0, 0, 0, 0, 0, 0];
        if (data) {
            var array = data.split(';');
            if (array.length === 2) {
                left = array[0].split(',');
                right = array[1].split(',');
            }
        }
        this.state = { left: left, right: right, specialRequirements: props.specialRequirements };
        this.handleInputChange = this.handleInputChange.bind(this);               
        this.handleTestAreaChange = this.handleTestAreaChange.bind(this);  
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        var array = name.split('-');
        if (array.length === 2) {
            if (array[0] === 'left') {

                var index = parseInt(array[1]);
                this.state.left[index] = value;
                this.setState(
                    {
                        left: this.state.left.slice()

                    });
                
            }
            else if (array[0] === 'right') {
                var index = parseInt(array[1]);
                this.state.right[index] = value;
                this.setState(
                    {
                        right: this.state.right.slice()

                    });
            }
        }        
    }

    handleTestAreaChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        this._subscription = orderService.subscribe(() => this.orderCommitting());      
    }

    orderCommitting() {
        orderService.updateHearingLossData(this.state);
    }

    componentWillUnmount() {
        orderService.unsubscribe(this._subscription);
    }

          
    render() {               
        return (
            <div className="">
                <div className="grid border-2 border-gray-100 p-1">
                    <label><FormattedMessage id="HearingLossData" /></label>
                    <div className="grid grid-rows-3 grid-cols-8 text-center">
                        <label className="self-center"></label>
                        <label className="self-center">125</label>
                        <label className="self-center">250</label>
                        <label className="self-center">500</label>
                        <label className="self-center">1K</label>
                        <label className="self-center">2K</label>
                        <label className="self-center">4K</label>
                        <label className="self-center">8K</label>
                        <label className="self-center"><FormattedMessage id="Left" /></label>
                        <OrderTextBox classnames="w-10" name="left-0" value={this.state.left[0]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="left-1" value={this.state.left[1]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="left-2" value={this.state.left[2]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="left-3" value={this.state.left[3]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="left-4" value={this.state.left[4]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="left-5" value={this.state.left[5]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="left-6" value={this.state.left[6]} onChange={this.handleInputChange} />
                        <label className="self-center"><FormattedMessage id="Right" /></label>
                        <OrderTextBox classnames="w-10" name="right-0" value={this.state.right[0]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="right-1" value={this.state.right[1]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="right-2" value={this.state.right[2]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="right-3" value={this.state.right[3]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="right-4" value={this.state.right[4]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="right-5" value={this.state.right[5]} onChange={this.handleInputChange} />
                        <OrderTextBox classnames="w-10" name="right-6" value={this.state.right[6]} onChange={this.handleInputChange} />
                    </div>
                </div>
                 <div className="flex flex-row border-2 border-gray-100 mt-1">
                    <label><FormattedMessage id="SpecialRequirements" /></label>
                    <textarea className="flex-auto bg-gray-100 m-1 m-full h-12" name="specialRequirements" value={this.state.specialRequirements??""} onChange={this.handleTestAreaChange} />
                        </div>
                </div>
            
    );
  }
}