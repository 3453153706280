import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DealerInfo } from '../CustomerMade/DealerInfo';
import { OrderTextBox } from '../OrderTextBox';
import { EardrumHearingLossData } from './EardrumHearingLossData';

export class EardrumCustomerInfo extends Component {   
    constructor(props) {
        super(props);
        this.state = { update: false };
        this.handleEarSurgery = this.handleEarSurgery.bind(this);
        this.handleEarInflammation = this.handleEarInflammation.bind(this);
        this.handleEardrumOrderType = this.handleEardrumOrderType.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        var model = this.props.model;
        if (model) {
            switch (name) {
                case "customerName":
                    model.customerName = value;
                    break;
                case "customerAge":
                    model.customerAge = value;
                    break;
                case "specialRequirements":
                    model.specialRequirements = value;
                    break;
                case "hearingAidModel":
                    model.hearingAidModel = value;
                    break;
                case "lastEardrumNumber":
                    model.lastEardrumNumber = value;
                    break;
            }

            this.updateUi();            
        }
    }

    updateUi() {
        let update = !this.state.update;
        this.setState({ update: update });
    }
    
    handleEardrumOrderType(event) {
        const target = event.target;
        const checked = target.checked;
        const value = target.value;        
        var model = this.props.model;
        if (model) {
            if (checked) {
                model.eardrumOrderType = parseInt(value);
            }
            else {
                model.eardrumOrderType = -1;
            }
            this.updateUi();
        }
    }

    handleEarSurgery(event) {
        const target = event.target;
        const checked = target.checked;
        const value = target.value;
        var model = this.props.model;
        if (model) {
            if (checked) {
                model.earSurgery = parseInt(value);
            }
            else {
                model.earSurgery = -1;
            }
            this.updateUi();
        }
    }

    handleEarInflammation(event) {
        const target = event.target;
        const checked = target.checked;
        const value = target.value;
        var model = this.props.model;
        if (model) {
            if (checked) {
                model.earInflammation = parseInt(value);
            }
            else {
                model.earInflammation = -1;
            }
            this.updateUi();
        }
    }

    render() {
        let model = this.props.model;
        if (!model) {
            return null;
        }

        return (
             <table className="w-full h-48">
                <tbody>
                    <tr className="align-top">
                        <td width="35%">
                            <DealerInfo model={this.props.dealerInfo} />
                        </td>
                        <td className="px-2" width="48%">
                            <table className="h-full w-full">
                                <tbody>
                                    <tr className="align-top">
                                        <td className="" width="40%">
                                            <div className=" border-2 border-gray-200 flex justify-between">
                                                <div className="border-r border-gray-200 flex flex-row pl-1">
                                                    <lable className="place-self-center"><FormattedMessage id="CustomerName" /></lable>
                                                    <OrderTextBox classnames="w-24" name="customerName" value={model.customerName ?? ""} onChange={this.handleInputChange} /></div>
                                                <div className="flex flex-row">
                                                    <lable className="place-self-center"><FormattedMessage id="CustomerAge" /></lable>
                                                    <OrderTextBox classnames="w-12" name="customerAge" value={model.customerAge ?? ""} onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            <table className="w-full border-2 border border-gray-200 mt-2 ">
                                                <thead>
                                                    <tr className="h-8">
                                                        <th colSpan="4" className="border border-gray-200 text-left pl-1"><FormattedMessage id="UserHistory" /></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="h-8">
                                                        <td className="border border-gray-200 pl-1"><FormattedMessage id="EarSurgery" /></td>
                                                        <td className="border border-gray-200">
                                                            <div className="grid grid-cols-2">
                                                                <div className="flex justify-center">
                                                                    <div className="place-self-center inline-flex items-center">
                                                                        <input type="checkbox" value={0} checked={model.earSurgery === 0} onChange={this.handleEarSurgery}></input>
                                                                        <span className="ml-1"><FormattedMessage id="No" /></span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <div className="place-self-center inline-flex items-center">
                                                                        <input className="ml-1" type="checkbox" value={1} checked={model.earSurgery === 1} onChange={this.handleEarSurgery}></input>
                                                                        <span className="ml-1"><FormattedMessage id="Yes" /></span>
                                                                    </div>
                                                                </div>
                                                            </div>                                                            
                                                        </td>
                                                        <td className="border border-gray-200 pl-1"><FormattedMessage id="EarInflammation" /></td>
                                                        <td className="border border-gray-200 text-center">
                                                            <div className="grid grid-cols-2">
                                                                <div className="flex justify-center">
                                                                    <div className="place-self-center inline-flex items-center">
                                                                        <input type="checkbox" value={0} checked={model.earInflammation === 0} onChange={this.handleEarInflammation}></input>
                                                                        <span className="ml-1"><FormattedMessage id="No" /></span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <div className="place-self-center inline-flex items-center">
                                                                        <input className="ml-1" type="checkbox" value={1} checked={model.earInflammation === 1} onChange={this.handleEarInflammation}></input>
                                                                        <span className="ml-1"><FormattedMessage id="Yes" /></span>
                                                                    </div>
                                                                </div>
                                                            </div>                                                            
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </td>
                                        <td className="pl-1 pb-1" width="60%">
                                            <EardrumHearingLossData data={model.hearingLossData} />
                                        </td>
                                    </tr>
                                    <tr className="border-2 border border-gray-200">
                                        <td colSpan="2">
                                            <div className="flex flex-row pl-1">
                                                <label className="w-16"><FormattedMessage id="SpecialRequirements" /></label>
                                                <textarea className="flow-grow bg-gray-100 m-1 h-12 w-full" name="specialRequirements" value={model.specialRequirements ?? ""} onChange={this.handleInputChange}></textarea>
                                            </div>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td width="18%">
                            <table className="w-full h-full">
                                <tbody>
                                    <tr className="border-2 border-gray-200">
                                        <td className=" pl-1"><FormattedMessage id="HearingAidModel" /></td>
                                        <td className=""><OrderTextBox classnames="" name="hearingAidModel" value={model.hearingAidModel ?? ""} onChange={this.handleInputChange} /></td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-200  pl-1" colSpan="2"><FormattedMessage id="EardrumOrderType" /></td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-200">
                                            <div className="flex justify-center">
                                                <div className="place-self-center inline-flex items-center">
                                                    <input type="checkbox" name="eardrumOrderType0" value={0} checked={model.eardrumOrderType === 0} onChange={this.handleEardrumOrderType}></input>
                                                    <span className="ml-1"><FormattedMessage id="NewEarMold" /></span>
                                                </div>
                                            </div>                                            
                                        </td>
                                        <td className="border border-gray-200">
                                            <div className="flex justify-center">
                                                <div className="place-self-center inline-flex items-center">
                                                    <input type="checkbox" name="eardrumOrderType1" value={1} checked={model.eardrumOrderType === 1} onChange={this.handleEardrumOrderType}></input>
                                                    <span className="ml-1"><FormattedMessage id="Repair" /></span>
                                                </div>
                                            </div>                                           
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-200">
                                            <div className="flex justify-center">
                                                <div className="place-self-center inline-flex items-center">
                                                    <input type="checkbox" name="eardrumOrderType2" value={2} checked={model.eardrumOrderType === 2} onChange={this.handleEardrumOrderType}></input>
                                                    <span className="ml-1"><FormattedMessage id="RedoEarMold" /></span>
                                                </div>
                                            </div>                                            
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-200 pl-1"><FormattedMessage id="LastEardrumNumber" /></td>
                                        <td className="border border-gray-200">
                                            <OrderTextBox classnames="" name="lastEardrumNumber" value={model.lastEardrumNumber ?? ""} onChange={this.handleInputChange} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </td>
                                </tr>
                           
           
                   
              
                    
                </tbody>
            </table>
        );
    }
}