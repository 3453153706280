import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import authService from '../../api-authorization/AuthorizeService'
import orderService from '../OrderService';
import { OrderTextBox } from '../OrderTextBox';
import { EardrumCheckBoxGroup } from './EardrumCheckBoxGroup';
import { EardrumOption } from './EardrumOption';

export class EardrumOptions extends Component {
    static displayName = EardrumOptions.name;
    constructor(props) {
        super(props);
        this.state = { data: null, model: props.model };

        this.handleEardrumBte = this.handleEardrumBte.bind(this);
        this.handleEardrumRic = this.handleEardrumRic.bind(this);
        this.handleEardrumBteVents = this.handleEardrumBteVents.bind(this);
        this.handleEarmoldBteTubeDiameters = this.handleEarmoldBteTubeDiameters.bind(this);
        this.handleEarmoldBteLengths = this.handleEarmoldBteLengths.bind(this);
        this.handleEarmoldBteTubeLengths = this.handleEarmoldBteTubeLengths.bind(this);
        this.handleEarmoldRicReceiverVersions = this.handleEarmoldRicReceiverVersions.bind(this);
        this.handleEardrumRicVents = this.handleEardrumRicVents.bind(this);
        this.handleEardrumRicCordLengths = this.handleEardrumRicCordLengths.bind(this);
        this.handleEardrumRicReceivers = this.handleEardrumRicReceivers.bind(this);
        this.handleEardrumRicCords = this.handleEardrumRicCords.bind(this);
    }


    componentDidMount() {
        this._subscription = orderService.subscribe(() => this.orderCommitting());
        this.populateProductOptionData();
    }

    async populateProductOptionData() {
        const token = await authService.getAccessToken();
        const response = await fetch('ProductOption/GetEardrumProductOptions', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ data: data });
    }

    orderCommitting() {
        //orderService.updateEardrumInfo(this.state);
    }

    componentWillUnmount() {
        orderService.unsubscribe(this._subscription);
    }

    handleEardrumBte(e) {
        if (this.state.model) {
            this.state.model.eardrumBte = e;
        }
    }

    handleEardrumRic(e) {
        if (this.state.model) {
            this.state.model.eardrumRic = e;
        }
    }

    handleEardrumBteVents(e) {
        if (this.state.model) {
            this.state.model.eardrumBteVent = e;
        }
    }

    handleEarmoldBteTubeDiameters(e) {
        if (this.state.model) {
            this.state.model.earmoldBteTubeDiameter = e;
        }
    }
    handleEarmoldBteLengths(e) {
        if (this.state.model) {
            this.state.model.earmoldBteLength = e;
        }
    }
    handleEarmoldBteTubeLengths(e) {
        if (this.state.model) {
            this.state.model.earmoldBteTubeLength = e;
        }
    }

    handleEarmoldRicReceiverVersions(e) {
        if (this.state.model) {
            this.state.model.earmoldRicReceiverVersion = e;
        }
    }

    handleEardrumRicVents(e) {
        if (this.state.model) {
            this.state.model.eardrumRicVent = e;
        }
    }
    handleEardrumRicCordLengths(e) {
        if (this.state.model) {
            this.state.model.eardrumRicCordLength = e;
        }
    }
    handleEardrumRicReceivers(e) {
        if (this.state.model) {
            this.state.model.eardrumRicReceiver = e;
        }
    }
    handleEardrumRicCords(e) {
        if (this.state.model) {
            this.state.model.eardrumRicCord = e;
        }
    }



    render() {
        let data = this.state.data;
        if (!data) {
            return null;
        }

        return (
            <table className="w-full h-36">
                <tbody>
                    <tr>
                        <td className="pt-2 pr-2" width="50%">
                            <EardrumOption title="BTE" desc="EardrumBteDesc" enclosureTypes={data.bteEnclosureTypes} hardEardrumColorOptions={data.hardEardrumColorOptions}
                                softEardrumColorOptions={data.softEardrumColorOptions} onSelectValueChanged={this.handleEardrumBte} value={this.state.model.eardrumBte} />
                        </td>
                        <td className="pt-2 pl-2" width="50%">
                            <EardrumOption title="RIC" desc="EardrumRicDesc" enclosureTypes={data.ricEnclosureTypes} hardEardrumColorOptions={data.hardEardrumColorOptions}
                                softEardrumColorOptions={data.softEardrumColorOptions} onSelectValueChanged={this.handleEardrumRic} value={this.state.model.eardrumRic} />
                        </td>
                    </tr>
                    <tr>
                        <td className="pt-2 pr-2">
                            <table className="w-full h-full">
                                <tbody>
                                    <tr className="align-top">
                                        <td width="30%">
                                            <div>
                                            <EardrumCheckBoxGroup title="Vent" header="BTE" desc="EardrumRicVentDesc" items={data.eardrumBteVents}
                                                    onSelectValueChanged={this.handleEardrumBteVents} value={this.state.model.eardrumBteVent} />
                                                <div className="text-purple-400"><FormattedMessage id="TubeNotice" /></div>
                                                <div className="text-xxs text-gray-500 italic transform scale-75">Template: SZH_F_ITE_013 - V2
                                                Template: SZH_F_ITE_013 - V4
                                                Template effective: 2021-11-11
                                                Template Editor: Ma Rongrong, Yuan Jiyi
                                                Copyright © WSAUD A/S. All rights reserved.
                                                </div>
                                            </div>
                                        </td>
                                        <td width="37%" className="px-2">
                                            <div>                                                
                                                <EardrumCheckBoxGroup title="EarmoldBteTubeDiamete" header="BTE" desc="EarmoldBteTubeDiameteDesc" items={data.earmoldBteTubeDiameters}
                                                    onSelectValueChanged={this.handleEarmoldBteTubeDiameters} value={this.state.model.earmoldBteTubeDiameter} />
                                                <div className="mt-2">
                                                    <EardrumCheckBoxGroup title="EarmoldBteLength" header="BTE" desc="EarmoldBteLengthDesc" items={data.earmoldBteLengths}
                                                        onSelectValueChanged={this.handleEarmoldBteLengths} value={this.state.model.earmoldBteLength} />
                                                </div>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <EardrumCheckBoxGroup title="EarmoldBteTubeLength" header="BTE" desc="EarmoldBteTubeLengthDesc" items={data.earmoldBteTubeLengths}
                                                onSelectValueChanged={this.handleEarmoldBteTubeLengths} value={this.state.model.earmoldBteTubeLength} />
                                        </td>
                                       
                                    </tr>
                                    <tr>
                                        <td className="text-xxs text-gray-500 italic">
                                            <div className="transform scale-75">
                                                Change Number :
                                                QMS_CR_2021_3179
                                                Restricted
                                            page 1 of 2</div>
                                        </td>                                        
                                        <td colSpan="2" className="text-xxs text-red-400">
                                            <div className="transform scale-90">
                                                <span className="text-xs text-red-600 font-bold">
                                                    <FormattedMessage id="OrderFillingInstructions" />
                                                </span>
                                                <FormattedMessage id="EardrumFillingInstructionsContent" />
                                            </div>
                                        </td>                                        
                                    </tr>
                                </tbody>

                            </table>
                        </td>
                        <td className="pt-2 pl-2">
                            <table className="w-full h-full">
                                <tbody>
                                    <tr className="align-top">
                                        <td width="25%" className="pr-2">
                                            <div>
                                                <EardrumCheckBoxGroup title="EardrumRicReceiver" header="RIC" desc="EarmoldRicReceiverVersionDesc" items={data.earmoldRicReceiverVersions}
                                                    onSelectValueChanged={this.handleEarmoldRicReceiverVersions} value={this.state.model.earmoldRicReceiverVersion} />
                                                <div className="mt-2">
                                                <EardrumCheckBoxGroup title="EardrumRicReceiver" header="RIC" desc="EardrumRicReceiverDesc" items={data.eardrumRicReceivers}
                                                        onSelectValueChanged={this.handleEardrumRicReceivers} value={this.state.model.eardrumRicReceiver} />
                                                </div>
                                            </div>
                                        </td>
                                        <td width="25%"><EardrumCheckBoxGroup title="Vent" header="RIC" desc="EardrumRicVentDesc" items={data.eardrumRicVents}
                                            onSelectValueChanged={this.handleEardrumRicVents} value={this.state.model.eardrumRicVent} /></td>
                                        <td width="25%" className="px-2"><EardrumCheckBoxGroup title="EardrumRicCordLength" header="RIC" desc="EardrumRicCordLengthDesc" items={data.eardrumRicCordLengths}
                                            onSelectValueChanged={this.handleEardrumRicCordLengths} value={this.state.model.eardrumRicCordLength} /></td>
                                        <td width="25%"><EardrumCheckBoxGroup title="Cord" header="RIC" desc="EardrumRicCordDesc" items={data.eardrumRicCords}
                                            onSelectValueChanged={this.handleEardrumRicCords} value={this.state.model.eardrumRicCord} /></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className="text-xxs text-gray-400 italic "><div className="transform scale-90">
                                            <FormattedMessage id="EardrumOrderFillingInstructionsClaim" />
                                        </div></td>
                                        <td>
                                            <table className="w-full">
                                                <tbody>
                                                    <tr>
                                                        <td width="30%"><FormattedMessage id="FillingPerson" /></td>
                                                        <td width="70%"><OrderTextBox readOnly="readonly" classnames="w-full" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><FormattedMessage id="Date" /></td>
                                                        <td><OrderTextBox readOnly="readonly" classnames="w-full" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
             
            </table>
        );
    }
}