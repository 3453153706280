import React, { Component, Fragment } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react';
import Select from 'react-select';
import { BaseTextBox } from '../controls/BaseTextBox';
import Toast from '../controls/BaseToast'
import { BaseTextArea } from '../controls/BaseTextArea';

export class DeliverAccountCodeDialog extends Component {
    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.bindDeliverAccountCode = this.bindDeliverAccountCode.bind(this);
        this.productsData = this.productsData.bind(this);
        this.state = { deliverAccountCodeId: "", inputDeliverAccountCode: "", inputAddress: "", dealerId: this.props.dealerId, deliverAccountCode: null, selected: [], productSelected: null, productsOptions: [], errMessage: '', zipcode: "", phone: "", addressee: "" }
    }

    componentDidMount() {
        this.props.onRef(this);
        this.productsData();
    }
    onChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

    }

    bindDeliverAccountCode(deliverAccountCode) {
        this.setState({
            inputDeliverAccountCode: deliverAccountCode.code,
            inputAddress: deliverAccountCode.address,
            selected: deliverAccountCode.salesAccountCodes.map((item) => {
                return { label: item.code, value: item.id }
            }),
            deliverAccountCodeId: deliverAccountCode.id,
            addressee: deliverAccountCode.addressee,
            phone: deliverAccountCode.phone,
            zipcode: deliverAccountCode.zipcode
        });
        if (deliverAccountCode.product) {
            this.setState({ productSelected: { label: deliverAccountCode.product.name, value: deliverAccountCode.product.id } });
        }
    }

    onSelectChange = selectedOption => {
        this.setState({
            selected: selectedOption
        })
    };
    onSelectProductChange = selectedOption => {
        this.setState({
            productSelected: selectedOption
        })
    };
    onClose() {
        this.props.onClose();
        this.clearForm();
    }
    clearForm() {
        this.setState({
            inputDeliverAccountCode: "", inputAddress: "", selected: [], deliverAccountCodeId: "", productSelected: null, errMessage: '', zipcode: "", phone: "", addressee: ""
        });
    }
    async productsData() {
        const token = await authService.getAccessToken();
        const response = await fetch('product/GetProducts', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ productsOptions: data.map(x => { return { label: x.name, value: x.id } }) });
    }
    async handleOnSubmit(e) {
        e.preventDefault();
        const deliverAccountCode = this.state.inputDeliverAccountCode;
        const address = this.state.inputAddress;
        const salesAccountCodes = this.state.selected.map((item) => { return { id: item.value, code: item.label } });
        const dealerId = this.state.dealerId;
        const id = this.state.deliverAccountCodeId;
        const product = this.state.productSelected;
        const addressee = this.state.addressee;
        const zipcode = this.state.zipcode;
        const phone = this.state.phone;
        if (!deliverAccountCode) {
            this.setState({ errMessage: 'Required' });
            return;
        }
        let response = null;
        const token = await authService.getAccessToken();
        if (id) {
            response = await fetch('dealer/EditDeliverAccountCode', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'id': id,
                    'code': deliverAccountCode,
                    'dealerId': dealerId,
                    'address': address,
                    'salesAccountCodes': salesAccountCodes,
                    'product': product ? { id: product.value, name: product.label } : null,
                    'addressee': addressee,
                    'zipcode': zipcode,
                    'phone': phone
                })
            })
        } else {
            response = await fetch('dealer/CreateDeliverAccountCode', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'code': deliverAccountCode,
                    'dealerId': dealerId,
                    'address': address,
                    'salesAccountCodes': salesAccountCodes,
                    'product': product ? { id: product.value, name: product.label } : null,
                    'addressee': addressee,
                    'zipcode': zipcode,
                    'phone': phone
                })
            })
        }

        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    this.onClose();
                    this.props.populateListData();
                }
                else {
                    Toast.warn(data.message);

                }
            }
        }
        else {
            console.error('response.status:' + response.status)
        }
    }
    render() {
        const open = this.props.open;
        const options = this.props.saleAccountCodes.map((item, index) => {
            return { label: item.code, value: item.id }
        });
        this.state.dealerId = this.props.dealerId;
        const IndicatorSeparator = ({ innerProps }) => {
            return <span style={{
                alignSelf: 'stretch',
                marginBottom: 8,
                marginTop: 8,
                width: 1,
            }} {...innerProps} />;
        };
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    open={open}
                    onClose={this.onClose}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <form onSubmit={this.handleOnSubmit} style={{ width: '100vw' }}>
                                <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="">
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900"><FormattedMessage id="DeliverAccountCode" /></Dialog.Title>
                                                <div className="mt-4">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="DeliverAccountCode" /></label>
                                                    <BaseTextBox classnames="w-3/5" type="text" name="inputDeliverAccountCode" placeholder="" value={this.state.inputDeliverAccountCode} errmessage={this.state.errMessage} onChange={this.onChange} />
                                                </div>
                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Addressee" /></label>
                                                    <BaseTextBox classnames="w-3/5" type="text" name="addressee" placeholder="" value={this.state.addressee} onChange={this.onChange} />
                                                </div>
                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Phone" /></label>
                                                    <BaseTextBox classnames="w-3/5" type="text" name="phone" placeholder="" value={this.state.phone} onChange={this.onChange} />
                                                </div>
                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Zipcode" /></label>
                                                    <BaseTextBox classnames="w-3/5" type="text" name="zipcode" placeholder="" value={this.state.zipcode} onChange={this.onChange} />
                                                </div>
                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Brand" /></label>
                                                    <FormattedMessage id="PickOne" >
                                                        {placeholder =>
                                                            <Select onChange={this.onSelectProductChange} className=""
                                                                closeMenuOnSelect={true}
                                                                components={{ IndicatorSeparator }}
                                                                value={this.state.productSelected}
                                                                options={this.state.productsOptions}
                                                                placeholder={placeholder}
                                                            />
                                                        }</FormattedMessage>

                                                </div>
                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="SaleAccountCode" /></label>
                                                    <FormattedMessage id="PickOne" >
                                                        {placeholder =>
                                                            <Select onChange={this.onSelectChange} className=""
                                                                closeMenuOnSelect={false}
                                                                components={{ IndicatorSeparator }}
                                                                defaultValue={this.state.selected}
                                                                isMulti
                                                                options={options}
                                                                placeholder={placeholder}
                                                            />
                                                        }</FormattedMessage>

                                                </div>
                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Address" /></label>
                                                    <BaseTextArea name="inputAddress" id="inputAddress" placeholder="" value={this.state.inputAddress} onChange={this.onChange} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={this.onClose}><FormattedMessage id="cancel" /></button>
                                        <button type="submit"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"><FormattedMessage id="Submit" /></button>

                                    </div>
                                </div>
                            </form>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root >
        )
    }
}