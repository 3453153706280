import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from "react-intl";

export class ActivateButton extends Component {
    constructor(props) {
        super(props);
        this.handleActivate = this.handleActivate.bind(this);
        this.state = { isAllow: false };
    }

    async handleActivate(e) {
        e.preventDefault();
        var url = this.props.url;
        var id = this.props.id;
        const token = await authService.getAccessToken();
        fetch(url + id, {
            method: 'post',
            headers: !token ? {} : { 'Authorization': `${token}` },
        }).then(result => {

            if (result.ok) {
                var handler = this.props.onActivateExecuted;
                if (handler) {
                    handler(id);
                }
            }
            else {
                alert('activate failed');
            }

        });
    }
    async componentDidMount() {
        if (this.props.module && this.props.action) {
            const isAllow = await authService.isAllow(this.props.module, this.props.action);
            //console.log(isAllow);
            this.setState({
                isAllow: isAllow
            });
        }
    }

    render() {

        return this.state.isAllow&&(
            <a className="cursor-pointer  text-indigo-600 hover:text-indigo-900" onClick={this.handleActivate}><FormattedMessage id="Activate" /></a>
        );
    }


}

