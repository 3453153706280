import React, { Component, useRef } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { SalesAccountCodeDialog } from './SalesAccountCodeDialog';
import { ConfirmAlert } from '../controls/ConfirmAlert'
import { BackButton } from '../controls/BackButton';


export class SalesAccountCode extends Component {
    static displayName = SalesAccountCode.name;

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnBack = this.handleOnBack.bind(this);
        this.handleOnCreate = this.handleOnCreate.bind(this);
        this.handleOnDeleted = this.handleOnDeleted.bind(this);
        this.populateListData = this.populateListData.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.deletedConfirm = this.deletedConfirm.bind(this);
        this.state = { inputAccountCode: '', salesAccountCodes: [], dealerId: this.props.match.params["id"], dealerName: "", open: false, confirmOpen: false, deleteId: this.props.match.params["id"] };
    }
    componentDidMount() {
        var id = this.props.match.params["id"];
        if (id) {
            this.populateListData();
        }
    }
    async populateListData() {
        const token = await authService.getAccessToken();
        await fetch('dealer/GetDealer/' + this.state.dealerId, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({ salesAccountCodes: data.salesAccountCodeModels, dealerName: data.name });
            });
    }
    onClose(e) {
        this.setState({ open: false });
    }
    onConfirmClose() {
        this.setState({ confirmOpen: false, deleteId: null });
    }
    handleOnBack(e) {
        e.preventDefault();
        window.history.back(-1);
    }
    async handleOnCreate(e) {
        this.setState({ open: true });
        e.preventDefault();
    }
    handleOnEdit(id, e) {
        e.preventDefault();
        const salesAccountCode = this.state.salesAccountCodes.filter(x => x.id == id)[0];
        this.dialogForm.bindSalesAccountCode(salesAccountCode);
        this.setState({ open: true });
    }
    deletedConfirm(id, e) {
        e.preventDefault();
        this.setState({ confirmOpen: true, deleteId: id });
    }
    async handleOnDeleted(e) {
        const id = this.state.deleteId;
        if (!id) { return;}
        this.setState({
            salesAccountCodes: this.state.salesAccountCodes.filter((rec) => {
                return (rec.id != id);
            })
        });
        const token = await authService.getAccessToken();
        await fetch('dealer/DeleteSalesAccountCode/' + id, {
            method: 'DELETE',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` }
        }).then(data => {

        });
        this.setState({ confirmOpen: false, deleteId: null });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900"><FormattedMessage id="SaleAccountCodeManagerment" /></h3>
                    {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Dealer details.</p>*/}
                </div>

                <div className="border-t border-gray-200">
                    <dl>
                        <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="col-span-3 sm:col-span-2">
                                    <label><FormattedMessage id="DealerName" /></label>：
                                            <span>{this.state.dealerName}</span>
                                </div>
                                <div className="col-span-3 sm:col-span-2 mt-4">
                                    <button className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4" onClick={this.handleOnCreate}><FormattedMessage id="Create" /></button>
                                    <BackButton/>
                                </div>
                            </div>
                        </div>
                    </dl>
                </div>
                <div className="w-full overflow-x-auto">
                    <table className='w-full'>
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                <th className="px-4 py-3"><FormattedMessage id="SaleAccountCode" /></th>
                                <th className="px-4 py-3"><FormattedMessage id="SalesAdmin" /></th>
                                <th className="px-4 py-3"><FormattedMessage id="Salesclerk" /></th>
                                <th className="px-4 py-3"><FormattedMessage id="Region" /></th>
                                <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                            {

                                this.state.salesAccountCodes.map((item) => {
                                    return (
                                        <tr key={item.id} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400">
                                            <td className="px-4 py-3 text-sm">{item.code}</td>
                                            <td className="px-4 py-3 text-sm">{item.salesAdmin}</td>
                                            <td className="px-4 py-3 text-sm">{item.salesclerk}</td>
                                            <td className="px-4 py-3 text-sm">{item.region}</td>
                                            <td className="px-4 py-3 text-sm">
                                                <a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={(e) => this.handleOnEdit(item.id, e)}><FormattedMessage id="edit" /></a>|
                            <a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={(e) => this.deletedConfirm(item.id, e)}><FormattedMessage id="delete" /></a>
                                            </td>
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </table>
                </div>

                <SalesAccountCodeDialog onRef={(ref) => { this.dialogForm = ref }} populateListData={this.populateListData} open={this.state.open} onClose={this.onClose} saleAccountCodes={this.state.salesAccountCodes} dealerId={this.state.dealerId}>
                </SalesAccountCodeDialog>
                <ConfirmAlert onRef={(ref) => { this.confirmRef = ref }} onCancel={this.onConfirmClose} onOk={(id, e) => { this.handleOnDeleted(id, e) }} open={this.state.confirmOpen} />
            </div>


        );
    }
}