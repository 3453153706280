import en from './en.json';
import zh from './zh.json';
import { injectIntl } from 'react-intl';

export const LanguageStrings = { en, zh };

export const LanguageOptions = [
    { value: 'en', dispalyName: 'English' },
    { value: 'zh', dispalyName: '中文' }    
]; 



