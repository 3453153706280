import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';

export class SearchButton extends Component {    
    constructor(props) {
        super(props);
        this.state = { keyword: '' };
        this.handleSearch = this.handleSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    async handleSearch(e) {
        e.preventDefault();
        var url = this.props.url;
        
        var handler = this.props.onSearching;
        if (handler) {                  
            handler();
        }
        
        handler = this.props.onSearchDid;
        if (handler) {
            const token = await authService.getAccessToken();
            const response = await fetch(url + '?page=0&keyword=' + this.state.keyword, {
                headers: !token ? {} : { 'Authorization': `${token}` }
            });
            const data = await response.json();
            handler(data);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        const { placeholder } = this.props;
        const { keyword } = this.state;        
       
        return (
            <div className="flex rounded-md shadow-sm w-full">             
                <FormattedMessage id={placeholder}>
                    {
                        palaceholdText => <input type="search"
                            name="keyword"
                            placeholder={palaceholdText}
                            value={keyword}
                            onChange={this.handleInputChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" />    
                    }
                </FormattedMessage>
                <button className="hover:bg-blue-500 hover:text-blue-800 group flex items-center rounded-r-md bg-blue-600 text-white text-sm font-medium px-4 py-2 w-20"
                    onClick={this.handleSearch}>
                    <FormattedMessage id="Search"/>
                  {/*  <svg className="w-5 text-gray-600 h-5 cursor-pointer" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        stroke="currentColor" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>*/}
                </button>                        
            </div>
        );
    }    
}

