import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Pager } from '../controls/Pager'
import { SearchButton } from '../controls/SearchButton';
import { DeleteButton } from '../controls/DeleteButton';
import { ActivateButton } from '../controls/ActivateButton';
import toLocalString from '../infrastructure/DateTimeFunctions';
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';
import { ModalTableTag } from './ModalTableTag';
import QRCode from "qrcode.react";

export class TableTags extends Component {
    static displayName = TableTags.name;

    constructor(props) {
        super(props);        
        this.state = { records: [], currentPage: 0, pageCount: 0, totalCount: 0, loading: true, isAllowEdit: false };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearching = this.handleSearching.bind(this);
        this.handleSearchDid = this.handleSearchDid.bind(this);
        this.handleDeleted = this.handleDeleted.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.handleDownloadQrCode = this.handleDownloadQrCode.bind(this);       
    }   

    handleActivate(id) {
        if (id) {
            this.state.records.map((rec) => {
                if (id == rec.id)
                    rec.state = 0;
            });
            this.setState(
                {
                    accounts: this.state.records.slice()

                });
        }
    }

    handleDeleted(id) {
        if (id) {
            this.state.records.map((rec) => {
                if (id == rec.id)
                    rec.state = 1;
            });
            this.setState(
                {
                    accounts: this.state.records.slice()

                });
        }
    }

    handleSearchDid(data) {
        this.setState({ accounts: data.entities, pageCount: data.pageCount, totalCount: data.totalCount, loading: false });
    }

    handleSearching() {
        this.setState({ loading: true });
    }

    handlePageChange(index) {
        this.setState({ currentPage: index });
        this.populateListData();
    }

    async componentDidMount() {
        this.populateListData();
        const edit = await authService.isAllow(AuthorityModules.TableTag, AuthorityActions.Edit);
        this.setState({
            isAllowEdit: edit
        });
    }
    async handleDownloadQrCode(e) {
        //e.preventDefault();
        let a = e.currentTarget;
        let qrCode = a.childNodes[0];
        let id = qrCode.id;
        //const canvasImg = document.getElementById(id); 
        const img = new Image();        
        var url = qrCode.toDataURL("image/png");
        img.src = url; 
        //const downLink = document.getElementById('down_link');
        a.href = img.src;
        a.download = id +".png"; // image file name
    }
    static toStatusString(state) {
        if (state == 0) {
            return (<span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">  <FormattedMessage id="Active" /> </span>);
        }
        return (<span className="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:bg-red-700 dark:text-red-100">  <FormattedMessage id="Obsoleted" /> </span>);
    }

    static toStatusButton(state, id, handleDeleted, handelActivate) {
        if (state == 0) {
            return (<DeleteButton module={AuthorityModules.TableTag} action={AuthorityActions.Delete} displayname="Deactivate" confirmMsg="DeactivateConfirm" url="account/delete/" id={id} onDeleteExecuted={handleDeleted} />);
        }
        return (<ActivateButton module={AuthorityModules.TableTag} action={AuthorityActions.Delete} url="account/Activate/" id={id} onActivateExecuted={handelActivate} />);
    }

    static renderForecastsTable(records, handelActivate, handleDeleted, handleDownloadQrCode,isAllowEdit) {
        return (
            <div className="w-full overflow-x-auto">
                <table className='w-full'>
                    <thead>
                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th className="px-4 py-3"><FormattedMessage id="Name" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="Desciption" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="QrCode" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        {records.map(a =>
                            <tr key={a.name} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400">
                                <td className="px-4 py-3 text-sm">{a.name}</td>
                                <td className="px-4 py-3 text-sm">{a.description}</td>
                                <td className="px-4 py-3 text-sm"><a href="#" onClick={handleDownloadQrCode} target="_blank"><QRCode id={a.id} value={a.id} size="96" /></a></td>
                                <td className="px-4 py-3 text-sm">{toLocalString(a.modifyTime)}</td>
                                <td className="px-4 py-3  whitespace-nowrap text-sm font-medium">
                                    
                                    <ModalTableTag isNew={false} id={a.id} buttonName="Edit" />
                                    
                                   
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : TableTags.renderForecastsTable(this.state.records, this.handleActivate, this.handleDeleted, this.handleDownloadQrCode, this.state.isAllowEdit);
        
        return (
            <div className="mt-4 mx-4">               
                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    <div className="flex justify-between items-center h-14">
                        <div className="w-1/2">
                        <SearchButton url="account/GetTableTags"
                            placeholder="TableTagSearchPlaceholder"
                            onSearching={this.handleSearching}
                            onSearchDid={this.handleSearchDid}/>
                        </div>
                       
                        <ModalTableTag isNew={true}/>
                    </div>

                </div>
                <div className="overflow-x-hidden overflow-y-auto fixed top-4 left-0 right-0 md:inset-0 z-50 justify-center items-center h-modal sm:h-full hidden" id="add-product-modal" aria-hidden="true">
              <div>test</div>      
                                           
</div>


                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    {contents}
                    <Pager currentPage={this.state.currentPage} pageCount={this.state.pageCount} totalCount={this.state.totalCount} onPageChange={this.handlePageChange} />
                </div>                
            </div>
        );
    }

    async populateListData() {
        const token = await authService.getAccessToken();
        const response = await fetch('tableTag/GetTableTags?page=' + this.state.currentPage, {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ records: data.entities, pageCount: data.pageCount, totalCount: data.totalCount, loading: false });
    }
}
