import React, { Component } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';

export class ModalActionButton extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        let parent = this;
        this.setState({
            show: true
        },
            () => {
                setTimeout(parent.doAction.bind(parent), 10);
            }
        );
    }

    async doAction() {
        let actionAsnc = this.props.actionAsnc;        
        try {
            await actionAsnc(actionAsnc);
        }
        catch (e) {
            console.error("doAction ex: " + e);
        }
      
        this.setState({ show: false });
    }

    render() {

        return (
            <div>
                <button className="...this.props hover:bg-blue-500 hover:text-blue-800 group flex items-center rounded-md bg-blue-600 text-white text-sm font-medium px-4 py-2 mr-2" onClick={this.handleClick}><FormattedMessage id={this.props.displayName} /></button>
                <LoadingBox open={this.state.show} desc={this.props.desc} />
            </div>
        );
    }
}

export default function LoadingBox(props) {

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => { }}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-white bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 active:bg-rose-700 transition ease-in-out duration-150 cursor-not-allowed">
                                <div className="flex items-center justify-center">
                                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <label><FormattedMessage id={props.desc} /></label>
                                    <input className="w-0" />
                                </div>
                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


