import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import productService from './ProductService';
import { ProductMenu }from './ProductMenu';
import { FormattedMessage } from 'react-intl';
import { EditButton } from '../controls/EditButton';
import { CreateButton } from '../controls/CreateButton';
import toLocalString from '../infrastructure/DateTimeFunctions';
import { ConfirmAlert } from '../controls/ConfirmAlert'
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';
import { BaseTextBox } from '../controls/BaseTextBox';
import { Switch } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export class Products extends Component {
    static displayName = Products.name;

    constructor(props) {
        super(props);
        this.state = { categories: [], menus: [], newTempCategory:'', loading: true, deleteId: null, confirmOpen: false, isAllowDelete:false };
        this.handleDelete = this.handleDelete.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.deletedConfirm = this.deletedConfirm.bind(this);
       
        this.handleBaseTextBoxChange = this.handleBaseTextBoxChange.bind(this);
        this.handleDelteCategory = this.handleDelteCategory.bind(this);
    }

    handleDelteCategory(event) {
        const target = event.currentTarget;        
        const id = target.id;
        let categories = this.state.categories;
        let newCategories = categories.filter(c => c.id != id);
        this.setState({ categories: newCategories });
    }

    handleBaseTextBoxChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }    

    async componentDidMount() {
        this.populateListData();
        const isAllow = await authService.isAllow(AuthorityModules.Product, AuthorityActions.Delete);
        this.setState({
            isAllowDelete: isAllow
        });
    }
    deletedConfirm(id, e) {
        e.preventDefault();
        this.setState({ confirmOpen: true, deleteId: id });
    }

    onConfirmClose() {
        this.setState({ confirmOpen: false, deleteId: null });
    }
    async handleDelete(e) {
        const id = this.state.deleteId;
        if (!id) { return; }
        this.setState(
            {
                menus: this.state.menus.filter((rec) => {
                    return (rec.id != id);
                })
            });
        const token = await authService.getAccessToken();
        fetch('product/Delete/' + id, {
            method: 'DELETE',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` }
        }).then(data => {

        });
        this.onConfirmClose();
    }
    
    render() {
        if (this.state.loading) {
            return (<p><em>Loading...</em></p>);
        }
        
        return (
            <div className="pt-6 px-4">
                {this.state.menus.map(m =>

                    <ProductMenu model={m} />
                )}
            </div>
        );
    }



    async populateListData() {
        const token = await authService.getAccessToken();        
        let response = await fetch('product/GetMenus', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        let data = await response.json();
        this.setState({ menus: data.menus, loading: false });
    }
}
