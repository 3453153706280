import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class Signature extends Component {
    static displayName = Signature.name;

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div className="mt-2 p-2">
                <div className="inline-block font-black align-top" style={{width:'49%'}}>
                    <FormattedMessage id="Notes"/>
                     </div>
                <div className="inline-block" style={{ width: '49%' }}>
                    <table className="w-full" >
                        <tbody>
                            <tr>
                                <td className="w-24">  <FormattedMessage id="FitterSignature" /></td>
                                <td className="border-b relative ">
                                    <input type="text" readOnly="readonly" className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                                </td>
                                <td className="w-10"> <FormattedMessage id="Date" /></td>
                                <td className="border-b relative ">
                                    <input type="text" readOnly="readonly" className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                                </td>
                            </tr>
                        </tbody>
                     
                    </table>
                </div>

            </div>
        );
    }
}