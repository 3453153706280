import React, { Component } from 'react';
import { LanguageOptions } from './LanguageConstants';

export class LanguageSwitch extends Component {
   
    constructor(props) {
        super(props);
        var language = localStorage.getItem('language');
        this.state = {
            selectedLanguage: language ? language : 'zh'
        };
      this.handleSelectChange = this.handleSelectChange.bind(this);
    }  

    handleSelectChange(e) {
        const { value } = e.target;       
        this.setState({ selectedLanguage: value });
        localStorage.setItem('language', value);
        window.location.reload();
    }

    render() {
        const { selectedLanguage } = this.state;   
        return (
            
            <select className="h-8 py-0 mx-4 text-gray-900 rounded-md shadow-sm border-2 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-center sm:text-sm font-medium focus:shadow-none focus-visible:ring-2 focus-visible:ring-teal-500"
                onChange={this.handleSelectChange}
                value={selectedLanguage}>
                {this.getOptions(LanguageOptions)}
                </select>
            
        );
      }

    getOptions(options) {
        return options.map((option) => {
            return <option key={option.value} value={option.value} className="text-center" >{option.dispalyName}</option>
        });
    }
}
