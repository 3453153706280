import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import authService from '../api-authorization/AuthorizeService'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PencilAltIcon } from '@heroicons/react/outline'
import Select from 'react-select';
import { OpStatus } from '../infrastructure/AppConstants';
import Toast from '../controls/BaseToast'


export class OrderTypeSelectionDialog extends Component {
    constructor(props) {
        super(props);
        const brandOptions = [{
            label: <FormattedMessage id="WSA" />, value: 0
        }, {
            label: <FormattedMessage id="Widex" />, value: 1
        }];
        const productCategoryOptions = [{
            label: <FormattedMessage id="CustomMade" />, value: 0
        }, {
            label: <FormattedMessage id="Eardrum" />, value: 1
        }];
        this.state = { loading: true, dealerId: '', brandOptions: brandOptions, brandSelected: brandOptions[0], productCategoryOptions: productCategoryOptions, productCategorySelected: productCategoryOptions[0] };
        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {

    }
    onBrandChange = selectedOption => {
        this.setState({
            brandSelected: selectedOption
        })
    };
    onProductCategoryChange = selectedOption => {
        this.setState({
            productCategorySelected: selectedOption
        })
    };
    async onSubmit() {
       // const { dealerId, selectedDeliverCode } = this.setState;
        //  send request to the server
        const token = await authService.getAccessToken();
        const response = await fetch('order/Create', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
            body: JSON.stringify({
                'productBrand': this.state.brandSelected.value,
                'ProductCategroy': this.state.productCategorySelected.value,
            })
        });
        const json = await response.json();
        if (json.status != OpStatus.Success) {
            Toast.warn(json.message);
            return;
        }
        const data = await json.id;
        var handler = this.props.onSubmit;
        if (handler) {
            handler(data);
        }
    }

    onClose() {
        var handler = this.props.onClose;
        if (handler) {
            handler();
        }
    }



    render() {
        const show = this.props.show;
        const IndicatorSeparator = ({ innerProps }) => {
            return <span style={{
                alignSelf: 'stretch',
                marginBottom: 8,
                marginTop: 8,
                width: 1,
            }} {...innerProps} />;
        };
       
        return (
            <Transition.Root show={show} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    open={show}
                    onClose={this.onClose}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-14">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <PencilAltIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-2">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                <FormattedMessage id="NewOrderBaseInfo" />
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500"><FormattedMessage id="SelectProductDes" /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-left">

                                        <div className="mt-5">
                                            <label className="inline-block w-1/6 text-right"><FormattedMessage id="OrderTemplateBrand" /></label>
                                            <Select className="min-w-0 w-4/5 inline-block ml-3" onChange={this.onBrandChange}
                                                closeMenuOnSelect={true}
                                                components={{ IndicatorSeparator }}
                                                defaultValue={this.state.brandSelected}
                                                options={this.state.brandOptions}
                                            />
                                        </div>
                                        <div className="mt-5">
                                            <label className="inline-block w-1/6 text-right"><FormattedMessage id="ProductCategory" /></label>
                                            <Select className="min-w-0 w-4/5 inline-block ml-3" onChange={this.onProductCategoryChange}
                                                closeMenuOnSelect={true}
                                                components={{ IndicatorSeparator }}
                                                defaultValue={this.state.productCategorySelected}
                                                options={this.state.productCategoryOptions}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                  
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={this.onClose}

                                    >
                                        <FormattedMessage id="cancel" />
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={this.onSubmit}

                                    >
                                        <FormattedMessage id="Confirm" />
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }
}

