import React, { Component } from 'react';
import { DealerForm } from './DealerForm';
import { FormattedMessage } from 'react-intl';

export class EditDealer extends Component {
    static displayName = EditDealer.name;

  constructor(props) {
    super(props);      
  }

  
  render() {    
      return (
            <div className="max-w-4xl mx-auto py-6 sm:px-3 lg:px-4">
            <div className="md:grid md:grid-cols-1 md:gap-6">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900"><FormattedMessage id="Edit"/></h3>
                    </div>
                </div>
                  <DealerForm isNew={false} id={this.props.match.params["id"]}/>
            </div>
      </div>
    );
  }  
}
