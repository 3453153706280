import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';
import { BackButton } from '../controls/BackButton';
import { BaseTextBox } from '../controls/BaseTextBox';
import Toast from '../controls/BaseToast'
import { BaseTextArea } from '../controls/BaseTextArea';

export class DealerForm extends Component {
    static displayName = DealerForm.name;

  constructor(props) {
      super(props);
      this.state = { code: '', name: '', address: '', country: '', city: '', contact: '', phone: '', salesPerson: '', isAllowEdit: false, errMessage: '', company:'' };
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleOnBack = this.handleOnBack.bind(this);
    }

    async loadRoles() {
        const token = await authService.getAccessToken();
        const response = await fetch('role/GetRoles', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        var selectRoleId = '';
        if (data.length > 1) {
            selectRoleId = data[0].id;
        }
        this.setState({ roles: data, selectedRoleId: selectRoleId, loadingRoles: false });
    }

    async componentDidMount() {       
        var id = this.props.id;
        if (id) {
            this.locaData(id);
        }
        const isAllow = await authService.isAllow(AuthorityModules.Dealer, AuthorityActions.Edit);
        this.setState({
            isAllowEdit: isAllow
        });
    }

    async locaData(id) {
        const token = await authService.getAccessToken();
        fetch('dealer/GetDealer/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    name: data.name, address: data.address, code: data.code, country: data.country, city: data.city, contact: data.contact, phone: data.phone, salesPerson: data.salesPerson, company: data.company
                });
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleOnBack(e) {
        e.preventDefault();
        window.history.back(-1);
    }
    async handleSubmit(e) {      
        e.preventDefault();
        const name = this.state.name&&this.state.name.trim();
        const address = this.state.address&&this.state.address.trim();
        const code = this.state.code&&this.state.code.trim();
        const country = this.state.country&&this.state.country.trim();
        const city = this.state.city&&this.state.city.trim();
        const contact = this.state.contact&&this.state.contact.trim();
        const phone = this.state.phone&&this.state.phone.trim();
        const company = this.state.company&&this.state.company.trim();
        const salesPerson = this.state.salesPerson&&this.state.salesPerson.trim();
        if (!name) {
            this.setState({ errMessage: 'Required' });
            return;
        }
        //  send request to the server
        const token = await authService.getAccessToken();
        const isNew = this.props.isNew;
        let response = null;
        if (isNew) {
                response = await fetch('dealer/Create', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'name': name,
                    'address': address, 
                    'code':code,
                    'country': country,
                    'city': city,
                    'contact': contact,
                    'phone': phone,
                    'salesPerson': salesPerson,
                    'company':company
                })
            });
        }
        else {
                response = await fetch('dealer/Edit', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'name': name,
                    'id': this.props.id,
                    'address': address,      
                    'code': code,
                    'country': country,
                    'city': city,
                    'contact': contact,
                    'phone': phone,
                    'salesPerson': salesPerson,
                    'company': company
                })
            });
        }
               
        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    window.history.back(-1);
                }
                else {
                    Toast.warn(data.message);
                  /*  this.setState({ name:'' })
                    this.setState({ errMessage: data.message });*/
                }
            }
        }
        else {
            console.error('response.status:' + response.status)
        }
    }    

    renderRoleSelect(roles, selected, hadleRoleChange) {

        return (
            <select type="select" name="select" id="roleSelect" onChangeCapture={hadleRoleChange} value={selected}>
                {roles.map(r =>
                    <option key={r.id} value={r.id}>{r.name}</option>
                    )}
            </select>
            );
    }   

    render() {
       
        return (  
            <div className="border-t border-gray-200">
                <dl>
                    <form onSubmit={this.handleSubmit}>
                        <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="col-span-2 sm:col-span-1">
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Name" /></label>
                                        <BaseTextBox errmessage={this.state.errMessage} type="text" name="name" placeholder="" value={this.state.name} onChange={this.handleInputChange} />                                        
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">                                        
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Code" /></label>
                                        <BaseTextBox type="text" name="code" placeholder="" value={this.state.code} onChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1" >
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Country" /></label>
                                        <BaseTextBox type="text" name="country" placeholder="" value={this.state.country} onChange={this.handleInputChange}/>                                       
                                    </div>
                                    <div className="col-span-2 sm:col-span-1" >                                        
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="City" /></label>
                                        <BaseTextBox type="text" name="city" placeholder="" value={this.state.city} onChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Contact" /></label>
                                        <BaseTextBox type="text" name="contact" placeholder="" value={this.state.contact} onChange={this.handleInputChange}/>                                      
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">                                        
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Phone" /></label>
                                        <BaseTextBox type="text" name="phone" placeholder="" value={this.state.phone} onChange={this.handleInputChange} />

                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="SalesPerson" /></label>
                                        <BaseTextBox type="text" name="salesPerson" placeholder="" value={this.state.salesPerson} onChange={this.handleInputChange} />                                       
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">                                        
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Company" /></label>
                                        <BaseTextBox type="text" name="company" placeholder="" value={this.state.company} onChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Address" /></label>
                                        <BaseTextArea name="address" id="address" value={this.state.address} onChange={this.handleInputChange} />
                                    </div>        
                                </div>
                                
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                {this.state.isAllowEdit && <button className="inline-flex justify-center py-2 mr-4 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><FormattedMessage id="Submit" /></button>}
                                <BackButton />
                            </div>
                        </div>
                    </form>                       
                </dl>
            </div>
    );
  }  
}
