import React, { Component } from 'react';

export class OrderTextBox extends Component {    
       
    render() {
        var props = this.props;
        var className = "bg-gray-100 m-1 pl-2 h-8";
        if (props.classnames) {
            className += ' ' + props.classnames;
        }
        return (
            <input {...props} type="text" className={className} />
        );
    }    
}
