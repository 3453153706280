import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AuthorityModules, AuthorityActions } from './api-authorization/ApiAuthorizationConstants';
import authService from './api-authorization/AuthorizeService';
import { ResetPasswordDialog } from './account/ResetPasswordDialog';

export class Sidebar extends Component {
    static displayName = Sidebar.name;

    constructor(props) {
        super(props);
        this.state = { manageItems: [], loading: true, isAuthenticated: false };
        this.handleResetPassword = this.handleResetPassword.bind(this);
    }

    componentDidMount() {
        this.loadNavigator();
    }

    handleResetPassword(e) {
        e.preventDefault();
        this.resetPasswordDialog.handleClick(null,null,e);
    }

    async loadNavigator() {
        var isAuthenticated = await authService.isAuthenticated();
        if (!isAuthenticated) {
            this.setState({ isAuthenticated: false });
        }
        else {
            var manageItems = [];
            manageItems.push({ nameId: "Dashboard", to: "/Dashboard/Dashboard", pathd: "M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" });
            var authority = await authService.isAllow(AuthorityModules.Order, AuthorityActions.List);
            if (authority) {
                manageItems.push({ nameId: "orders", to: "/order-list/orders", pathd: "M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" });
            }

            var systemItems = [];
            authority = await authService.isAllow(AuthorityModules.Dealer, AuthorityActions.List);
            if (authority) {
                manageItems.push({ nameId: "dealers", to: "/dealer-list/dealers", pathd: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" });
            }
            authority = await authService.isAllow(AuthorityModules.Product, AuthorityActions.List);
            manageItems.push({ nameId: "stores", to: "/store-list/stores", pathd: "M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" });
            if (authority) {
                manageItems.push({ nameId: "products", to: "/product-list/products", pathd: "M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" });
            }
            
            manageItems.push({ nameId: "ProductOptions", to: "/table-tag/tabletags", pathd: "M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" });

            authority = await authService.isAllow(AuthorityModules.ProductOptions, AuthorityActions.List);
            if (authority) {
                manageItems.push({ nameId: "ProductOptions", to: "/product-option/options", pathd: "M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" });
            }

            authority = await authService.isAllow(AuthorityModules.Account, AuthorityActions.List);
            if (authority) {
                manageItems.push({ nameId: "Accounts", to: "/account-list/accounts", pathd: "M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" });
            }
            authority = await authService.isAllow(AuthorityModules.Role, AuthorityActions.List);
            if (authority) {
                manageItems.push({ nameId: "roles", to: "/account-role/roles", pathd: "M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" });
            }

            this.setState({ manageItems: manageItems, loading: false, isAuthenticated: isAuthenticated });
        }

    }

    render() {

        if (!this.state.isAuthenticated) {
            return '';
        }

        if (this.state.loading) {
            return (<p><em>Loading...</em></p>);
        }
        const { manageItems, systemItems } = this.state;
        const user = this.props.user;
        const userId = user && user.accountId;
        return (
            <aside id="sidebar" className="fixed hidden z-20 h-full top-0 left-0 pt-16 flex lg:flex flex-shrink-0 flex-col w-64 transition-width duration-75" aria-label="Sidebar">
                <div className="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
                    <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div className="flex-1 px-3 bg-white divide-y space-y-1">
                            <ul className="space-y-2 pb-2">
                        
                        {manageItems.map(item =>
                            <li key={item.nameId}>
                                <Link to={item.to} className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group">
                                    
                                    <svg className="w-6 h-6 text-gray-500 group-hover:text-gray-900 transition duration-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={item.pathd}>
                                            </path>
                                        </svg>
                                    
                                    <span className="ml-3">
                                        <FormattedMessage id={item.nameId} />
                                    </span>
                                </Link>
                            </li>
                        )}
                                                
                        <li>
                            {/* <div className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-blue-800 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6">*/}
                                    <a href="#" onClick={this.handleResetPassword} className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group">                                
                                            <svg className="w-6 h-6 text-gray-500 group-hover:text-gray-900 transition duration-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z">
                                        </path>
                                    </svg>                                
                                        <span className="ml-3">
                                    <FormattedMessage id="ChangePassword" />
                                    <ResetPasswordDialog onRef={(ref) => { this.resetPasswordDialog = ref }} accountId={userId} />
                                </span>
                            </a>

                            {/* </div>*/}
                        </li>
                    </ul>
                        </div>
                    </div>
                </div>
            </aside>

        );
    }
}
