import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import storeService from './StoreService';
import { FormattedMessage } from 'react-intl';
import { EditButton } from '../controls/EditButton';
import { CreateButton } from '../controls/CreateButton';
import toLocalString from '../infrastructure/DateTimeFunctions';
import { ConfirmAlert } from '../controls/ConfirmAlert'
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';
import { BaseTextBox } from '../controls/BaseTextBox';
import { Switch } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { StoreForm } from './StoreForm';

export class Stores extends Component {
    static displayName = Stores.name;

    constructor(props) {
        super(props);
        this.state = { stores: [], newTempCategory:'', loading: true, deleteId: null, confirmOpen: false, isAllowDelete:false };
        this.handleDelete = this.handleDelete.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.deletedConfirm = this.deletedConfirm.bind(this);        
        this.handleBaseTextBoxChange = this.handleBaseTextBoxChange.bind(this);
       
    }

    

    handleBaseTextBoxChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    

    guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

    async componentDidMount() {
        this.populateListData();
        const isAllow = await authService.isAllow(AuthorityModules.Store, AuthorityActions.Delete);
        this.setState({
            isAllowDelete: isAllow
        });
    }
    deletedConfirm(id, e) {
        e.preventDefault();
        this.setState({ confirmOpen: true, deleteId: id });
    }

    onConfirmClose() {
        this.setState({ confirmOpen: false, deleteId: null });
    }
    async handleDelete(e) {
        const id = this.state.deleteId;
        if (!id) { return; }
        this.setState(
            {
                stores: this.state.stores.filter((rec) => {
                    return (rec.id != id);
                })
            });
        const token = await authService.getAccessToken();
        fetch('store/Delete/' + id, {
            method: 'DELETE',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` }
        }).then(data => {

        });
        this.onConfirmClose();
    }

    static renderTable(stores, handleEdit, handleDelete, handleEditActions,isAllowDelete) {
        return (<div className="w-full overflow-x-auto">
            <table className='w-full'>
                <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th className="px-4 py-3"><FormattedMessage id="Name" /></th>
                     {/*   <th className="px-4 py-3"><FormattedMessage id="Description" /></th>*/}
                        <th className="px-4 py-3"><FormattedMessage id="Phone" /> </th>
                        <th className="px-4 py-3"><FormattedMessage id="Address" /> </th>
                        <th className="px-4 py-3"><FormattedMessage id="ModifyTime" /></th>
                        <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>                        
                    </tr>
                </thead>
                <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    {stores.map(a =>
                        <tr key={a.id} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400 text-sm">
                            <td className="px-4 py-3">{a.name}</td>
                            {/*   <td className="px-4 py-3">{a.description}</td>*/}
                            <td className="px-4 py-3">{a.phone}</td>
                            <td className="px-4 py-3">{a.address}</td>
                            <td className="px-4 py-3">{toLocalString(a.modifyTime)}</td>
                            <td className="px-4 py-3 whitespace-nowrap text-sm font-medium">
                                <EditButton module={AuthorityModules.Store} action={AuthorityActions.Edit} url={'/store/EditStore/' + a.id} />
                                {isAllowDelete && <span>|<a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={(e) => handleDelete(a.id, e)}><FormattedMessage id="delete" /></a></span>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Stores.renderTable(this.state.stores, this.handleEdit, this.deletedConfirm, this.handleEditActions, this.state.isAllowDelete);
        
        return (
            <div className="mt-4 mx-0">               
                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    <div className="flex justify-between items-center h-14">                        
                        <StoreForm isNew={true}/>
                    </div>
                </div>
                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    {contents}
                    <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                        <span><FormattedMessage id="RecordCount" /> {this.state.stores.length}</span>
                    </div>
                </div>                                                
                <ConfirmAlert onRef={(ref) => { this.confirmRef = ref }} onCancel={this.onConfirmClose} onOk={(id, e) => { this.handleDelete(id, e) }} open={this.state.confirmOpen} />                
            </div>
        );
    }



    async populateListData() {       
        let data = await storeService.getStores();        
        this.setState({ stores: data, loading: false });
    }
}
