import React, { Component } from 'react';
import Select from 'react-select';
import authService from '../../../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';

export class Dealer extends Component {
    static displayName = Dealer.name;

    constructor(props) {
        super(props);
        this.state = {
            dealer: this.props.model,
            dealerId: "", dealerName: "", fitter: "", fitterPhone: "", deliverAccountCode: [], deliverAccountCodeMatchItems: [] };
        this.onChange = this.onChange.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
    }
    componentDidMount() {
        this.props.onRef(this);
        const dealer = this.state.dealer;
        this.setState({
            dealerId: dealer.id, dealerName: dealer.dealerName, fitter: dealer.widexFitterName??"", fitterPhone: dealer.widexFitterPhone??""
        });
        if (dealer.deliverAccountCode) {
            this.setState({ deliverAccountCode: { label: dealer.deliverAccountCode, value: '' }});
        }
    }
    onChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    onSelectChange = selectedOption => {
        this.setState({
            deliverAccountCode: selectedOption
        })
    };
    handleOnSearch(keyword, e) {
        if (keyword.trim().length > 0) {
            authService.getAccessToken().then(token => {
                fetch('dealer/GetDeliverAccountCodesWithDealerId?isWidex=true&keyword=' + keyword + "&dealerId=" + this.state.dealerId, { headers: !token ? {} : { 'Authorization': `${token}` } })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({ deliverAccountCodeMatchItems: data.entities.map((item) => { return { value: item.id, label: item.code } }) });
                    })
            });
        }
    }
    render() {
        const IndicatorSeparator = ({ innerProps }) => {
            return <span style={{
                alignSelf: 'stretch',
                marginBottom: 0,
                marginTop: 0,
                width: 0,
                border:0
            }} {...innerProps} />;
        };
        return (
            <table className="border-r border-b border-separate inline-table" style={{ borderSpacing: '0',width:'49%' }}>
                <tbody>
                    <tr>
                        <td className="border-l border-t p-2 w-20"><FormattedMessage id="DealerName" /></td>
                        <td colSpan="3" className="border-l border-t p-2 relative">
                            <input type="text" readOnly="readonly" name="dealerName" value={this.state.dealerName} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                        </td>
                    </tr>
                    <tr>
                        <td className="border-l border-t p-2"><FormattedMessage id="DealerCode" /></td>
                        <td colSpan="3" className="border-l border-t relative td-select">
                            <Select onChange={this.onSelectChange}
                                closeMenuOnSelect={true}
                                components={{ IndicatorSeparator }}
                                value={this.state.deliverAccountCode}
                                options={this.state.deliverAccountCodeMatchItems}
                                onInputChange={this.handleOnSearch}
                                placeholder=""
                                isClearable={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border-l border-t p-2" width="22%"><FormattedMessage id="FitterName" /></td>
                        <td className="border-l border-t p-2">
                            <input type="text" onChange={this.onChange} name="fitter" value={this.state.fitter} className="p-2 border-0 outline-none w-full h-full" />
                        </td>
                        <td className="border-l border-t p-2" width="22%"><FormattedMessage id="FitterPhone" /></td>
                        <td className="border-l border-t p-2">
                            <input type="text" onChange={this.onChange} name="fitterPhone" value={this.state.fitterPhone} className="p-2 border-0 outline-none w-full h-full" />
                        </td>
                    </tr>
                </tbody>
              
            </table>
            );
    }
}