import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Pager } from '../controls/Pager'
import { SearchButton } from '../controls/SearchButton';
import { EditButton } from '../controls/EditButton';
import { CreateButton } from '../controls/CreateButton';
import { ImportButton } from '../controls/ImportButton';
import { AttachAccountDialog } from './AttachAccountDialog';
import toLocalString from '../infrastructure/DateTimeFunctions';
import { DeleteButton } from '../controls/DeleteButton';
import { ActivateButton } from '../controls/ActivateButton';
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';

export class Dealers extends Component {
    static displayName = Dealers.name;

    constructor(props) {
        super(props);
        this.state = { dealers: [], currentPage: 0, pageCount: 0, totalCount: 0, loading: true, openAttachAccount: false, isAllowEdit: false, isAllowDelete: false,name:'',code:'',company:'' };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearching = this.handleSearching.bind(this);
        this.handleSearchDid = this.handleSearchDid.bind(this);
        this.handleAttachAccount = this.handleAttachAccount.bind(this);
        this.populateListData = this.populateListData.bind(this);
        this.onAttachAccountDialogClose = this.onAttachAccountDialogClose.bind(this);
        this.handleDeleted = this.handleDeleted.bind(this);
        this.handleDeactivate = this.handleDeactivate.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    handleSearchDid(data) {
        this.setState({ dealers: data.entities, pageCount: data.pageCount, totalCount: data.totalCount, loading: false });
    }

    handleSearching() {
        this.setState({ loading: true });
    }
    async handleSearch() {
        this.handleSearching();
        this.setState({ currentPage: 0 });
        await this.populateListData();

    }
    onChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handlePageChange(index) {
        this.setState({ currentPage: index });
        this.populateListData();
    }
    handleAttachAccount(id, e) {
        e.preventDefault();
        this.attachAccountDialogForm.bindDealer(this.state.dealers.filter(x => x.id == id)[0]);
        this.setState({ openAttachAccount: true });
    }
    async componentDidMount() {
        this.populateListData();
        const edit = await authService.isAllow(AuthorityModules.Dealer, AuthorityActions.Edit);
        const del = await authService.isAllow(AuthorityModules.Dealer, AuthorityActions.Delete);
        this.setState({
            isAllowEdit: edit,
            isAllowDelete: del
        });
    }

    onAttachAccountDialogClose(e) {
        this.setState({ openAttachAccount: false });
    }
    handleDeactivate(id) {
        if (id) {
            this.state.dealers.map((rec) => {
                if (id == rec.id) {
                    rec.state = 1;
                }
                    
            });
            this.setState({
                    dealers: this.state.dealers.slice() });
        }
    }

    handleActivate(id) {
        if (id) {
            this.state.dealers.map((rec) => {
                if (id == rec.id)
                    rec.state = 0;
            });
            this.setState(
                {
                    dealers: this.state.dealers.slice()

                });
        }
    }

    handleDeleted(id) {
        if (id) {
            this.setState({
                dealers: this.state.dealers.filter(x => x.id != id)
            });
        }
    }
    static toStatusString(state) {
        if (state == 0) {
            return (<span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">  <FormattedMessage id="Active" /> </span>);
        }
        return (<span className="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:bg-red-700 dark:text-red-100">  <FormattedMessage id="Obsoleted" /> </span>);
    }

    static toStatusButton(state, id, handleDeactivate, handleActivate) {
        if (state == 0) {
            return (<DeleteButton module={AuthorityModules.Dealer} action={AuthorityActions.Edit} displayname="Deactivate" confirmMsg="DeactivateConfirm" url="dealer/deactivate/" id={id} onDeleteExecuted={handleDeactivate} />);
        }
        return (<ActivateButton url="dealer/Activate/" module={AuthorityModules.Dealer} action={AuthorityActions.Edit}  id={id} onActivateExecuted={handleActivate} />);
    }

    static renderForecastsTable(dealers, handleActivate, handleDeleted, handleAttachAccount, handleDeactivate, isAllowEdit) {
        return (
            <div className="w-full overflow-x-auto">
                <table className='w-full'>
                    <thead>
                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th className="px-4 py-3"><FormattedMessage id="Name" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="Code" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="Company" /></th>
           {/*                 <th className="px-4 py-3"><FormattedMessage id="Address" /></th>*/}
                            <th className="px-4 py-3"><FormattedMessage id="AttachAccount" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="Status" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="ModifyTime" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        {dealers.map(a =>
                            <tr key={a.id} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400">
                                <td className="px-4 py-3 text-sm">{a.name}</td>
                                <td className="px-4 py-3 text-sm">{a.code}</td>
                                <td className="px-4 py-3 text-sm">{a.company}</td>
                        {/*        <td className="px-4 py-3 text-sm">{a.address}</td>*/}
                                <td className="px-4 py-3 text-sm">{a.accounts.map(x => { return x.name }).join(',')}</td>
                                <td className="px-4 py-3 text-sm">{Dealers.toStatusString(a.state)}</td>
                                <td className="px-4 py-3 text-sm">{toLocalString(a.modifyTime)}</td>
                                <td className="px-4 py-3 text-sm">
                                    <EditButton module={AuthorityModules.Dealer} action={AuthorityActions.Edit} url={'/dealer/EditDealer/' + a.id} />|
                            <EditButton module={AuthorityModules.Dealer} action={AuthorityActions.SaleAccountCode} displayname="SaleAccountCode" url={'/dealer/SalesAccountCode/' + a.id} />|
                            <EditButton module={AuthorityModules.Dealer} action={AuthorityActions.DeliverAccountCode} displayname="DeliverAccountCode" url={'/dealer/DeliverAccountCode/' + a.id} />|
                                <DeleteButton module={AuthorityModules.Dealer} action={AuthorityActions.Delete} displayname="delete" confirmMsg="DeleteConfirm" url="dealer/delete/" id={a.id} onDeleteExecuted={handleDeleted} />
                                    {isAllowEdit && <span>|<a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(e) => handleAttachAccount(a.id, e)}><FormattedMessage id="AttachAccount" /></a></span>}|
                                    {Dealers.toStatusButton(a.state, a.id, handleDeactivate, handleActivate)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Dealers.renderForecastsTable(this.state.dealers, this.handleActivate, this.handleDeleted, this.handleAttachAccount, this.handleDeactivate,this.state.isAllowEdit);

        return (
            <div className="mt-4 mx-4">
                <div className="w-full rounded-lg shadow-xs bg-white p-4 mb-2">
                    <div className="flex gap-x-4  justify-start">
                        <div className="grid grid-cols-3 gap-x-3">
                            <div className="flex justify-center items-center">
                                <label className="w-16 place-self-center block text-sm font-medium text-gray-700"><FormattedMessage id="Name" /></label>
                                <input type="text" onChange={this.onChange} name="name"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                            </div>
                            <div className="flex justify-center items-center">
                                <label className="w-16 place-self-center block text-sm font-medium text-gray-700"><FormattedMessage id="Code" /></label>
                                <input type="text" onChange={this.onChange} name="code"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                            </div>
                            <div className="flex justify-center items-center">
                                <label className="w-16 place-self-center block text-sm font-medium text-gray-700"><FormattedMessage id="Company" /></label>
                            <input type="text" onChange={this.onChange} name="company"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                            </div>
                        </div>
                        <div>
                        <div className="flex">
                            <button className="hover:bg-blue-500 hover:text-blue-800 group flex items-center rounded-md bg-blue-600 text-white text-sm font-medium px-4 py-2 mr-2" onClick={this.handleSearch}><FormattedMessage id="Search" /></button>                            
                                <ImportButton module={AuthorityModules.Dealer} action={AuthorityActions.Create} url="/Dealer/ImportDealers" callback={this.populateListData} template="/Resources/Templates/DealerImportTemplate.xlsx" />
                                <div className="ml-4">
                                    <CreateButton module={AuthorityModules.Dealer} action={AuthorityActions.Create} url="/dealer/CreateDealer" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    {contents}
                    <Pager currentPage={this.state.currentPage} pageCount={this.state.pageCount} totalCount={this.state.totalCount} onPageChange={this.handlePageChange} />
                </div>
                <AttachAccountDialog onRef={(ref) => { this.attachAccountDialogForm = ref }} open={this.state.openAttachAccount} onClose={this.onAttachAccountDialogClose} populateListData={this.populateListData} />
            </div>

        );
    }

    async populateListData() {
        const token = await authService.getAccessToken();
        const name = this.state.name;
        const code = this.state.code;
        const company = this.state.company;
        const response = await fetch('dealer/GetDealers?page=' + this.state.currentPage + "&name=" + name + "&code=" + code + "&company=" + company, {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ dealers: data.entities, pageCount: data.pageCount, totalCount: data.totalCount, loading: false });
    }
}
