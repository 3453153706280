import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import { FormattedMessage  } from 'react-intl';
import { LockClosedIcon } from '@heroicons/react/solid'
import { LanguageSwitch } from '../language/LanguageSwitch';
import  WsaLogo  from '../Images/WSA.png';
// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {
    static displayName = Login.name;
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            message: undefined,
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;       
        if (username && password) {
            this.login(this.getReturnUrl());
        }
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LoginActions.Login:
                //this.login(this.getReturnUrl());
                break;
            
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                this.setState({ message: error });
                break;
            case LoginActions.Profile:
                this.redirectToProfile();
                break;
            case LoginActions.Register:
                this.redirectToRegister();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    WarningMessage(message) {
        if (message) {
            return (<FormattedMessage id={message} />);
        }

        return ('');
    }

    render() {
        const action = this.props.action;
        const { message, username, password, submitted } = this.state;
                        
        const warning = this.WarningMessage(message);
        switch (action) {
            case LoginActions.Login:
                return (
                    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                        <div className="absolute top-0 right-0">
                            <LanguageSwitch />
                        </div>
                        <div className="max-w-md w-full space-y-8">
                            <div>
                                <p className="flex justify-center">
                                    Test
                                </p>
                                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900"><FormattedMessage id="SignInAccount"/></h2>
                                <p className="mt-2 text-center text-sm text-red-600">
                                    {warning}
                                </p>
                            </div>
                            <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={this.handleSubmit}>
                                <div className="rounded-md shadow-sm -space-y-px">
                            <div className={(submitted && !username ? ' has-error' : '')}>
                                        <label htmlFor="username" className="sr-only">Username</label>
                                        <FormattedMessage id="AccountName">
                                            {placeholderText=><input type="text"
                                            required
                                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            name="username" value={username} onChange={this.handleChange}
                                            placeholder={placeholderText} />}
                                        </FormattedMessage>
                                        
                                        {submitted && !username &&
                                            <div className="text-red-600  text-xs"><FormattedMessage id="Required"/></div>
                                }
                            </div>
                            <div className={(submitted && !password ? ' has-error' : '')}>
                                        <label htmlFor="password" className="sr-only">Password</label>
                                        <FormattedMessage id="password">
                                            {placeHolderText=>
                                             <input type="password"
                                             required
                                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                             name="password" value={password} onChange={this.handleChange}
                                             placeholder ={placeHolderText}/>}
                                        </FormattedMessage>
                                       
                                {submitted && !password &&
                                            <div className="text-red-600 text-xs"><FormattedMessage id="Required" /></div>
                                }
                                    </div>
                                </div>                               
                            <div>
                                    <button className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                            <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                                        </span>
                                        <FormattedMessage id="SignIn"/>
                                    </button>                                
                                    
                            </div>
                            </form>
                        </div>
                    </div>
                    );
            case LoginActions.LoginCallback:
                return (<div>Processing login callback</div>);
            case LoginActions.Profile:
            case LoginActions.Register:
                return (<div></div>);
            default:
                throw new Error(`Invalid action '${action}'`);
            
    }
    }

    async login(returnUrl) {
       
        const { username, password } = this.state;
        const state = { returnUrl, username, password };
        if (username && password) {
            const result = await authService.signIn(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    //await this.navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    this.setState({ message: result.message });
                    break;
                default:
                    throw new Error(`Invalid status result ${result.status}.`);
            }
        }
    }
    
    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser they get sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}






