import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { LanguageSwitch } from './language/LanguageSwitch';
import { FormattedMessage } from 'react-intl';
import authService from './api-authorization/AuthorizeService';
import avatar from './Images/dummy-avatar.jpg'

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    constructor(props) {
        super(props);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    async handleLogoutClick(e) {
        e.preventDefault();
        const returnUrl = `${window.location.origin}`; 
        const state = { returnUrl };
        const result = await authService.signOut(state);
        //switch (result.status) {            
        //    case AuthenticationResultStatus.Success: //TODO check
    }

    renderLogoutSection() {
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        return (
            <Link to="#" onClick={ this.handleLogoutClick} className="flex items-center mr-4 hover:text-blue-100">
                <span className="inline-flex mr-1">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                </span>
                <FormattedMessage id="logout" />
            </Link>
            );
    }

    render() {
        
        var user = this.props.user;
        if (!user) {
            return null;
        }
        const userId = user && user.accountId;
        const userName = user && user.name;
        let logoutSection = this.renderLogoutSection();
                
      return (
          <nav className="bg-white border-b border-gray-200 fixed z-30 w-full">
              <div className="px-3 py-3 lg:px-5 lg:pl-3">
                  <div className="flex items-center justify-between">
                      <div className="flex items-center justify-start">
                      <button id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar" className="lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded">
                          <svg id="toggleSidebarMobileHamburger" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                          </svg>
                          <svg id="toggleSidebarMobileClose" className="w-6 h-6 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                          </svg>
                      </button>
                      <a href="https://demo.themesberg.com/windster/" className="text-xl font-bold flex items-center lg:ml-2.5">
                          <img src="https://demo.themesberg.com/windster/images/logo.svg" className="h-6 mr-2" alt="Windster Logo"/>
                              <span className="self-center whitespace-nowrap">WYF</span>
                          </a>
                      </div>              
                      <div className="flex items-center">
                  <div></div>
                  <ul className="flex items-center">
                      <li>
                          <LanguageSwitch/>
                      </li>
                     
                          </ul>
                          <Link className="flex items-center justify-start md:justify-center pl-3 w-14 md:w-64  border-none" to={"/account/profile/" + userId}>
                              <img className="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src={avatar} />
                              <span className="hidden md:block">{userName}</span>
                          </Link>
                          {logoutSection}
                      </div>
                  </div>
              </div>
          </nav>
    );
  }
}
