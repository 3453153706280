import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { BaseTextBox } from '../controls/BaseTextBox';
import { BaseCombobox } from '../controls/BaseCombobox';
import { BackButton } from '../controls/BackButton';
import { BasePasswordBox } from '../controls/BasePasswordBox';
import Toast from '../controls/BaseToast';
import validator from 'validator';
import { ModalView } from '../common/ModalView';

export class AccountForm extends ModalView {
    static displayName = AccountForm.name;

    constructor(props) {
        super(props);
        this.state = { open: false, name: '', selectedRoleId: '', accountGroup: '0', password: '', confirmPassword: '', cellPhone: '', email: '', fullName: '', roles: [], loadingRoles: true, errMessage: '', passwordErrMessage: '', confirmPasswordErrMessage: '' };
        this.handleBaseTextBoxChange = this.handleBaseTextBoxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAccountGroupChange = this.handleAccountGroupChange.bind(this);
        this.handleRoleSelectChange = this.handleRoleSelectChange.bind(this);
    }

    async loadRoles() {
        const token = await authService.getAccessToken();
        const response = await fetch('role/GetRoles', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        var selectRoleId = '';
        if (data.length > 1) {
            selectRoleId = data[0].id;
        }
        this.setState({ roles: data, selectedRoleId: selectRoleId, loadingRoles: false });

        var id = this.props.id;
        if (id) {
            this.locaData(id);
        }
    }

    componentDidMount() {
        this.loadRoles();
    }

    async locaData(id) {
        const token = await authService.getAccessToken();
        fetch('account/getaccount/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    name: data.name, selectedRoleId: data.role.id, accountGroup: data.accountGroup, cellPhone: data.cellPhone, email: data.email, fullName: data.fullName
                });
            });
    }


    handleAccountGroupChange(event) {
        event.preventDefault();
        const target = event.target;
        const value = target.value;

        this.setState({
            accountGroup: value
        });
    }
    handleRoleSelectChange(event) {
        event.preventDefault();
        const target = event.target;
        const value = target.value;

        this.setState({
            selectedRoleId: value
        });
    }
    handleBaseTextBoxChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const name = this.state.name.trim();
        const password = this.state.password.trim();
        const confirmPassword = this.state.confirmPassword.trim();
        const roleId = this.state.selectedRoleId;
        const accountGroup = this.state.accountGroup;
        const cellphone = this.state.cellPhone.trim();
        const email = this.state.email.trim();
        const fullName = this.state.fullName.trim();

        //  send request to the server
        const token = await authService.getAccessToken();
        const isNew = this.props.isNew;
        let response = null;
        if (name.length == 0) {
            this.setState({ errMessage: 'Required' });            
        }

        if (isNew) {
            if (password.length == 0) {
                this.setState({ passwordErrMessage: 'Required' });                
            }
            if (!validator.isStrongPassword(password, {
                minLength: 6, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
                Toast.warn('PasswordRuleRequired');               
            } 
            if (password !== confirmPassword) {
                this.setState({ confirmPassword: '' });
                this.setState({ confirmPasswordErrMessage: 'ConfirmPasswordError' });               
            }

            response = await fetch('account/Create', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'name': name,
                    'password': password,
                    'roleId': roleId,
                    'accountGroup': parseInt(accountGroup),
                    'cellPhone': cellphone,
                    'email': email,
                    'fullName': fullName,
                })
            });


        }
        else {
            response = await fetch('account/Edit', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'name': name,
                    'id': this.props.id,
                    'roleId': roleId,
                    'accountGroup': parseInt(accountGroup),
                    'cellPhone': cellphone,
                    'email': email,
                    'fullName': fullName
                })
            });
        }

        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    super.setOpen(false);
                }
                else {
                    Toast.warn(data.message);                    
                }
            }
        }
        else {
            console.error('response.status:' + response.status)           
        }
    }

    rendPasswordSection() {
        return (<div className="col-span-6 sm:col-span-3">
            <label htmlFor="examplePassword"><FormattedMessage id="password" /></label>
            <BasePasswordBox name="password" autocomplete="new-password" value={this.state.password} onChange={this.handleBaseTextBoxChange} errmessage={this.state.passwordErrMessage} />
        </div>
        );
    }
    rendConfirmPasswordSection() {
        return (<div className="col-span-6 sm:col-span-3">
            <label htmlFor="confirmPassword"><FormattedMessage id="confirmpassword" /></label>
            <BasePasswordBox name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleBaseTextBoxChange} errmessage={this.state.confirmPasswordErrMessage} />
            {/* {this.state.showConfirmPasswordError ? <div className="text-red-600  text-xs"><FormattedMessage id="ConfirmPasswordError" /></div> : ''}*/}
        </div>);
    }

    renderContent() {
        let passwordSection = '';
        let confirmPasswordSection = '';
        const isNew = this.props.isNew;
        if (isNew) {
            passwordSection = this.rendPasswordSection();
            confirmPasswordSection = this.rendConfirmPasswordSection();
        }
        console.log(this.state.roles);

        return (
           
                    <form onSubmit={this.handleSubmit}>                                                  
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Name" /></label>
                                <BaseTextBox classnames="mt-1" type="text" name="name" id="name" placeholder="" value={this.state.name} onChange={this.handleBaseTextBoxChange} errmessage={this.state.errMessage} />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="roleSelect" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Role" /></label>
                                <BaseCombobox classnames="mt-1" name="roleSelect" id="roleSelect" onChangeCapture={this.handleRoleSelectChange} value={this.state.selectedRoleId}>
                                    {this.state.roles.map((item, index) => (<option key={index} value={item.id}>{item.name}</option>)
                                    )}

                                </BaseCombobox>

                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="accountGroup" className="block text-sm font-medium text-gray-700"><FormattedMessage id="AccountGroup" /></label>
                                <BaseCombobox classnames="mt-1" name="accountGroup" id="accountGroup" onChangeCapture={this.handleAccountGroupChange} value={this.state.accountGroup}>
                                    <FormattedMessage id="Factory">
                                        {text => <option value="0">{text}</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="Dealer">
                                        {text => <option value="1">{text}</option>}
                                    </FormattedMessage>
                                </BaseCombobox>
                            </div>
                            {passwordSection}
                            {confirmPasswordSection}
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="fullName" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Fullname" /></label>
                                <BaseTextBox classnames="mt-1" type="text" name="fullName" id="fullName" value={this.state.fullName} onChange={this.handleBaseTextBoxChange} />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="cellPhone" className="block text-sm font-medium text-gray-700"><FormattedMessage id="cellphone" /></label>
                                <BaseTextBox classnames="mt-1" type="text" name="cellPhone" id="cellPhone" placeholder="" value={this.state.cellPhone} onChange={this.handleBaseTextBoxChange} />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Email" /></label>
                                <BaseTextBox classnames="mt-1" type="email" name="email" id="email" placeholder="" value={this.state.email} onChange={this.handleBaseTextBoxChange} />
                            </div>

                        </div>                                                                        
                    </form>
               
        );
    }
}
