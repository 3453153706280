import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import orderService from '../OrderService';
import { CheckBoxGroup } from './CheckBoxGroup';

export class OtherOptions extends Component {
    static displayName = OtherOptions.name;
    constructor(props) {
        super(props);
        let value = props.otherOptionsValue;
        let otherOptions = props.otherOptions;
        let otherOptionValues = new Array();
        
        if (otherOptions && value) {
            var array = value.split(';');
            var count = array.length;
            for (var i = 0; i < count; i++) {
                var childArray = array[i].split(',');
                if (childArray.length === 3) {
                    let id = childArray[0];
                    let left = childArray[1] === "1" ? true : false;
                    let right = childArray[2] === "1" ? true : false;
                    var option = this.getOtherOptionValues(otherOptions, id, left, right);
                    if (option) {
                        otherOptionValues.push(option);
                    }
                }
            }
        }
        else if (otherOptions){
            for (var i = 0; i < otherOptions.length; i++) {
                let op = { id: otherOptions[i].id, name: otherOptions[i].name, left: false, right: false };
                otherOptionValues.push(op);                 
            }
        }
        this.state = {
            otherOptionValues: otherOptionValues,
            cord: props.cord,
            shellCoating: props.shellCoating,
            cordValue: props.cordValue,
            shellCoatingValue: props.shellCoatingValue
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleCordChanged = this.handleCordChanged.bind(this);
        this.handleShellCoatingChanged = this.handleShellCoatingChanged.bind(this);
    }

    handleShellCoatingChanged(e) {
        this.setState({ shellCoatingValue: e });
        
    }

    handleCordChanged(e) {
        this.setState({ cordValue: e });
    }

    getOtherOptionValues(otherOptions, id, left, right) {
        let returnValue = null;
        for (var i = 0; i < otherOptions.length; i++) {
            if (id == otherOptions[i].id) {
                returnValue = { id: id, name: otherOptions[i].name, left: left, right: right };
                break;
            }
        }
        return returnValue;
    }
   
    handleCheckboxChange(event) {
        const target = event.target;
        const value = target.checked;
        const name = target.name;
        if (name.startsWith('left')) {
            let id = name.substring(4);
            let arr = this.state.otherOptionValues.slice();
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].id == id) {
                    arr[i].left = value;
                }
            }

            this.setState({
                otherOptionValues: arr
            });
        }
        else if (name.startsWith('right')) {
            let id = name.substring(5);
            let arr = this.state.otherOptionValues.slice();
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].id == id) {
                    arr[i].right = value;
                }
            }

            this.setState({
                otherOptionValues: arr
            });
        }        
    }  

    componentDidMount() {
        this._subscription = orderService.subscribe(() => this.orderCommitting());       
    }

    orderCommitting() {
        orderService.updateOtherOptions(this.state);
    }

    componentWillUnmount() {
        orderService.unsubscribe(this._subscription);
    }

      
    render() {
        if (!this.state.otherOptionValues) {
            return null;
        }
               
        return (
            <div className="">
                <div className="border-b-2 border-blue-900 text-blue-800 font-bold" ><h2><FormattedMessage id="OtherOptions" /></h2></div>
                <div className="m-1">
                    <table className="w-full">      
                        <tbody>
                          <tr className="border-b border-gray-50">
                            <td colSpan="2"></td>
                                <td className="w-6">
                                    <div className="bg-blue-200  flex flex-col text-center">
                                <label><FormattedMessage id="Left" /></label>
                                        <div className="self-center mx-auto flex-shrink-0 flex items-center justify-center h-4 w-4 lg:h-4 lg:w-4 rounded-full bg-blue-500 sm:mx-0 sm:h-5 sm:w-5">
                                    <label className="text-white">L</label>
                                </div>
                                </div>
                            </td>
                                <td className="w-6">
                                    <div className="bg-red-200  flex flex-col text-center">
                                    <label><FormattedMessage id="Right" /></label>
                                        <div className="self-center mx-auto flex-shrink-0 flex items-center justify-center h-4 w-4 lg:h-4 lg:w-4 rounded-full bg-blue-500 sm:mx-0 sm:h-5 sm:w-5">
                                        <label className="text-white">R</label>
                                    </div>

                                </div>
                            </td>
                            </tr>
                            {this.state.otherOptionValues.map((op, index) => <tr className="border-b border-gray-50" key={index}><td className="py-1" colSpan="2">{op.name}</td>
                                <td className="w-6 text-center"><input type="checkbox" name={"left" + op.id} checked={op.left} onChange={this.handleCheckboxChange} /></td>
                                <td className="w-6 text-center"><input type="checkbox" name={"right" + op.id} checked={op.right} onChange={this.handleCheckboxChange} /></td>
                            </tr>)}
                        </tbody>
                    </table>
                    <CheckBoxGroup title="Cord" items={this.state.cord} value={this.state.cordValue} onSelectValueChanged={this.handleCordChanged} />
                    <CheckBoxGroup title="ShellCoating" items={this.state.shellCoating} value={this.state.shellCoatingValue} onSelectValueChanged={this.handleShellCoatingChanged} />
                </div>
                  
          </div>
    );
  }
}