import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom'

export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
          <div>TODO</div>
    );
  }
}
