import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService'

export class EditButton extends Component {    
    constructor(props) {
        super(props);        
        this.state = { isAllow: false };
    }
    async componentDidMount() {
        if (this.props.module && this.props.action) {
            const isAllow = await authService.isAllow(this.props.module, this.props.action);
            this.setState({
                isAllow: isAllow
            });
        }
    }
    render() {
        var url = this.props.url;
        var displayName = this.props.displayname;
        if (displayName) {

        }
        else {
            displayName = "edit";
        }
        return this.state.isAllow&&(
            <Link {...this.props} className="text-indigo-600 hover:text-indigo-900" to={url}><FormattedMessage id={displayName} /></Link>
        );
    }    
}

