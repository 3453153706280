import React, { Component } from 'react';
import { ProductBrandTemplate, ProductCategory, ProductOptionCategory } from '../infrastructure/AppConstants';

import { BaseOptionsView } from './BaseOptionsView';


export class Options extends Component {
  static displayName = Options.name;

  constructor(props) {
      super(props);
       
      this.state = { selectedOrderTemplate: ProductBrandTemplate.WSA, selectedProductCategory: ProductCategory.CustomMade, selectedOptionCategroy: -1 };
      
      this.handleOrderTemplateChanged = this.handleOrderTemplateChanged.bind(this);     
      this.handleProductCategroyChanged = this.handleProductCategroyChanged.bind(this);
      this.handleOptionCategroyChanged = this.handleOptionCategroyChanged.bind(this);   
      this.getSelectedOptionCategroy = this.getSelectedOptionCategroy.bind(this);
    }

    handleOptionCategroyChanged(e) {
        this.setState({ selectedOptionCategroy: e });
    }

    getSelectedOptionCategroy() {
        return this.state.selectedOptionCategroy;
    }

    handleProductCategroyChanged(e) {
        this.setState({ selectedProductCategory: e, selectedOptionCategroy:-1 });
    }

    handleOrderTemplateChanged(e) {
        this.setState({ selectedOrderTemplate: e, selectedOptionCategroy: -1  });
    }

    renderBaseOptionsView(selectedOptionCategroy) {
        return (<BaseOptionsView optionCategory={selectedOptionCategroy}/>);
    }



    render() {
        var content = '';
        let { selectedOrderTemplate, selectedProductCategory, selectedOptionCategroy } = this.state;
        if (selectedOptionCategroy === -1) {
            //Do nothing
        }
        else {
             
                if (selectedProductCategory == ProductCategory.Eardrum) {
                    switch (selectedOptionCategroy) {
                       
                        
                        case ProductOptionCategory.EardrumBteVent:
                        case ProductOptionCategory.EardrumRicVent:
                        case ProductOptionCategory.EardrumRicCordLength:
                        case ProductOptionCategory.EardrumRicReceiver:
                        case ProductOptionCategory.EardrumRicCord:
                            content = this.renderBaseOptionsView(selectedOptionCategroy);
                            break;
                    }
                }
                else {
                    switch (selectedOptionCategroy) {
                        
                        case ProductOptionCategory.Attachment:
                        case ProductOptionCategory.AntiEarwaxDevice:
                        case ProductOptionCategory.Vent:
                        case ProductOptionCategory.OnlyForRICSeries:
                        case ProductOptionCategory.OtherOptions:
                        case ProductOptionCategory.Cord:
                        case ProductOptionCategory.ShellCoating:
                            content = this.renderBaseOptionsView(selectedOptionCategroy);
                            break;
                        
                    }
                }
            

        }
        
    return (
      <div className="mt-4 mx-4">
        <div className="w-full overflow-hidden rounded-lg shadow-xs">
            <div className="flex justify-start">
              
            </div>
        </div>
        <div className="w-full overflow-hidden rounded-lg shadow-xs">
          {content}          
        </div>       
      </div>
    );
  }
}
