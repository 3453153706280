import React, { Component, Fragment } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react'
import { KeyIcon } from '@heroicons/react/outline'
import { BaseTextBox } from '../controls/BaseTextBox';
import Toast from '../controls/BaseToast';
import validator from 'validator';

export class ResetPasswordDialog extends Component {
    static displayName = ResetPasswordDialog.name;

    constructor(props) {
        super(props);
        this.state = { name: '', password: '', confirmPassword: '', open: false, accountId: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ open: false, password: '', confirmPassword: '' });
    }
    async handleClick(id, name, e) {
        e.preventDefault();
        if (!id) {
            const user = await authService.getUser();
            id = user.accountId;
            name = user.name;
        }
        this.setState({ open: true, accountId: id, name: name });
    }

    async componentDidMount() {
        this.props.onRef(this);
       // console.log(this.state.accountId);
        if (!this.state.accountId) {
            const user = await authService.getUser();
         //   console.log(user);
            this.setState({ name: user.name, accountId: user.accountId });
        }

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const password = this.state.password.trim();
        if (!validator.isStrongPassword(password, {
            minLength: 6, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            Toast.warn('PasswordRuleRequired');
            return;
        } 
        const confirmPassword = this.state.confirmPassword.trim();
        const id = this.state.accountId;
        if (!(password && password == confirmPassword)) {
            Toast.warn('ConfirmPasswordError');
            return;
        }
        //  send request to the server
        const token = await authService.getAccessToken();
        const response = await fetch('account/ResetPassword', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
            body: JSON.stringify({
                'id': id,
                'password': password,
            })
        }).then(data => {
            this.handleClose();
            if (this.props.accountId) {
                if (data.ok) {
                    this.logout();
                }
                else {
                    console.warn('Edit faile:' + data.text);
                }
            }
        });
    }

    async logout() {
        const returnUrl = `${window.location.origin}`;
        const state = { returnUrl };
        const result = await authService.signOut(state);
    }

    render() {
        const { open } = this.state;
        return (            
                <Transition.Root show={open} as={Fragment}>
                    <Dialog
                        as="div"
                        static
                        className="overflow-x-hidden overflow-y-auto fixed top-4 left-0 right-0 md:inset-0 z-50 justify-center items-center h-modal sm:h-full flex"
                        open={open}
                        onClose={this.handleClose}>
                    <div className="relative w-full max-w-2xl px-4 h-full md:h-auto">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                &#8203;
                        </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-200 sm:mx-0 sm:h-10 sm:w-10">
                                                <KeyIcon className="h-6 w-6 text-yellow-700" aria-hidden="true" />
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg text-left leading-6 font-medium text-gray-900 mt-2">
                                                <FormattedMessage id="resetpassword" />
                                            </Dialog.Title>
                                            </div>
                                        </div>
                                        <div className="mt-3 mb-4 px-2 text-center sm:mt-0 sm:ml-4 sm:text-center w-full">
                                            <div className="mt-2">
                                                <label className="inline-block mr-3"><FormattedMessage id="AccountName" /></label>
                                                <label className="py-2 px-3 rounded-md bg-white sm:text-sm dark:bg-gray-800 border-0 border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-white-500 focus:outline-none w-3/5 mt-2" >{this.state.name}</label>
                                            </div>
                                            <div className="mt-2 px-4">
                                                <FormattedMessage id="NewPassword">
                                                    {placeholderText => <BaseTextBox autocomplete="new-password" type="password" name="password"
                                                        placeholder={placeholderText} value={this.state.password} onChange={this.handleInputChange} />}
                                                </FormattedMessage>
                                            </div>
                                            <div className="mt-2 px-4">
                                                <FormattedMessage id="confirmpassword">
                                                {placeholderText => <BaseTextBox type="password" name="confirmPassword"
                                                    placeholder={placeholderText} value={this.state.confirmPassword} onChange={this.handleInputChange} />}
                                                </FormattedMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                       
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={this.handleClose} >
                                            <FormattedMessage id="cancel" />
                                        </button>
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={this.handleSubmit}>
                                            <FormattedMessage id="Submit" />
                                        </button>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>            
        );
    }
}
