import React, { Component } from 'react';
import toLocalString from '../infrastructure/DateTimeFunctions';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { EditButton } from '../controls/EditButton';
import { CreateButton } from '../controls/CreateButton';
import { DeleteButton } from '../controls/DeleteButton';
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';

export class Roles extends Component {
  static displayName = Roles.name;

  constructor(props) {
    super(props);
    this.state = { roles: [], loading: true };
    this.handleDelete = this.handleDelete.bind(this);       
  }

  componentDidMount() {
    this.populateListData();
  }

  async handleDelete(id) {          
    this.setState(
        {
        roles: this.state.roles.filter((rec) => {
            return (rec.id != id);
        })
        });
         
  }
    
  static renderTable(roles, handleEdit, handleDelete, handleEditActions) {
      return (<div className="w-full overflow-x-auto">
          <table className='w-full'>
              <thead>
                  <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                      <th className="px-4 py-3"><FormattedMessage id="Name" /></th>
                      <th className="px-4 py-3"><FormattedMessage id="Description" /></th>                  
                      <th className="px-4 py-3"><FormattedMessage id="ModifyTime" /></th>
                      <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                {roles.map(a =>
                    <tr key={a.id} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400 text-sm">
                    <td className="px-4 py-3">{a.name}</td>
                    <td className="px-4 py-3">{a.description}</td>                    
                        <td className="px-4 py-3">{toLocalString(a.modifyTime)}</td>
                        <td className="px-4 py-3  whitespace-nowrap text-sm font-medium">
                            <EditButton module={AuthorityModules.Role} action={AuthorityActions.Edit} url={'/account-role/EditRole/' + a.id} />|
                            <EditButton module={AuthorityModules.Role} action={AuthorityActions.Edit} url={'/account-role/RoleActions/' + a.id} displayname="RoleActions" />|
                            <DeleteButton module={AuthorityModules.Role} action={AuthorityActions.Delete} displayname="Delete" url="role/delete/" id={a.id} onDeleteExecuted={handleDelete} />                            
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>        
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Roles.renderTable(this.state.roles, this.handleEdit, this.handleDelete, this.handleEditActions);

    return (
        <div className="mt-4 mx-4">      
            <div className="w-full overflow-hidden rounded-lg shadow-xs mb-2">   
                <div className="flex justify-end items-end">
                    <CreateButton module={AuthorityModules.Role} action={AuthorityActions.Create} url="/account-role/CreateRole" />
                </div>
            </div>
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                {contents}
                <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                    <span><FormattedMessage id="RecordCount"/> {this.state.roles.length}</span>
                </div>
            </div>
      </div>
    );
  }



  async populateListData() {
    const token = await authService.getAccessToken();
    const response = await fetch('role/GetRoles', {
      headers: !token ? {} : { 'Authorization': `${token}` }
    });
    const data = await response.json();
    this.setState({ roles: data, loading: false });
  }
}
