import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { FormattedMessage } from 'react-intl';

export class Loading extends Component {
    static displayName = Loading.name;

    constructor(props) {
        super(props);
        this.state = { loading: false }
        this.showLoading = this.showLoading.bind(this);
        this.closeLoading = this.closeLoading.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }
    showLoading() {
        this.setState({ loading: true });
    }
    closeLoading() {
        this.setState({ loading: false });
    }


    render() {
        return this.state.loading && (
            < div className="bg-gray-500 bg-opacity-75 transition-opacity fixed z-10 inset-0 overflow-y-auto flex flex-wrap content-center justify-center" >
                <div className="w-1/4 m-2 h-32 items-center justify-center flex flex-col flex-wrap">
                    <ReactLoading type="spin" height={'64px'} color="blue" width={'64px'} />
                    <span className="text-xl font-bold text-gray-200"><FormattedMessage id="Loading" /></span>
                </div>
            </div>
        );
    }
}
