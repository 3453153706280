import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
export class ComboBoxGroup extends Component {   
    constructor(props) {
        super(props);
        
        let leftValue= "-1";
        let rightValue = "-1";
        let value = this.props.value;
        if (value) {
            var vals = value.split(',');
            if (vals.length == 2) {
                leftValue = vals[0];
                rightValue = vals[1];
            }
        }
        this.state = { leftValue: leftValue, rightValue: rightValue};
        this.handleLeftValueChange = this.handleLeftValueChange.bind(this);
        this.handleRightValueChange = this.handleRightValueChange.bind(this);
    }

    handleLeftValueChange(e) {
        var handle = this.props.onSelectValueChanged;
        if (handle) {
            this.setState({ leftValue: e.target.value }, () => {
                var e = this.state.leftValue + ',' + this.state.rightValue;
                handle(e);
            });
            
        }
    }

    handleRightValueChange(e) {
        var handle = this.props.onSelectValueChanged;
        if (handle) {
            this.setState({ rightValue: e.target.value }, ()=>{
                var e = this.state.leftValue + ',' + this.state.rightValue;
                handle(e);
            });
        }
    }

    render() {
        const title = this.props.title;        
        let items = this.props.items;
        if (!items) {
            items = [];
        }
        return (
            <div className="mx-4 mb-4">
                <div className="border-b-2 border-blue-900 text-blue-800 font-bold" ><h2><FormattedMessage id={title} /></h2></div>
                <div className="flex flex-row mt-2">
                    <div className="bg-blue-200 w-6 flex flex-col text-center">
                        <label><FormattedMessage id="Left" /></label>
                        <div className="self-center mx-auto flex-shrink-0 flex items-center justify-center h-4 w-4 lg:h-4 lg:w-4 rounded-full bg-blue-500 sm:mx-0 sm:h-5 sm:w-5">
                            <label className="text-white">L</label>
                        </div>
                    </div>
                    <div className="flex-grow place-self-center border-t border-gray-50">
                        <select className="w-48 ml-2 h-8" onChange={this.handleLeftValueChange} value={this.state.leftValue} >
                            <FormattedMessage id="PickOne" >
                                {text => <option value="-1">{text}</option>}
                            </FormattedMessage>
                            {items.map((i, index) => <option key={ index} value={i.id}>{i.name}</option>)}
                        </select>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="bg-red-200 w-6 flex flex-col text-center">
                        <label><FormattedMessage id="Right" /></label>
                        <div className="self-center mx-auto flex-shrink-0 flex items-center justify-center h-4 w-4 lg:h-4 lg:w-4 rounded-full bg-blue-500 sm:mx-0 sm:h-5 sm:w-5">
                            <label className="text-white">R</label>
                        </div>
                    </div>
                    <div className="flex-grow place-self-center  border-b border-gray-50">
                        <select className="w-48 ml-2 h-8" onChange={this.handleRightValueChange} value={this.state.rightValue}>
                            <FormattedMessage id="PickOne" >
                                {text => <option value="-1">{text}</option>}
                            </FormattedMessage>
                            {items.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                        </select>
                    </div>
                </div>
            </div>

        );

    }

}
