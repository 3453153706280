import { ApplicationPaths, ApplicationName } from './ApiAuthorizationConstants';

export class AuthorizeService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _user = null;
    _isAuthenticated = false;

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    async getUser() {
        var user = this.getWithExpiry("user");
        if (user) {
            return user;
        }
        /* const returnUrl = `${window.location.origin}`;
         const state = { returnUrl };
         await this.signOut(state);*/
        /*  if (this._user) {
              return this._user;
          }
  
          const user = this.getWithExpiry("user");
          return user;*/
    }

    async getAccessToken() {
        const user = await this.getUser();
        if (user) {
            this.setUser(user);
            return user && user.accessToken;
        }
        const returnUrl = `${window.location.origin}`;
        const state = { returnUrl };
        await this.signOut(state);

    }

    async isAllow(module, action) {
        var a = module * 100 + action;
        const user = await this.getUser();
        if (user && user.actions && user.actions.indexOf(a) > -1) {
            return true;
        }
        return false;
    }

    async signIn(state) {

        try {
            const response = await fetch('Authentication/Authenticate', {
                method: 'POST',
                headers:
                {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'name': state.username,
                    'password': state.password
                })
            });
            const silentUser = await response.json();
            var status = silentUser.authenticateStatus;
            switch (status) {
                case 0:
                    this.updateState(silentUser);
                    return this.success(state);
                case 1:
                    return this.error("WrongAccount");
                case 2:
                    return this.error("WrongPassword");
                case 3:
                    return this.error("AccountObsoleted");
                default:
                    return this.error("Unknow error");
            }
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent authentication error: ", silentError);

            return this.error(silentError);
        }
    }


    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state) {

        try {
            this.updateState(undefined);
            return this.success(state);
        } catch (popupSignOutError) {
            console.log("Popup signout error: ", popupSignOutError);
            return this.error(popupSignOutError);
        }
    }

    updateState(user) {
        this._user = user;
        if (user == undefined) {
            localStorage.removeItem('user');
        }
        else {
            // localStorage.setItem('user', JSON.stringify(user));
            this.setUser(user);
        }
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }
    setUser(user) {
        this.setWithExpiry("user", user, 1000 * 60 * 30);
    }
    setWithExpiry(key, value, ttl) {
        const now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item));
    }
    getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }
    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    createArguments(state) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state) {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }


    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
