import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class Contact extends Component {
    static displayName = Contact.name;

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div className="mt-2 border p-3" style={{ width:'98%' }}>
                <div className="w-1/3 inline-block align-top">
                    <div><FormattedMessage id="WidexCompany" /></div>
                    <div><FormattedMessage id="AfterSalesServiceAddress" /></div>
                </div>
                <div className="w-1/3 inline-block text-center align-top">
                    <div>www.widex.com.cn</div>
                </div>
                <div className="w-1/3 inline-block align-top">
                    <div><FormattedMessage id="WeChatOfficialAccount" /></div>
                    <div><FormattedMessage id="HotLine" />：400-921-7066</div>
                </div>
            </div>
        );
    }
}