import React, { Component } from 'react';
import { Route,Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Dealers } from './components/dealer/Dealers';
import { CreateDealer } from './components/dealer/CreateDealer';
import { EditDealer } from './components/dealer/EditDealer';
import { SalesAccountCode } from './components/dealer/SalesAccountCode';
import { DeliverAccountCode } from './components/dealer/DeliverAccountCode';
import { Orders } from './components/order/Orders';
import { EditOrder } from './components/order/EditOrder';
import { Accounts } from './components/account/Accounts';
import { Profile } from './components/account/Profile';
import { Roles } from './components/account-role/Roles';
import { CreateRole } from './components/account-role/CreateRole';
import { EditRole } from './components/account-role/EditRole';
import { RoleActions } from './components/account-role/RoleActions';
import { Products } from './components/product/Products';
import { CreateProduct } from './components/product/CreateProduct';
import { EditProduct } from './components/product/EditProduct';
import { Stores } from './components/store/Stores';
import { Options } from './components/product/Options';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { LanguageStrings } from './components/language/LanguageConstants';
import { IntlProvider } from 'react-intl'
import { ToastContainer } from 'react-toastify';
import { TableTags } from "./components/table-tag/TableTags";
import { Dashboard } from "./components/Dashboard/Dashboard";
import 'react-toastify/dist/ReactToastify.css';

export default class App extends Component {
  static displayName = App.name;    
    render() {
        var language = localStorage.getItem('language')??"zh";
      return (          
          <IntlProvider messages={LanguageStrings[language]} locale={language} defaultLocale="zh">
              <Layout>
                <Switch>
                      <AuthorizeRoute exact path='/' component={Home} />         
                      <AuthorizeRoute path='/order-list/Orders' exact component={Orders} />
                      <AuthorizeRoute path='/order/EditOrder/:id' component={EditOrder} />
                      <AuthorizeRoute path='/dealer-list/Dealers' exact component={Dealers} />
                      <AuthorizeRoute path='/dealer/CreateDealer' component={CreateDealer} />
                      <AuthorizeRoute path='/dealer/EditDealer/:id' component={EditDealer} />
                      <AuthorizeRoute path='/dealer/SalesAccountCode/:id' component={SalesAccountCode} />
                      <AuthorizeRoute path='/dealer/DeliverAccountCode/:id' component={DeliverAccountCode} />
                      <AuthorizeRoute path='/account-role/Roles' component={Roles} />
                      <AuthorizeRoute path='/account-role/CreateRole' component={CreateRole} />
                      <AuthorizeRoute path='/account-role/editrole/:id' component={EditRole} />
                      <AuthorizeRoute path='/account-role/roleactions/:id' component={RoleActions} />
                      <AuthorizeRoute path='/product-list/products' component={Products} />
                      <AuthorizeRoute path='/product/createproduct' component={CreateProduct} />
                      <AuthorizeRoute path='/product/editproduct/:id' component={EditProduct} />
                      <AuthorizeRoute path='/store-list/stores' component={Stores} />                      
                      <AuthorizeRoute path='/product-option/options' component={Options} />                    
                      <AuthorizeRoute path='/account-list/accounts' component={Accounts} />                      
                      <AuthorizeRoute path='/account/profile/:id' component={Profile} />
                      <AuthorizeRoute path='/table-tag/TableTags' component={TableTags} />
                      <AuthorizeRoute path='/Dashboard/Dashboard' component={Dashboard} />
                  <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                </Switch>
                  
            </Layout>
             
             <ToastContainer></ToastContainer>
          </IntlProvider>
    );
  }
}
