import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import productService from './ProductService';
import { FormattedMessage } from 'react-intl';
import { EditButton } from '../controls/EditButton';
import { CreateButton } from '../controls/CreateButton';
import toLocalString from '../infrastructure/DateTimeFunctions';
import { ConfirmAlert } from '../controls/ConfirmAlert'
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';
import { BaseTextBox } from '../controls/BaseTextBox';
import { Switch } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export class ProductMenu extends Component {    
    constructor(props) {
        super(props);
        let model = props.model;
        this.state = { name: model.name, categories: model.categories, products: model.products,  newTempCategory:'', deleteId: null, confirmOpen: false, isAllowDelete:false };
        this.handleDelete = this.handleDelete.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.deletedConfirm = this.deletedConfirm.bind(this);
        this.handleAddCategory = this.handleAddCategory.bind(this);
        this.handleBaseTextBoxChange = this.handleBaseTextBoxChange.bind(this);
        this.handleDelteCategory = this.handleDelteCategory.bind(this);
    }

    handleDelteCategory(event) {
        const target = event.currentTarget;        
        const id = target.id;
        let categories = this.state.categories;
        let newCategories = categories.filter(c => c.id != id);
        this.setState({ categories: newCategories });
    }

    handleBaseTextBoxChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleAddCategory(e) {
        e.preventDefault();
        var menuId = this.props.model.id;
        const token = await authService.getAccessToken();
        let response = await fetch('Product/CreateCategory', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
            body: JSON.stringify( {
                "name": this.state.newTempCategory,
                "menuId": menuId
            })
        });
        let newCategory = await response.json();
        let categories = this.state.categories;
        categories.push(newCategory);
        this.setState({ categories: categories });
    }

    guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

    async componentDidMount() {

        const isAllow = await authService.isAllow(AuthorityModules.Product, AuthorityActions.Delete);
        this.setState({
            isAllowDelete: isAllow
        });
    }
    deletedConfirm(id, e) {
        e.preventDefault();
        this.setState({ confirmOpen: true, deleteId: id });
    }

    onConfirmClose() {
        this.setState({ confirmOpen: false, deleteId: null });
    }
    async handleDelete(e) {
        const id = this.state.deleteId;
        if (!id) { return; }
        this.setState(
            {
                menus: this.state.menus.filter((rec) => {
                    return (rec.id != id);
                })
            });
        const token = await authService.getAccessToken();
        fetch('product/Delete/' + id, {
            method: 'DELETE',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` }
        }).then(data => {

        });
        this.onConfirmClose();
    }
    static toCategoriesStrings(categories, categoryIds) {
        let categoryStrings = [];
        categories.forEach(function (c) {
            if (categoryIds && categoryIds.contains(c.id)) {
                categoryStrings.push(c.name);
            }            
        });

        return categoryStrings;
    }

    static renderTable(categories, products, handleEdit, handleDelete, handleEditActions,isAllowDelete) {
        return (<div className="w-full overflow-x-auto">
            <table className='w-full'>
                <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th className="px-4 py-3"><FormattedMessage id="Name" /></th>
                     {/*   <th className="px-4 py-3"><FormattedMessage id="Description" /></th>*/}
                        <th className="px-4 py-3">Logo</th>                        
                        <th className="px-4 py-3"><FormattedMessage id="ModifyTime" /></th>
                        <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>                        
                    </tr>
                </thead>
                <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    {products.map(a =>
                        <tr key={a.id} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400 text-sm">
                            <td className="px-4 py-3">{a.name}</td>
                            {/*   <td className="px-4 py-3">{a.description}</td>*/}
                            <td className="px-4 py-3">{a.logoUrl && <img className="w-16 h-12" src={a.logoUrl} />}</td>

                            <td className="px-4 py-3">{ProductMenu.toCategoriesStrings(categories, a.categories).map(c => <span>{ c},</span>)}</td>
                            <td className="px-4 py-3 whitespace-nowrap text-sm font-medium">
                                <EditButton module={AuthorityModules.Product} action={AuthorityActions.Edit} url={'/product/EditProduct/' + a.id} />
                                {isAllowDelete && <span>|<a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={(e) => handleDelete(a.id, e)}><FormattedMessage id="delete" /></a></span>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
       
        const { name, categories, products, newTempCategory } = this.state;
        return (
          
                    <div>
                        <label className="text-center">{name}</label>
                        <div className="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
                            <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                                <label>Category</label>
                                <div className="flex mt-1 mb-4">
                                    <BaseTextBox name="newTempCategory" type="text" value={newTempCategory} onChange={this.handleBaseTextBoxChange} />
                                    <button onClick={this.handleAddCategory}>add</button>
                                </div>
                                <div className="grid grid-cols-1 gap-4">
                                    {
                                        categories.map(c =>
                                            <div key={c.id} className="relative bg-white shadow-sm text-gray-900 cursor-pointer  border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1">
                                                <button
                                                    id={c.id}
                                                    type="button"
                                                    className="absolute top-1 right-1 text-gray-400 hover:text-gray-500 "
                                                    onClick={this.handleDelteCategory}
                                                >
                                                    <span className="sr-only">Close</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                                <label>{c.name}</label>
                                            </div>)
                                    }
                                </div>
                            </div>
                            <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
                                <div className="w-full overflow-hidden rounded-lg shadow-xs  mb-2">
                                    <div className="flex justify-end items-end">
                                        <CreateButton module={AuthorityModules.Product} action={AuthorityActions.Create} url="/product/CreateProduct" />
                                    </div>
                                </div>
                                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                            {ProductMenu.renderTable(categories, products, this.handleEdit, this.deletedConfirm, this.handleEditActions, this.state.isAllowDelete)}
                                    <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                                <span><FormattedMessage id="RecordCount" /> {products.length}</span>
                                    </div>
                                </div>

                            </div>

                            <ConfirmAlert onRef={(ref) => { this.confirmRef = ref }} onCancel={this.onConfirmClose} onOk={(id, e) => { this.handleDelete(id, e) }} open={this.state.confirmOpen} />
                        </div>
                    </div>
                                    
        );
    }
    
}
