import React, { Component } from 'react';
import authService from '../../../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';

export class Address extends Component {
    static displayName = Address.name;

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onReturnToAssignAddress = this.onReturnToAssignAddress.bind(this);
        this.onReturnToDealerChange = this.onReturnToDealerChange.bind(this);
        this.state = { orderDealer: this.props.model, returnToDealerSelected: false, returnToAssignAddressSelected: false, address: "", addressee: "", phone: "",dealer:null };
    }
    componentDidMount() {
        this.props.onRef(this);
        const dealer = this.state.orderDealer;
        this.setState({
            address: dealer.address, addressee: dealer.addressee, phone: dealer.phone
        })
        //获取经销商信息
        authService.getAccessToken().then(token => {
            fetch("dealer/GetDealer/" + this.state.orderDealer.id, { headers: !token ? {} : { 'Authorization': `${token}` } })
                .then(response => response.json())
                .then(data => {
                    this.setState({ dealer: data });
                })
        });
    }

    onChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    onReturnToDealerChange(e) {
        this.setState({ returnToDealerSelected: e.target.value });
        if (e.target.value) {
            const dealer = this.state.dealer;
            this.setState({
                returnToAssignAddressSelected: false, address: dealer.address, addressee: dealer.contact, phone: dealer.phone
            })
        }
    }
    onReturnToAssignAddress(e) {
        this.setState({ returnToAssignAddressSelected: e.target.value });
        if (e.target.value) {
            this.setState({
                returnToDealerSelected: false, address: "", addressee: "", phone: ""
            })
        }
    }
    render() {
        return (
            <table className="border-r border-b border-separate inline-table ml-2 align-top" style={{ borderSpacing: '0', width:'49%' }}>
                <tbody>
                    <tr>
                        <td colSpan="2" className="border-l border-t p-2 font-black"><FormattedMessage id="ReturnAddressDescription" /></td>
                        <td colSpan="2" className="border-l border-t p-2 ">
                            <input type="checkbox" name="returnToDealerSelected" className="w-4 h-4 align-middle mr-2" checked={this.state.returnToDealerSelected} onChange={this.onReturnToDealerChange} /><FormattedMessage id="SendBackToTheDealer" />
                          </td>
                    </tr>
                    <tr>
                        <td className="border-l border-t p-2" colSpan="4">
                            <input type="checkbox" name="returnToAssignAddressSelected" checked={this.state.returnToAssignAddressSelected} className="w-4 h-4 align-middle mr-2" onChange={this.onReturnToAssignAddress} /><FormattedMessage id="ReturnToTheDesignatedAddress" />
                            </td>
                    </tr>
                    <tr className="h-8">
                        <td className="border-l border-t p-2 relative" colSpan="4">
                            <input type="text" name="address" onChange={this.onChange} value={this.state.address??""} className="p-2 absolute left-0 top-0 border-0 outline-none w-full h-full" />
                        </td>
                    </tr>
                    <tr>
                        <td className="border-l border-t p-2" width="20%"><FormattedMessage id="Addressee" /></td>
                        <td className="border-l border-t p-2">
                            <input type="text" name="addressee" onChange={this.onChange} value={this.state.addressee??""}  className="p-2 border-0 outline-none w-full h-full" />
                        </td>
                        <td className="border-l border-t p-2" width="20%"><FormattedMessage id="Phone" /></td>
                        <td className="border-l border-t p-2">
                            <input type="text" name="phone" onChange={this.onChange} value={this.state.phone??""} className="p-2 border-0 outline-none w-full h-full" />
                        </td>
                    </tr>
                </tbody>
            
            </table>
            );
    }
}