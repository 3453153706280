import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import orderService from '../OrderService';
import { OrderTextBox } from '../OrderTextBox';

export class CustomerInfo extends Component {
    static displayName = CustomerInfo.name;
    constructor(props) {
        super(props);
        const { customerName, customerAge, isRegularCustomer, currentBrand, originalShellScanFileUpload, machineBodyNumber, communicateWithMatchMaker, enclosureDesignReference } = props.model;
        this.state = { options: [], uploading: false, customerName: customerName, customerAge: customerAge, isRegularCustomer: isRegularCustomer, currentBrand: currentBrand, machineBodyNumber: machineBodyNumber, originalShellScanFileUpload: originalShellScanFileUpload, communicateWithMatchMaker: communicateWithMatchMaker, enclosureDesignReference: enclosureDesignReference };
        this.handleLeftChange = this.handleLeftChange.bind(this);
        this.handleRightChange = this.handleRightChange.bind(this);      
        this.handleSelectChanged = this.handleSelectChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
       
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (target.type === 'radio') {
            if (name === "enclosureDesignReference") {
                this.setState({
                    [name]: parseInt(value)
                });
            }
            else {
                this.setState({
                    [name]: value === "1" ? true : false
                });
            }            
        }
        else {
            this.setState({
                [name]: value
            });
        }       
    }

    componentDidMount() {
        this._subscription = orderService.subscribe(() => this.orderCommitting());
        //this.populateProductBand();
    }

    orderCommitting() {
        orderService.updateCustomerInfo(this.state);
    }

    componentWillUnmount() {
        orderService.unsubscribe(this._subscription);
    }

    handleSelectChanged(e) {
        var handler = this.onSelectChanged;
        if (handler) {
            handler(e);
        }
    }
   
    handleLeftChange(e) {
        var handler = this.onLeftChanged;
        if (handler) {
            handler(e);
        }
    }

    handleRightChange(e) {
        var handler = this.onRightChanged;
        if (handler) {
            handler(e);
        }
    }
      
    render() {
        let items = this.props.items;
        if (!items) {
            items = new Array();
            
        }
       
        return (
            <div className="mx-2">
                <div className="grid grid-cols-2 border-2 border-gray-50 ">
                    <div className="flex">
                        <label className="place-self-center"><FormattedMessage id="CustomerName" /></label>
                        <OrderTextBox classnames="flex-grow" name="customerName" value={this.state.customerName ?? ""} onChange={this.handleInputChange} />                        
                    </div>     
                    <div className="flex">                        
                        <label className="place-self-center"><FormattedMessage id="CustomerAge" /></label>
                        <OrderTextBox classnames="flex-grow" name="customerAge" value={this.state.customerAge ?? ""} onChange={this.handleInputChange} />
                    </div>         
                </div>
                <div className="mt-2 grid grid-cols-2  border-l-2 border-r-2 border-t-2 border-blue-800 text-blue-800">                    
                    <div className="bg-blue-800 text-white font-bold py-2 text-center">
                        <label className="place-self-center"><FormattedMessage id="RequiredForRegularCustomer" /></label>                        
                    </div>
                    <div className="grid grid-cols-2 pl-1">
                        <label className="mx-2 place-self-center"><FormattedMessage id="IsRegularCustomer" /></label>
                        <div className="place-self-center grid grid-cols-2">
                            <div className="flex justify-center">
                                <div className="place-self-center inline-flex items-center">
                                    <input type="radio" name="isRegularCustomer" value="1" checked={this.state.isRegularCustomer === true} onChange={this.handleInputChange} />
                                    <span className="ml-1"><FormattedMessage id="Yes" /></span>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div className="place-self-center inline-flex items-center">
                                    <input className="ml-1" type="radio" name="isRegularCustomer" value="0" checked={this.state.isRegularCustomer === false} onChange={this.handleInputChange} />
                                    <span className="ml-1"><FormattedMessage id="No" /></span>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="border-l-2 border-r-2 border-blue-800 text-blue-800">     
                    <div className="flex flex-row justify-between border-t border-b border-gray-50 text-left">   
                    <label className="mx-2 place-self-center"><FormattedMessage id="CurrentBrand" /></label>                    
                        <OrderTextBox classnames="lg:w-64" name="currentBrand" value={this.state.currentBrand ?? ""} onChange={this.handleInputChange} />
                    </div>
                </div>
                <div className="border-l-2 border-r-2 border-blue-800 text-blue-800">   
                    <div className="flex flex-row justify-between border-b border-gray-50 text-left">   
                        <label className="mx-2 place-self-center"><FormattedMessage id="MachineBodyNumber" /></label>
                        <OrderTextBox classnames="lg:w-64" name="machineBodyNumber" value={this.state.machineBodyNumber ?? ""} onChange={this.handleInputChange} />
                    </div>
                </div>
                <div className="grid grid-cols-2 border-l-2 border-r-2 border-blue-800 text-blue-800">
                    <div className="flex flex-row justify-between pr-4 border-r  border-b border-gray-50">
                        <label className="mx-2"><FormattedMessage id="OriginalShellScanFileUpload" /></label>
                        <div className="grid grid-cols-2">
                            <div className="flex justify-center">
                                <div className="place-self-center inline-flex items-center">
                                    <input type="radio" type="radio" name="originalShellScanFileUpload" value="1" checked={this.state.originalShellScanFileUpload === true} onChange={this.handleInputChange} />
                                    <span className="ml-1"><FormattedMessage id="Yes" /></span>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div className="place-self-center inline-flex items-center">
                                    <input className="ml-2" type="radio" name="originalShellScanFileUpload" value="0" checked={this.state.originalShellScanFileUpload === false} onChange={this.handleInputChange} />
                                    <span className="ml-1"><FormattedMessage id="No" /></span>
                                </div>
                            </div>
                        </div>                                  
                    </div>
                    <label className="border-b border-gray-50 pl-2"><FormattedMessage id="EnclosureDesignReference" /></label>
                </div>
                <div className="grid grid-cols-2 border-l-2 border-r-2 border-b-2 border-blue-800 text-blue-800">
                    <div className="flex flex-row justify-between pr-4 border-r border-gray-50">
                        <label className="mx-2"><FormattedMessage id="CommunicateWithMatchMaker" /></label>
                        <div className="grid grid-cols-2">
                            <div className="flex justify-center">
                                <div className="place-self-center inline-flex items-center">
                                    <input type="radio" type="radio" name="communicateWithMatchMaker" value="1" checked={this.state.communicateWithMatchMaker === true} onChange={this.handleInputChange} />
                                    <span className="ml-1"><FormattedMessage id="Yes" /></span>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div className="place-self-center inline-flex items-center">
                                    <input className="ml-2" type="radio" name="communicateWithMatchMaker" value="0" checked={this.state.communicateWithMatchMaker === false} onChange={this.handleInputChange} />
                                    <span className="ml-1"><FormattedMessage id="No" /></span>
                                </div>
                            </div>
                        </div>                            
                    </div>
                    <div className="place-self-start grid grid-cols-2 pl-2">
                        <div className="flex justify-center">
                            <div className="place-self-center inline-flex items-center">
                                <input type="radio" name="enclosureDesignReference" value="0" checked={this.state.enclosureDesignReference === 0} onChange={this.handleInputChange} />
                                <span className="ml-1"><FormattedMessage id="CopyOriginal" /></span>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <div className="place-self-center inline-flex items-center">
                                <input className="ml-1" type="radio" name="enclosureDesignReference" value="1" checked={this.state.enclosureDesignReference === 1} onChange={this.handleInputChange} />
                                <span className="ml-1"><FormattedMessage id="ReferenceProduction" /></span>
                            </div>
                        </div>
                    </div>                             
                </div>
          </div>
    );
  }
}