import authService from '../api-authorization/AuthorizeService';
import { OrderStatus, ProductBrandTemplate, ProductCategory } from '../infrastructure/AppConstants';

export class OrderService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _order = null;

    async getCurrentOrder() {
        /*  if (this._order) {
              return this._order;
          }
  
          this._order = JSON.parse(localStorage.getItem('currentOrder'));    */
        return this._order;
    }

    async getOrderInfoFromServer(id) {

        try {

            const token = await authService.getAccessToken();
            //const response = fetch('order/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } });
            //const data = await response.json();
            //this.updateState(data);
            await fetch('order/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
                .then(response => response.json())
                .then(data => {
                    this.updateState(data);
                });
            return await this.getCurrentOrder();

        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent get order info error: ", silentError);

            return null;
        }
    }

    async updateDealerInfo(state) {
        const { dealerName, deliverAccountCode, saleAccountCode, addressee, zipcode, phone, address } = state;
        var order = await this.getCurrentOrder();
        order.dealerInfo.dealerName = dealerName;
        order.dealerInfo.deliverAccountCode = deliverAccountCode;
        order.dealerInfo.saleAccountCode = saleAccountCode;
        order.dealerInfo.addressee = addressee;
        order.dealerInfo.zipcode = zipcode;
        order.dealerInfo.phone = phone;
        order.dealerInfo.address = address;
        this.updateState(order);
    }

    async updateCustomerInfo(state) {
        const { customerName, customerAge, isRegularCustomer, currentBrand, originalShellScanFileUpload, machineBodyNumber, communicateWithMatchMaker, enclosureDesignReference } = state;
        var order = await this.getCurrentOrder();
        order.customerInfo.customerName = customerName;
        order.customerInfo.customerAge = customerAge;
        order.customerInfo.isRegularCustomer = isRegularCustomer;
        order.customerInfo.currentBrand = currentBrand;
        order.customerInfo.originalShellScanFileUpload = originalShellScanFileUpload;
        order.customerInfo.machineBodyNumber = machineBodyNumber;
        order.customerInfo.communicateWithMatchMaker = communicateWithMatchMaker;
        order.customerInfo.enclosureDesignReference = enclosureDesignReference;
    }

    async updateHearingLossData(state) {
        const { left, right, specialRequirements } = state;
        var data = '';
        for (let i = 0; i < left.length; i++) {
            data += left[i];
            if (i < left.length - 1) {
                data += ','
            }
        }
        data += ';'
        for (let i = 0; i < right.length; i++) {
            data += right[i];
            if (i < right.length - 1) {
                data += ','
            }
        }
        this._order.hearingLossData = data;
        this._order.specialRequirements = specialRequirements;
    }

    async updateEardrumHearingLossData(state) {
        const { left, right } = state;
        var data = '';
        for (let i = 0; i < left.length; i++) {
            data += left[i];
            if (i < left.length - 1) {
                data += ','
            }
        }
        data += ';'
        for (let i = 0; i < right.length; i++) {
            data += right[i];
            if (i < right.length - 1) {
                data += ','
            }
        }
        this._order.eardrumCustomerInfo.hearingLossData = data;
    }

    async updateCustomizedMachineProductOptions(state) {
        const { leftEnclousureType, leftWireless, leftLine, leftSpecsMatrix, leftColor,
            rightEnclousureType, rightWireless, rightLine, rightSpecsMatrix, rightColor,
            attachment, antiEarwaxDevice, vent, onlyForRICSeries } = state;
        let customizedMachineModel = leftEnclousureType + "," + leftWireless + "," + leftLine + "," + leftSpecsMatrix + "," + leftColor + ";" +
            rightEnclousureType + "," + rightWireless + "," + rightLine + "," + rightSpecsMatrix + "," + rightColor;
        this._order.customizedMachineModelInf.attachment = attachment;
        this._order.customizedMachineModelInf.antiEarwaxDevice = antiEarwaxDevice;
        this._order.customizedMachineModelInf.vent = vent;
        this._order.customizedMachineModelInf.onlyForRICSeries = onlyForRICSeries;
        this._order.customizedMachineModelInf.customizedMachineModel = customizedMachineModel;
    }

    updateEardrumInfo(state) {
        //const { model } = state;
        //this._order.eardrumInfo = model;
    }
    async updateOtherOptions(state) {
        const {
            otherOptionValues,
            cordValue,
            shellCoatingValue
        } = state;
        var options = '';
        for (let i = 0; i < otherOptionValues.length; i++) {
            if (i === otherOptionValues.length - 1) {
                options += otherOptionValues[i].id + ',' + (otherOptionValues[i].left ? '1' : '0') + ',' + (otherOptionValues[i].right ? '1' : '0')
            }
            else {
                options += otherOptionValues[i].id + ',' + (otherOptionValues[i].left ? '1' : '0') + ',' + (otherOptionValues[i].right ? '1' : '0') + ';'
            }

        }

        this._order.customizedMachineModelInf.otherOptions = options;
        this._order.customizedMachineModelInf.cord = cordValue;
        this._order.customizedMachineModelInf.shellCoating = shellCoatingValue;
    }

    async updateWidexOrder(order) {
        this._order = order;
    }

    CheckFieldRequiredInvalid(field) {

        let trimfield = !field ? '' : field.trim();
        if (trimfield === '') {
            return true;
        }
        return false;
    }

    async commit(action, scanFiles) {
        try {
            this.notifySubscribers(action);
            var order = await this.getCurrentOrder();
            var status = order.status;
            switch (action) {
                case OrderCommitAction.Save:
                    if (OrderStatus.Draft == status || status == OrderStatus.Retreat) { }
                    break;
                case OrderCommitAction.Submit:
                    if (order.productBrand == ProductBrandTemplate.WSA) {
                        if (this.CheckFieldRequiredInvalid(order.dealerInfo.deliverAccountCode)) {
                            return this.error("DeliverAccountCodeRequired");
                        }

                        if (order.productCategory === ProductCategory.CustomMade) {
                            if (this.CheckFieldRequiredInvalid(order.customerInfo.customerName)) {
                                return this.error("CustomerNameRequired");
                            }

                            //if (this.CheckFieldRequiredInvalid(order.customerInfo.customerAge)) {
                            //    return this.error("CustomerAgeRequired");
                            //}

                            //let age = parseInt(order.customerInfo.customerAge);
                            //let res = typeof (age) == 'number';
                            //if (age <= 0 ) {
                            //    return this.error("CustomerAgeMoreThanZero");
                            //}
                        }
                        else {
                            if (this.CheckFieldRequiredInvalid(order.eardrumCustomerInfo.customerName)) {
                                return this.error("CustomerNameRequired");
                            }

                            //if (this.CheckFieldRequiredInvalid(order.eardrumCustomerInfo.customerAge)) {
                            //    return this.error("CustomerAgeRequired");
                            //}

                            //var age = parseInt(order.eardrumCustomerInfo.customerAge);
                            //let res = typeof (age) == 'number';
                            //if (age <= 0 )  {
                            //    return this.error("CustomerAgeMoreThanZero");
                            //}
                        }
                        if (!scanFiles || scanFiles.length == 0) {
                            return this.error("");
                        }

                    }
                    order.status = OrderCommitAction.Submit;
                    break;
                case OrderCommitAction.Retreat:
                    order.status = OrderCommitAction.Retreat;
                    break;
            }
            const token = await authService.getAccessToken();
            const response = await fetch('order/Edit', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify(order)
            });
            console.log(response);
            const data = await response.text();

            //   this.updateState(undefined);
            return this.success({ state: "success" });
        } catch (popupSignOutError) {
            console.log("Popup commit error: ", popupSignOutError);
            return this.error(popupSignOutError);
        }
    }

    updateState(order) {
        this._order = order;
        /* if (order == undefined) {
             localStorage.removeItem('orderService');
         }
         else {
             localStorage.setItem('orderService', JSON.stringify(order));
         }      */
    }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers(e) {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback(e);
        }
    }

    error(message) {
        return { status: OrderResultStatus.Fail, message };
    }

    success(state) {
        return { status: OrderResultStatus.Success, state };
    }

    static get instance() { return orderService }
}

const orderService = new OrderService();

export default orderService;

export const OrderResultStatus = {
    Success: 'success',
    Fail: 'fail'
};

export const OrderCommitAction = {
    Save: 0,
    Submit: 1,
    Retreat: 2
};

export const DirectionConsts = {
    Left: "left",
    Right: "right"
}
