import React, { Component } from 'react';

export class BaseTextArea extends Component {    
       
    render() {
        var props = this.props;
        return (
            <textarea {...props} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" />
        );
    }    
}
