import React, { Component } from 'react';

export class BaseCombobox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <select {...this.props} className={"shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 " + this.props.classnames}>
            </select>
        )

    }
}
