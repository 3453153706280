import authService from '../api-authorization/AuthorizeService';

export class StoreService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _categories = [];

    

    async getStoreInfoFromServer(id) {

        try {

            const token = await authService.getAccessToken();
            //const response = fetch('Store/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } });
            //const data = await response.json();
            //this.updateState(data);
            await fetch('Store/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
                .then(response => response.json())
                .then(data => {
                    this.updateState(data);
                });
            return await this.getCategories();

        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent get Store info error: ", silentError);

            return null;
        }
    }

    async getStores() {
        try {

            const token = await authService.getAccessToken();
            let response = await fetch('store/GetStores', {
                headers: !token ? {} : { 'Authorization': `${token}` }
            });
            let data = await response.json();           
            return data;

        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent get Store info error: ", silentError);

            return [];
        }
    }
   

    


    CheckFieldRequiredInvalid(field) {

        let trimfield = !field ? '' : field.trim();
        if (trimfield === '') {
            return true;
        }
        return false;
    }

    async commit(action, scanFiles) {
        try {
            this.notifySubscribers(action);
            var Store = await this.getCategories();
            var status = Store.status;
            switch (action) {
                case StoreCommitAction.Save:
                    
                    break;
                case StoreCommitAction.Submit:
                    
                    Store.status = StoreCommitAction.Submit;
                    break;
                case StoreCommitAction.Retreat:
                    Store.status = StoreCommitAction.Retreat;
                    break;
            }
            const token = await authService.getAccessToken();
            const response = await fetch('Store/Edit', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify(Store)
            });
            console.log(response);
            const data = await response.text();

            //   this.updateState(undefined);
            return this.success({ state: "success" });
        } catch (popupSignOutError) {
            console.log("Popup commit error: ", popupSignOutError);
            return this.error(popupSignOutError);
        }
    }

    updateState(Store) {
        this._Store = Store;
        /* if (Store == undefined) {
             localStorage.removeItem('StoreService');
         }
         else {
             localStorage.setItem('StoreService', JSON.stringify(Store));
         }      */
    }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers(e) {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback(e);
        }
    }

    error(message) {
        return { status: StoreResultStatus.Fail, message };
    }

    success(state) {
        return { status: StoreResultStatus.Success, state };
    }

    static get instance() { return StoreService }
}

const productService = new StoreService();

export default productService;

export const StoreResultStatus = {
    Success: 'success',
    Fail: 'fail'
};

export const StoreCommitAction = {
    Save: 0,
    Submit: 1,
    Retreat: 2
};

export const DirectionConsts = {
    Left: "left",
    Right: "right"
}
