import authService from '../api-authorization/AuthorizeService';
import {  ProductBrandTemplate, ProductCategory } from '../infrastructure/AppConstants';

export class ProductService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _categories = [];

    async getCategories(forceLoad) {
        if (!forceLoad && this._categories && this._categories.length > 0) {
            return this._categories;
        }
        const token = await authService.getAccessToken();
        let response = await fetch('product/GetProductCategories', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        this._categories = await response.json();
        return this._categories;
    }

    async getProductInfoFromServer(id) {

        try {

            const token = await authService.getAccessToken();
            //const response = fetch('Product/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } });
            //const data = await response.json();
            //this.updateState(data);
            await fetch('Product/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
                .then(response => response.json())
                .then(data => {
                    this.updateState(data);
                });
            return await this.getCategories();

        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent get Product info error: ", silentError);

            return null;
        }
    }

   

    


    CheckFieldRequiredInvalid(field) {

        let trimfield = !field ? '' : field.trim();
        if (trimfield === '') {
            return true;
        }
        return false;
    }

    async commit(action, scanFiles) {
        try {
            this.notifySubscribers(action);
            var Product = await this.getCategories();
            var status = Product.status;
            switch (action) {
                case ProductCommitAction.Save:
                    
                    break;
                case ProductCommitAction.Submit:
                    if (Product.productBrand == ProductBrandTemplate.WSA) {
                        if (this.CheckFieldRequiredInvalid(Product.dealerInfo.deliverAccountCode)) {
                            return this.error("DeliverAccountCodeRequired");
                        }

                        if (Product.productCategory === ProductCategory.CustomMade) {
                            if (this.CheckFieldRequiredInvalid(Product.customerInfo.customerName)) {
                                return this.error("CustomerNameRequired");
                            }

                            //if (this.CheckFieldRequiredInvalid(Product.customerInfo.customerAge)) {
                            //    return this.error("CustomerAgeRequired");
                            //}

                            //let age = parseInt(Product.customerInfo.customerAge);
                            //let res = typeof (age) == 'number';
                            //if (age <= 0 ) {
                            //    return this.error("CustomerAgeMoreThanZero");
                            //}
                        }
                        else {
                            if (this.CheckFieldRequiredInvalid(Product.eardrumCustomerInfo.customerName)) {
                                return this.error("CustomerNameRequired");
                            }

                            //if (this.CheckFieldRequiredInvalid(Product.eardrumCustomerInfo.customerAge)) {
                            //    return this.error("CustomerAgeRequired");
                            //}

                            //var age = parseInt(Product.eardrumCustomerInfo.customerAge);
                            //let res = typeof (age) == 'number';
                            //if (age <= 0 )  {
                            //    return this.error("CustomerAgeMoreThanZero");
                            //}
                        }
                        if (!scanFiles || scanFiles.length == 0) {
                            return this.error("");
                        }

                    }
                    Product.status = ProductCommitAction.Submit;
                    break;
                case ProductCommitAction.Retreat:
                    Product.status = ProductCommitAction.Retreat;
                    break;
            }
            const token = await authService.getAccessToken();
            const response = await fetch('Product/Edit', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify(Product)
            });
            console.log(response);
            const data = await response.text();

            //   this.updateState(undefined);
            return this.success({ state: "success" });
        } catch (popupSignOutError) {
            console.log("Popup commit error: ", popupSignOutError);
            return this.error(popupSignOutError);
        }
    }

    updateState(Product) {
        this._Product = Product;
        /* if (Product == undefined) {
             localStorage.removeItem('ProductService');
         }
         else {
             localStorage.setItem('ProductService', JSON.stringify(Product));
         }      */
    }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers(e) {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback(e);
        }
    }

    error(message) {
        return { status: ProductResultStatus.Fail, message };
    }

    success(state) {
        return { status: ProductResultStatus.Success, state };
    }

    static get instance() { return ProductService }
}

const productService = new ProductService();

export default productService;

export const ProductResultStatus = {
    Success: 'success',
    Fail: 'fail'
};

export const ProductCommitAction = {
    Save: 0,
    Submit: 1,
    Retreat: 2
};

export const DirectionConsts = {
    Left: "left",
    Right: "right"
}
