import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Pager } from '../controls/Pager'
import { SearchButton } from '../controls/SearchButton';
import { DeleteButton } from '../controls/DeleteButton';
import { ActivateButton } from '../controls/ActivateButton';
import { CreateButton } from '../controls/CreateButton';
import toLocalString from '../infrastructure/DateTimeFunctions';
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';
import { ResetPasswordDialog } from './ResetPasswordDialog';
import { AccountForm } from './AccountForm';

export class Accounts extends Component {
    static displayName = Accounts.name;

    constructor(props) {
        super(props);        
        this.state = { accounts: [], currentPage: 0, pageCount: 0, totalCount: 0, loading: true, isAllowEdit: false };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearching = this.handleSearching.bind(this);
        this.handleSearchDid = this.handleSearchDid.bind(this);
        this.handleDeleted = this.handleDeleted.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
    }

    handleCreateSubmit() {

    }

    handleActivate(id) {
        if (id) {
            this.state.accounts.map((rec) => {
                if (id == rec.id)
                    rec.state = 0;
            });
            this.setState(
                {
                    accounts: this.state.accounts.slice()

                });
        }
    }

    handleDeleted(id) {
        if (id) {
            this.state.accounts.map((rec) => {
                if (id == rec.id)
                    rec.state = 1;
            });
            this.setState(
                {
                    accounts: this.state.accounts.slice()

                });
        }
    }

    handleSearchDid(data) {
        this.setState({ accounts: data.entities, pageCount: data.pageCount, totalCount: data.totalCount, loading: false });
    }

    handleSearching() {
        this.setState({ loading: true });
    }

    handlePageChange(index) {
        this.setState({ currentPage: index });
        this.populateListData();
    }

    async componentDidMount() {
        this.populateListData();
        const edit = await authService.isAllow(AuthorityModules.Account, AuthorityActions.Edit);
        this.setState({
            isAllowEdit: edit
        });
    }
    handleResetPassword(id,name, e) {
        e.preventDefault();
        this.resetPasswordDialog.handleClick(id,name, e);
    }
    static toStatusString(state) {
        if (state == 0) {
            return (<div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div><FormattedMessage id="Active" /></div>);
        }
        return (<div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-red-400 mr-2"></div><FormattedMessage id="Obsoleted" /> </div>);
    }

    static toStatusButton(state, id, handleDeleted, handelActivate) {
        if (state == 0) {
            return (<DeleteButton module={AuthorityModules.Account} action={AuthorityActions.Delete} displayname="Deactivate" confirmMsg="DeactivateConfirm" url="account/delete/" id={id} onDeleteExecuted={handleDeleted} />);
        }
        return (<ActivateButton module={AuthorityModules.Account} action={AuthorityActions.Delete} url="account/Activate/" id={id} onActivateExecuted={handelActivate} />);
    }

    static renderForecastsTable(accounts, handelActivate, handleDeleted, handleResetPassword,isAllowEdit) {
        return (
            <div className="flex flex-col">
            <div className="overflow-x-auto">
            <div className="align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden">
                <table className='table-fixed min-w-full divide-y divide-gray-200'>
                    <thead className="bg-gray-100">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded"/>
                                    <label for="checkbox-all" className="sr-only">checkbox</label>
                            </div>
                            </th>
                            <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="AccountName" /></th>
                            <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="Role" /></th>
                            <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="AccountGroup" /></th>
                            <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="Fullname" /></th>
                            <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="Phone" /></th>
                            <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="Email" /></th>
                            <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="Status" /></th>
                                <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="ModifyTime" /></th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase"><FormattedMessage id="Actions" /></th>
                        </tr>
                    </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                        {accounts.map(a =>
                            <tr key={a.name} className="hover:bg-gray-100">
                                <td class="p-4 w-4">
                                    <div class="flex items-center">
                                        <input id="checkbox-623232" aria-describedby="checkbox-1" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded"/>
                                            <label for="checkbox-623232" class="sr-only">checkbox</label>
</div>
</td>
                                <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{a.name}</td>
                                <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{a.roleName}</td>
                                <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900"><FormattedMessage id={a.accountGroup == 0 ? "Factory" : "Dealer"} /></td>
                                <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{a.fullName}</td>
                                <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{a.cellPhone}</td>
                                <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{a.email}</td>
                                <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{Accounts.toStatusString(a.state)}</td>
                                <td className="p-4 whitespace-nowrap text-base font-medium text-gray-900">{toLocalString(a.modifyTime)}</td>
                                <td className="p-4 whitespace-nowrap space-x-2">
                                    
                                    <AccountForm isNew={false} id={a.id} buttonName="Edit" />
                                    
                                    {isAllowEdit && <span>|<a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(e) => handleResetPassword(a.id, a.name, e)}><FormattedMessage id="resetpassword" /></a></span>}|
                            {Accounts.toStatusButton(a.state, a.id, handleDeleted, handelActivate)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Accounts.renderForecastsTable(this.state.accounts, this.handleActivate, this.handleDeleted, this.handleResetPassword, this.state.isAllowEdit);
        
        return (
            <div className="mt-4 mx-0">               
                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    <div className="flex justify-between items-center h-14">
                        <div className="w-1/2">
                        <SearchButton url="account/GetAccounts"
                            placeholder="AccountSearchPlaceholder"
                            onSearching={this.handleSearching}
                            onSearchDid={this.handleSearchDid}/>
                        </div>
                        <CreateButton module={AuthorityModules.Account} action={AuthorityActions.Create} url="/account/CreateAccount" />
                        <button type="button" data-modal-toggle="add-product-modal" className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center rounded-lg text-sm px-3 py-2 text-center sm:ml-auto">
                            <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path>
                            </svg>
                            Add product
                        </button>
                        <AccountForm isNew={true}/>
                    </div>

                </div>
                <div className="overflow-x-hidden overflow-y-auto fixed top-4 left-0 right-0 md:inset-0 z-50 justify-center items-center h-modal sm:h-full hidden" id="add-product-modal" aria-hidden="true">
              <div>test</div>      
                                           
</div>


                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    {contents}
                    <Pager currentPage={this.state.currentPage} pageCount={this.state.pageCount} totalCount={this.state.totalCount} onPageChange={this.handlePageChange} />
                </div>
                <ResetPasswordDialog onRef={(ref) => { this.resetPasswordDialog = ref }} />
            </div>
        );
    }

    async populateListData() {
        const token = await authService.getAccessToken();
        const response = await fetch('account/GetAccounts?page=' + this.state.currentPage, {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ accounts: data.entities, pageCount: data.pageCount, totalCount: data.totalCount, loading: false });
    }
}
