import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import BaseOptionsViewDialog from './BaseOptionsViewDialog'
import { ConfirmAlert } from '../controls/ConfirmAlert'
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';

export class BaseOptionsView extends Component {

    constructor(props) {
        super(props);
        this.state = { options: [], optionCategory: props.optionCategory, loading: true, open: false, confirmOpen: false, deleteId: null, isAllowCreate: false, isAllowEdit: false, isAllowDelete:false };
        this.onClose = this.onClose.bind(this);
        this.handleOnAdd = this.handleOnAdd.bind(this);
        this.populateListData = this.populateListData.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.deletedConfirm = this.deletedConfirm.bind(this);
        this.handleOnDeleted = this.handleOnDeleted.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
    }

    async componentDidMount() {
        this.populateListData();
        const create = await authService.isAllow(AuthorityModules.ProductOptions, AuthorityActions.Create);
        const edit = await authService.isAllow(AuthorityModules.ProductOptions, AuthorityActions.Edit);
        const del = await authService.isAllow(AuthorityModules.ProductOptions, AuthorityActions.Delete);
        this.setState({
            isAllowCreate: create, isAllowEdit: edit, isAllowDelete: del 
        });
    }
    onConfirmClose() {
        this.setState({ confirmOpen: false, deleteId: null });
    }
    async populateListData() {
        const token = await authService.getAccessToken();
        const response = await fetch('ProductOption/GetProductOptions?optionCategory=' + this.state.optionCategory, {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ options: data, loading: false });
    }
    handleOnEdit(id, e) {
        e.preventDefault();
        const options = this.state.options.filter(x => x.id == id)[0];
        this.baseOptionsViewDialogRef.bindProductOption(options);
        this.setState({ open: true });
        this.baseOptionsViewDialogRef.updateOptionCategory(this.state.optionCategory);
    }
    onClose(e) {
        this.setState({ open: false });
    }
    handleOnAdd() {
        this.setState({ open: true });
        this.baseOptionsViewDialogRef.updateOptionCategory(this.state.optionCategory);
    }
    deletedConfirm(id, e) {
        e.preventDefault();
        this.setState({ confirmOpen: true, deleteId: id });
    }
    async handleOnDeleted(e) {
        e.preventDefault();
        var id = this.state.deleteId;
        if (!id) { return; }
        this.setState({
            options: this.state.options.filter((rec) => {
                return (rec.id != id);
            })
        });
        const token = await authService.getAccessToken();
        await fetch('ProductOption/Delete/' + id, {
            method: 'DELETE',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` }
        }).then(data => {
        });
        this.setState({ confirmOpen: false, deleteId: null });
    }
    static renderTable(options, handleDelete, handleEdit,isAllowDelete,isAllowEdit) {
        return (<div className="w-full overflow-x-auto">
            <table className='w-full'>
                <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th className="px-4 py-3"><FormattedMessage id="Name" /></th>
                        <th className="px-4 py-3"><FormattedMessage id="Code" /></th>
                        <th className="px-4 py-3"><FormattedMessage id="Index" /></th>
                        <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    {options.map(a =>
                        <tr key={a.id} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400 text-sm">
                            <td className="px-4 py-3">{a.name}</td>
                            <td className="px-4 py-3">{a.code}</td>
                            <td className="px-4 py-3">{a.order}</td>
                            <td className="px-4 py-3  whitespace-nowrap text-sm font-medium">
                                {isAllowEdit && <a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={(e) => handleEdit(a.id, e)}><FormattedMessage id="edit" /></a>}  
                                {isAllowDelete && <span>|<a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={(e) => handleDelete(a.id, e)}><FormattedMessage id="delete" /></a></span>}     
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        var state = this.state.optionCategory;
        var prop = this.props.optionCategory;
        if (state != prop) {
            this.setState({ optionCategory: prop });
            this.populateListData();
        }
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : BaseOptionsView.renderTable(this.state.options, this.deletedConfirm, this.handleOnEdit, this.state.isAllowDelete, this.state.isAllowEdit);
        return (
            <div className="mt-3">
                {this.state.isAllowCreate && <button className="mb-2 py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600" onClick={this.handleOnAdd}><FormattedMessage id="Create" /></button>}
                {contents}
                <BaseOptionsViewDialog populateListData={this.populateListData} onRef={(ref) => { this.baseOptionsViewDialogRef = ref }} open={this.state.open} onClose={this.onClose}>
                </BaseOptionsViewDialog>
                <ConfirmAlert onRef={(ref) => { this.confirmRef = ref }} onCancel={this.onConfirmClose} onOk={(e) => { this.handleOnDeleted(e) }} open={this.state.confirmOpen} />
            </div>
        );
    }
}
