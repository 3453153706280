import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {DefaultImage } from '../../../infrastructure/AppConstants';

export class OrderNum extends Component {
    static displayName = OrderNum.name;

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="pt-2 grid grid-cols-3">
                <div className="relative">
                    <table className="border-r border-b border-separate w-60 absolute bottom-0" style={{ borderSpacing: '0' }}>
                            <tbody>
                                <tr>
                                    <td className="border-l border-t p-2"><FormattedMessage id="OrderNum" /></td>
                                    <td className="border-l border-t p-2">{this.props.orderNum}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                <div className="text-3xl font-bold h-full align-top text-center"><FormattedMessage id={this.props.title}/></div>
                <div className="flex justify-end px-2">
                    <img className="h-32" src={this.props.logoUrl ?? DefaultImage} onError={(e) => { e.target.src = DefaultImage }} />
                    </div>
            </div>
        );
    }
}