import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
export class CheckBoxGroup extends Component {   
    constructor(props) {
        super(props);
        
        let leftValue= "-1";
        let rightValue = "-1";
        let value = this.props.value;
        if (value) {
            var vals = value.split(',');
            if (vals.length == 2) {
                leftValue = vals[0];
                rightValue = vals[1];
            }
        }
        this.state = { items: props.items, leftValue: leftValue, rightValue: rightValue};
        this.handleLeftValueChange = this.handleLeftValueChange.bind(this);
        this.handleRightValueChange = this.handleRightValueChange.bind(this);
    }

    handleLeftValueChange(e) {
        let checked = e.target.checked;
        let value = e.target.value;
        let leftValue = value;
        if (!checked) {
            leftValue = '-1';
        }
        
        this.setState({ leftValue: leftValue }, () => {
            var handle = this.props.onSelectValueChanged;
            if (handle) {
                var e = this.state.leftValue + ',' + this.state.rightValue;
                handle(e);
            }
            });
            
        
    }

    handleRightValueChange(e) {
        let checked = e.target.checked;
        let value = e.target.value;
        let rightValue = value;
        if (!checked) {
            rightValue = '-1';
        }

        this.setState({ rightValue: rightValue }, () => {
            var handle = this.props.onSelectValueChanged;
            if (handle) {
                var e = this.state.leftValue + ',' + this.state.rightValue;
                handle(e);
            }
        });

    }    

    render() {
        const title = this.props.title;
        if (!this.state.items) {
            return null;
        }
        return (
            <table className="w-full">
                <tbody>
                {
                    this.state.items.map((item, index) => {
                        if (index == 0) {
                            return (<tr key={index} className="border-b border-gray-50">
                                <td rowSpan={this.state.items.length} width="33%"><FormattedMessage id={title} /></td>
                                <td>{item.name}</td>
                                <td className="w-6 text-center"><input type="checkbox" name="left" onChange={this.handleLeftValueChange} value={item.id} checked={item.id === this.state.leftValue} /></td>
                                <td className="w-6 text-center"><input type="checkbox" name="right" onChange={this.handleRightValueChange} value={item.id} checked={item.id === this.state.rightValue}/></td>
                            </tr>)
                        }
                        else {
                            return (<tr key={index} className="border-b border-gray-50">
                                <td className="py-1">{item.name}</td>                                
                                <td className="w-6 text-center"><input type="checkbox" name="left" onChange={this.handleLeftValueChange} value={item.id} checked={item.id === this.state.leftValue}/></td>
                                <td className="w-6 text-center"><input type="checkbox" name="right" onChange={this.handleRightValueChange} value={item.id} checked={item.id === this.state.rightValue}/></td>
                            </tr>)
                        }
                    })
                    }
                </tbody>
                </table>        
        );

    }

}
