import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomizedMachineModelInf } from './CustomizedMachineModelInf';
import { BaseBarcode } from '../../controls/BaseBarcode';
import { CustomerInfo } from './CustomerInfo';
import { HearingLossData } from './HearingLossData';
import { DealerInfo } from './DealerInfo';
//import './print.css';

export class OtherCustomerMadeView extends Component {   
    constructor(props) {
        super(props);                
    }    

    render() {
        let data = this.props.model;        
        if (!data) {
            return null;
        }

        return (
            <div id="pDiv" className="bg-white shadow overflow-hidden sm:rounded-sm text-sm">
                <div className="flex flex-col p-6" id="orderForm">
                    <div className="w-full overflow-hidden  mt-4">
                        <div className="grid grid-cols-3">
                            <div className="text-sm">
                                <BaseBarcode value={data.orderNum} />
                            </div>
                            <div className="text-center"><h1 className="text-4xl font-bold"><FormattedMessage id="CustomerOrderTitle" /></h1></div>
                            <div className="flex justify-between">
                                <div className="w-48">
                                    <div className="bg-blue-800 text-white px-2 font-bold"><FormattedMessage id="DeliverAccountCode" /></div>
                                    <div className="bg-gray-200 h-8 px-1 pt-1">{data.dealerInfo.deliverAccountCode}</div>
                                </div>
                                {data.brandLogoUrl && <img className="h-24" src={data.brandLogoUrl} />}
                            </div>
                        </div>

                    </div>

                    <div className="pb-6">
                        <table className="w-full">
                            <tbody>
                                <tr className="align-top">
                                    <td width="35%">
                                        <DealerInfo model={data.dealerInfo} />
                                    </td>
                                    <td width="38%">
                                        <CustomerInfo model={data.customerInfo} />
                                    </td>
                                    <td width="27%">
                                        <HearingLossData data={data.hearingLossData} specialRequirements={data.specialRequirements} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <CustomizedMachineModelInf model={data.customizedMachineModelInf} />
                </div>

            </div>
        );

    }

}
