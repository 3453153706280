import React, { Component } from 'react';
import { OrderStatus } from '../infrastructure/AppConstants';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Pager } from '../controls/Pager'
import { DeleteButton } from '../controls/DeleteButton';
import { EditButton } from '../controls/EditButton';
import { CreateOrderButton } from './CreateOrderButton';
import { BaseTabControl } from '../controls/BaseTabControl';
import { ModalActionButton } from '../controls/ModalActionButton';
import toLocalString from '../infrastructure/DateTimeFunctions';
import InfoIdDialog from './InfoIdDialog'
import { AuthorityModules, AuthorityActions } from '../api-authorization/ApiAuthorizationConstants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toast from '../controls/BaseToast'
import ExcelUtil from '../utils/ExcelUtil';
import { ConfirmAlert } from '../controls/ConfirmAlert'

export class Orders extends Component {
    static displayName = Orders.name;

    constructor(props) {
        super(props);
        this.state = { orders: [], currentPage: 0, pageCount: 0, totalCount: 0, status: OrderStatus.All, loading: true, open: false, isAllowUploadScan: false, orderNum: '', dealerName: '', brandId: '', customerName: '', productCategory: null, deliverAccountCode: '', orderStartTime: null, orderEndTime: null, products: [], confirmOpen: false, reviewId: null };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearching = this.handleSearching.bind(this);
        this.handleSearchDid = this.handleSearchDid.bind(this);
        this.handleDeleted = this.handleDeleted.bind(this);
        this.handleStatusChagned = this.handleStatusChagned.bind(this);
        this.openInfoIdDialog = this.openInfoIdDialog.bind(this);
        this.closeInfoIdDialog = this.closeInfoIdDialog.bind(this);
        this.populateListData = this.populateListData.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.onReviewClose = this.onReviewClose.bind(this);
        this.onReviewOpen = this.onReviewOpen.bind(this);
        this.handleReview = this.handleReview.bind(this);
    }

    async handleExport(e) {        
        const orderNum = this.state.orderNum;
        const dealerName = this.state.dealerName;
        //const brandName = this.state.brandName;
        const brandId = this.state.brandId;
        let brandName = "";
        if (brandId) {
            brandName = this.state.products.filter(x => x.id == brandId)[0].name;
        }   
        const customerName = this.state.customerName;
        const productCategory = this.state.productCategory;
        const deliverAccountCode = this.state.deliverAccountCode;
        const orderStartTime = this.state.orderStartTime;
        const orderEndTime = this.state.orderEndTime;

        if (brandName == "" || brandName == null) {
            Toast.warn("BrandRequired");
            return;
        }

        if (productCategory == "" || productCategory == null) {
            Toast.warn("OderTypeRequired");
            return;
        }

       
        const token = await authService.getAccessToken();
        const response = await fetch('order/ExportOrders', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
            body: JSON.stringify({
                'pageIndex': this.state.currentPage,
                'orderNum': orderNum,
                'dealerName': dealerName,
                'brandName': brandName,
                "brandId": brandId,
                'customerName': customerName,
                'productCategory': productCategory ? parseInt(productCategory) : null,
                'deliverAccountCode': deliverAccountCode,
                'orderStartTime': orderStartTime,
                'orderEndTime': orderEndTime,
                status: this.state.status
            })
        });
        const data = await response.json();
        if (data.length <= 0) {
            Toast.warn("NoEligibleData");
            return;
        }
        let time = Date.now();
        let fileName = brandName + "_" + (productCategory === "1" ? "耳膜" : "定制机") + "_" + time + ".xlsx";
        let headers;
        if (brandId == "9e9a666b2e0e475f8113a2f75c1d7010") //Workaround this should be a widex code
        {
            if (productCategory == 0) {
                headers =
                    [
                        {
                            title: '经销商',
                            dataIndex: 'dealerName',
                            key: 'dealerName',
                            className: 'text-monospace',
                        },
                        {
                            title: '品牌',
                            dataIndex: 'brandName',
                            key: 'brandName',
                        },
                        {
                            title: '订单类型',
                            dataIndex: 'productCategory',
                            key: 'productCategory',
                        },
                        {
                            title: '发货代码',
                            dataIndex: 'deliverAccountCode',
                            key: 'deliverAccountCode',
                        },
                        {
                            title: '客户姓名',
                            dataIndex: 'customerName',
                            key: 'customerName',
                        },
                        {
                            title: '当前机型品牌',
                            dataIndex: 'currentBrand',
                            key: 'currentBrand',
                        },
                        {
                            title: '当前机身型号',
                            dataIndex: 'machineBodyNumber',
                            key: 'machineBodyNumber',
                    },
                    {
                        title: '制作式样(左)',
                        dataIndex: 'lWidexMadeStyle',
                        key: 'lWidexMadeStyle',
                    },
                    {
                        title: '其他定制项(左)',
                        dataIndex: 'lWidexOtherMadeOptions',
                        key: 'lWidexOtherMadeOptions',
                    },
                    {
                        title: '通气孔类别(左)',
                        dataIndex: 'lWidexSpiracleType',
                        key: 'lWidexSpiracleType',
                    },
                    {
                        title: '耳道长度(左)',
                        dataIndex: 'lWidexAcousticMeatusSize',
                        key: 'lWidexAcousticMeatusSize',
                    },
                    {
                        title: '制作式样(右)',
                        dataIndex: 'rWidexMadeStyle',
                        key: 'rWidexMadeStyle',
                    },
                    {
                        title: '其他定制项(右)',
                        dataIndex: 'rWidexOtherMadeOptions',
                        key: 'rWidexOtherMadeOptions',
                    },
                    {
                        title: '通气孔类别(右)',
                        dataIndex: 'rWidexSpiracleType',
                        key: 'rWidexSpiracleType',
                    },
                    {
                        title: '耳道长度(右)',
                        dataIndex: 'rWidexAcousticMeatusSize',
                        key: 'rWidexAcousticMeatusSize',
                    },
                    {
                        title: '孔径大小',
                        dataIndex: 'widexSpiracleSize',
                        key: 'widexSpiracleSize',
                    }
                    ];
            }
            else {
                headers =
                    [
                        {
                            title: '经销商',
                            dataIndex: 'dealerName',
                            key: 'dealerName',
                            className: 'text-monospace',
                        },
                        {
                            title: '品牌',
                            dataIndex: 'brandName',
                            key: 'brandName',
                        },
                        {
                            title: '订单类型',
                            dataIndex: 'productCategory',
                            key: 'productCategory',
                        },
                        {
                            title: '发货代码',
                            dataIndex: 'deliverAccountCode',
                            key: 'deliverAccountCode',
                        },
                        {
                            title: '客户姓名',
                            dataIndex: 'customerName',
                            key: 'customerName',
                        },
                        {
                            title: '当前机型品牌',
                            dataIndex: 'currentBrand',
                            key: 'currentBrand',
                        },
                        {
                            title: '当前机身型号',
                            dataIndex: 'machineBodyNumber',
                            key: 'machineBodyNumber',
                    },
                    {
                        title: '常规耳背机耳模材料（左）',
                        dataIndex: 'lWidexBteEardrumMaterial',
                        key: 'lWidexBteEardrumMaterial',
                    },
                    {
                        title: '常规耳背机耳模式样（左）',
                        dataIndex: 'lWidexBteEardrumStyle',
                        key: 'lWidexBteEardrumStyle',
                    },
                    {
                        title: '细声管耳背机耳塞声管直径（左）',
                        dataIndex: 'lWidexBteEarphoneDiameter',
                        key: 'lWidexBteEarphoneDiameter',
                    },
                    {
                        title: '细声管耳背机耳塞式样（左）',
                        dataIndex: 'lWidexBteEarphoneStyle',
                        key: 'lWidexBteEarphoneStyle',
                    },
                   
                    {
                        title: '外置受话器用定制耳塞材料（左）',
                        dataIndex: 'lWidexReceiverMaterial',
                        key: 'lWidexReceiverMaterial',
                    },
                    {
                        title: '外置受话器用定制耳塞式样（左）',
                        dataIndex: 'lWidexReceiverStyle',
                        key: 'lWidexReceiverStyle',
                    },
                    {
                        title: '外置受话器用定制耳塞受话器功率（左）',
                        dataIndex: 'lWidexReceiverPower',
                        key: 'lWidexReceiverPower',
                    },
                    {
                        title: '外置受话器用定制耳塞出声孔类别（左）',
                        dataIndex: 'lWidexSoundOutletType',
                        key: 'lWidexSoundOutletType',
                    },
                    {
                        title: '通气孔类别（左）',
                        dataIndex: 'lWidexSpiracleType',
                        key: 'lWidexSpiracleType',
                    },
                   
                    {
                        title: '其他定制项（左）',
                        dataIndex: 'lWidexOtherMadeOptions',
                        key: 'lWidexOtherMadeOptions',
                    },
                    {
                        title: '常规耳背机耳模材料（右）',
                        dataIndex: 'rWidexBteEardrumMaterial',
                        key: 'rWidexBteEardrumMaterial',
                    },
                    {
                        title: '常规耳背机耳模式样（右）',
                        dataIndex: 'rWidexBteEardrumStyle',
                        key: 'rWidexBteEardrumStyle',
                    },
                    {
                        title: '细声管耳背机耳塞声管直径（右）',
                        dataIndex: 'rWidexBteEarphoneDiameter',
                        key: 'rWidexBteEarphoneDiameter',
                    },
                    {
                        title: '细声管耳背机耳塞式样（右）',
                        dataIndex: 'rWidexBteEarphoneStyle',
                        key: 'rWidexBteEarphoneStyle',
                    },

                    {
                        title: '外置受话器用定制耳塞材料（右）',
                        dataIndex: 'rWidexReceiverMaterial',
                        key: 'rWidexReceiverMaterial',
                    },
                    {
                        title: '外置受话器用定制耳塞式样（右）',
                        dataIndex: 'rWidexReceiverStyle',
                        key: 'rWidexReceiverStyle',
                    },
                    {
                        title: '外置受话器用定制耳塞受话器功率（右）',
                        dataIndex: 'rWidexReceiverPower',
                        key: 'rWidexReceiverPower',
                    },
                    {
                        title: '外置受话器用定制耳塞出声孔类别（右）',
                        dataIndex: 'rWidexSoundOutletType',
                        key: 'rWidexSoundOutletType',
                    },
                    {
                        title: '通气孔类别（右）',
                        dataIndex: 'rWidexSpiracleType',
                        key: 'rWidexSpiracleType',
                    },

                    {
                        title: '其他定制项（右）',
                        dataIndex: 'rWidexOtherMadeOptions',
                        key: 'rWidexOtherMadeOptions',
                    },
                   
                    {
                        title: '孔径选择',
                        dataIndex: 'widexSpiracleSize',
                        key: 'widexSpiracleSize',
                    },
                    {
                        title: '外置受话器用定制耳塞耳钩线长度',
                        dataIndex: 'widexEarHookSize',
                        key: 'widexEarHookSize',
                    },
                    {
                        title: '细声管耳背机耳塞声管长度',
                        dataIndex: 'widexBteEarphoneSize',
                        key: 'widexBteEarphoneSize',
                    }
                    ];
            }
        }
        else {

            if (productCategory == 0) {
                headers =
                    [
                        {
                            title: '经销商',
                            dataIndex: 'dealerName',
                            key: 'dealerName',
                            className: 'text-monospace',
                        },
                        {
                            title: '品牌',
                            dataIndex: 'brandName',
                            key: 'brandName',
                        },
                        {
                            title: '订单类型',
                            dataIndex: 'productCategory',
                            key: 'productCategory',
                        },
                        {
                            title: '发货代码',
                            dataIndex: 'deliverAccountCode',
                            key: 'deliverAccountCode',
                        },
                        {
                            title: '客户姓名',
                            dataIndex: 'customerName',
                            key: 'customerName',
                        },
                        {
                            title: '是否老客户',
                            dataIndex: 'isRegularCustomer',
                            key: 'isRegularCustomer',
                        },
                        {
                            title: '配戴助听器型号',
                            dataIndex: 'currentBrand',
                            key: 'currentBrand',
                        },
                        {
                            title: '上次耳模编号',
                            dataIndex: 'machineBodyNumber',
                            key: 'machineBodyNumber',
                        },
                        {
                            title: '左耳外壳',
                            dataIndex: 'lEnclosureType',
                            key: 'lEnclosureType',
                        },
                        {
                            title: '是否有线(左)',
                            dataIndex: 'lWireless',
                            key: 'lWireless',
                        },
                        {
                            title: '线路(左)',
                            dataIndex: 'lLine',
                            key: 'lLine',
                        },
                        {
                            title: '规格(左)',
                            dataIndex: 'lSpecMatrix',
                            key: 'lSpecMatrix',
                    },
                    {
                        title: 'Product Code(左)',
                        dataIndex: 'lProductCode',
                        key: 'lProductCode',
                    },
                    {
                        title: 'RIC Code(左)',
                        dataIndex: 'lRicCode',
                        key: 'lRicCode',
                    },
                        {
                            title: '右耳外壳',
                            dataIndex: 'rEnclosureType',
                            key: 'rEnclosureType',
                        },
                        {
                            title: '是否有线(右)',
                            dataIndex: 'rWireless',
                            key: 'rWireless',
                        },
                        {
                            title: '线路(右)',
                            dataIndex: 'rLine',
                            key: 'rLine',
                        },
                        {
                            title: '规格(右)',
                            dataIndex: 'rSpecMatrix',
                            key: 'rSpecMatrix',
                    },
                    {
                        title: 'Product Code(右)',
                        dataIndex: 'rProductCode',
                        key: 'rProductCode',
                    },
                    {
                        title: 'RIC Code(右)',
                        dataIndex: 'rRicCode',
                        key: 'rRicCode',
                    }
                    ];
            }
            else {
                headers =
                    [
                        {
                            title: '经销商',
                            dataIndex: 'dealerName',
                            key: 'dealerName',
                            className: 'text-monospace',
                        },
                        {
                            title: '品牌',
                            dataIndex: 'brandName',
                            key: 'brandName',
                        },
                        {
                            title: '订单类型',
                            dataIndex: 'productCategory',
                            key: 'productCategory',
                        },
                        {
                            title: '发货代码',
                            dataIndex: 'deliverAccountCode',
                            key: 'deliverAccountCode',
                        },
                        {
                            title: '客户姓名',
                            dataIndex: 'customerName',
                            key: 'customerName',
                        },
                        {
                            title: '是否老客户',
                            dataIndex: 'isRegularCustomer',
                            key: 'isRegularCustomer',
                        },
                        {
                            title: '当前机型品牌',
                            dataIndex: 'currentBrand',
                            key: 'currentBrand',
                        },
                        {
                            title: '当前机身型号',
                            dataIndex: 'machineBodyNumber',
                            key: 'machineBodyNumber',
                        },
                        {
                            title: '左耳耳膜选项',
                            dataIndex: 'lEarmoldOption',
                            key: 'lEarmoldOption',
                        },
                        {
                            title: '样式(左)',
                            dataIndex: 'lEarmoldType',
                            key: 'lEarmoldType',
                        },
                        {
                            title: '材质(左)',
                            dataIndex: 'lEarmoldMaterial',
                            key: 'lEarmoldMaterial',
                        },
                        {
                            title: '颜色(左)',
                            dataIndex: 'lEarmoldColor',
                            key: 'lEarmoldColor',
                        },
                        {
                            title: 'BTE通气孔(左)',
                            dataIndex: 'lEardrumBteVent',
                            key: 'lEardrumBteVent',
                        },
                        {
                            title: 'BTE细声管3.0直径(左)',
                            dataIndex: 'lEarmoldBteTubeDiameter',
                            key: 'lEarmoldBteTubeDiameter',
                        },
                        {
                            title: 'BTE细声管3.0长度(左)',
                            dataIndex: 'lEarmoldBteTubeLength',
                            key: 'lEarmoldBteTubeLength',
                        },
                        {
                            title: 'BTE耳模3.0长度(左)',
                            dataIndex: 'lEarmoldBteLength',
                            key: 'lEarmoldBteLength',
                    },
                    {
                        title: 'RIC受话器(左)',
                        dataIndex: 'lEarmoldRicReceiverVersion',
                        key: 'lEarmoldRicReceiverVersion',
                    },
                    {
                        title: 'RIC通气孔(左)',
                        dataIndex: 'lEardrumRicVent',
                        key: 'lEardrumRicVent',
                    },
                    {
                        title: 'RIC导线长度(左)',
                        dataIndex: 'lEardrumRicCordLength',
                        key: 'lEardrumRicCordLength',
                    },
                    {
                        title: 'RIC拉线(左)',
                        dataIndex: 'lEardrumRicCord',
                        key: 'lEardrumRicCord',
                    }
                    ,
                    {
                        title: 'RIC受话器增益',
                        dataIndex: 'lEardrumRicReceiver',
                        key: 'lEardrumRicReceiver',
                    },
                        {
                            title: '右耳耳膜选项',
                            dataIndex: 'rEarmoldOption',
                            key: 'rEarmoldOption',
                        },
                        {
                            title: '样式(右)',
                            dataIndex: 'rEarmoldType',
                            key: 'rEarmoldType',
                        },
                        {
                            title: '材质(右)',
                            dataIndex: 'rEarmoldMaterial',
                            key: 'rEarmoldMaterial',
                        },
                        {
                            title: '颜色(右)',
                            dataIndex: 'rEarmoldColor',
                            key: 'rEarmoldColor',
                        },
                        {
                            title: 'BTE通气孔(右)',
                            dataIndex: 'rEardrumBteVent',
                            key: 'rEardrumBteVent',
                       },
                        {
                            title: 'BTE细声管3.0直径(右)',
                            dataIndex: 'rEarmoldBteTubeDiameter',
                            key: 'rEarmoldBteTubeDiameter',
                        },
                        {
                            title: 'BTE细声管3.0长度(右)',
                            dataIndex: 'rEarmoldBteTubeLength',
                            key: 'rEarmoldBteTubeLength',
                        },
                        {
                            title: 'BTE耳模3.0长度(右)',
                            dataIndex: 'rEarmoldBteLength',
                            key: 'rEarmoldBteLength',
                    },
                    {
                        title: 'RIC受话器(右)',
                        dataIndex: 'rEarmoldRicReceiverVersion',
                        key: 'rEarmoldRicReceiverVersion',
                    },
                    {
                        title: 'RIC通气孔(右)',
                        dataIndex: 'rEardrumRicVent',
                        key: 'rEardrumRicVent',
                    },
                    {
                        title: 'RIC导线长度(右)',
                        dataIndex: 'rEardrumRicCordLength',
                        key: 'rEardrumRicCordLength',
                    },
                    {
                        title: 'RIC拉线(右)',
                        dataIndex: 'rEardrumRicCord',
                        key: 'rEardrumRicCord',
                    }
                    ,
                    {
                        title: 'RIC受话器增益(右)',
                        dataIndex: 'rEardrumRicReceiver',
                        key: 'rEardrumRicReceiver',
                    }
                    ];
            }
        }

        ExcelUtil.ExportExcel(headers, data, fileName);

    }
    onReviewClose() {
        this.setState({ confirmOpen: false, reviewId: null });
    }

    onReviewOpen(id, e) {
        e.preventDefault();
        this.setState({ confirmOpen: true, reviewId: id });
    }

    async handleReview(e) {
        var id = this.state.reviewId;
        const token = await authService.getAccessToken();
        const response = await fetch('order/AuditPass', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
            body: JSON.stringify({
                'id': id,
            })
        });
        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    this.onReviewClose();
                    this.populateListData();
                }
                else {
                    Toast.warn(data.message);
                }
            }
        }
        else {
            console.error('response.status:' + response.status)
        }
      
    }
    async componentDidMount() {
        this.populateListData();
        const isAllow = await authService.isAllow(AuthorityModules.Order, AuthorityActions.ConfirmOrder);
        this.setState({
            isAllowUploadScan: isAllow
        });
        await this.getProducts();
    }

    async handleStatusChagned(item) {
        //  console.log("Selected status" + item);
        var index = OrderStatus[item];
        this.setState({ status: index });
        this.setState({ currentPage: 0 });
        await this.populateListData();
    }

    async handleDeleted(id) {
        if (id) {
            this.setState(
                {
                    orders: this.state.orders.filter((rec) => {
                        return (rec.id != id);
                    })
                });
        }
    }

    handleSearchDid(data) {
        this.setState({ orders: data.entities, pageCount: data.pageCount, totalCount: data.totalCount, loading: false });
    }

    handleSearching() {
        this.setState({ loading: true });
    }

    async handleSearch() {
        this.handleSearching();
        this.setState({ currentPage: 0 });
        await this.populateListData();

    }
    async getProducts() {
        const token = await authService.getAccessToken();
        const response = await fetch('product/GetProducts', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ products: data });
    }
    async handlePageChange(index) {
        this.setState({ currentPage: index });
        await this.populateListData();
    }
    openInfoIdDialog(id, e) {
        e.preventDefault();
        this.setState({ open: true });
        const order = this.state.orders.filter((rec) => {
            return (rec.id == id);
        })[0]
        this.infoIdDialog.updateState(order);

    }

    onChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    onOrderStartTimeChange = (item, e) => {
        this.setState({ orderStartTime: item });
    }

    onOrderEndTimeChange = (item, e) => {
        this.setState({ orderEndTime: item });
    }

    closeInfoIdDialog() {
        this.setState({ open: false });
    }


    renderStatus(orderStatus) {
        if (this.state.status == OrderStatus.All) {
            var display = '';
            switch (orderStatus) {
                case OrderStatus.Draft:
                    display = 'Draft';
                    return <td className="px-3 py-2"><span className="px-2 py-1 font-semibold leading-tight text-yellow-700 bg-yellow-100 rounded-full dark:bg-yellow-700 dark:text-yellow-100"> <FormattedMessage id={display} /></span></td>
                case OrderStatus.Submitted:
                    display = 'Submitted';
                    return <td className="px-3 py-2"><span className="px-2 py-1 font-semibold leading-tight text-blue-700 bg-blue-100 rounded-full dark:bg-blue-700 dark:text-blue-100"> <FormattedMessage id={display} /></span></td>
                case OrderStatus.Reviewed:
                    display = 'Reviewed';
                    return <td className="px-3 py-2"><span className="px-2 py-1 font-semibold leading-tight text-indigo-700 bg-indigo-100 rounded-full dark:bg-indigo-700 dark:text-indigo-100"> <FormattedMessage id={display} /></span></td>
                case OrderStatus.Retreat:
                    display = 'Retreat';
                    return <td className="px-3 py-2"><span className="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:bg-red-700 dark:text-red-100"> <FormattedMessage id={display} /></span></td>
                case OrderStatus.Finish:
                    display = 'Finish';
                    return <td className="px-3 py-2"><span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"> <FormattedMessage id={display} /></span></td>

            }

            return <td className="px-4 py-3"><FormattedMessage id={display} /></td>
        }

        return ('');
    }

    rendActions(a) {
        switch (a.status) {
            case OrderStatus.Draft:
                return (<div className="flex flex-row">

                    <EditButton module={AuthorityModules.Order} action={AuthorityActions.Edit} url={'/order/EditOrder/' + a.id} />
                    |<DeleteButton module={AuthorityModules.Order} action={AuthorityActions.Delete} url="order/delete/" id={a.id} onDeleteExecuted={this.handleDeleted} />
                </div>);
            case OrderStatus.Submitted:
                return (<div className="flex flex-row">
                    <EditButton displayname="View" module={AuthorityModules.Order} action={AuthorityActions.Edit} url={'/order/EditOrder/' + a.id} />
                    {(this.state.isAllowUploadScan && (<span>|<a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(e) => this.onReviewOpen(a.id, e)}><FormattedMessage id="ConfirmOrder" /></a></span>))}
                </div>);
            case OrderStatus.Reviewed:
                return (<div className="flex flex-row">
                    <EditButton displayname="View" module={AuthorityModules.Order} action={AuthorityActions.Edit} url={'/order/EditOrder/' + a.id} />
                    {(this.state.isAllowUploadScan && (<span>|<a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(e) => this.openInfoIdDialog(a.id, e)}><FormattedMessage id="FinishOrder" /></a></span>))}
                </div>);
            case OrderStatus.Retreat:
                return (<div className="flex flex-row">
                    <EditButton module={AuthorityModules.Order} action={AuthorityActions.View} url={'/order/EditOrder/' + a.id} />|
                    <DeleteButton module={AuthorityModules.Order} action={AuthorityActions.Delete} url="order/delete/" id={a.id} onDeleteExecuted={this.handleDeleted} />
                </div>);
            case OrderStatus.Finish:
                return (<div className="flex flex-row">
                    <EditButton displayname="View" module={AuthorityModules.Order} action={AuthorityActions.View} url={'/order/EditOrder/' + a.id} />
                </div>);
        }

        return (<div className="flex flex-row">
            <EditButton module={AuthorityModules.Order} action={AuthorityActions.Edit} url={'/order/EditOrder/' + a.id} />|
            <DeleteButton module={AuthorityModules.Order} action={AuthorityActions.Delete} url="order/delete/" id={a.id} onDeleteExecuted={this.handleDeleted} />
        </div>);
    }

    renderForecastsTable(orders, handleEdit, handleDeleted) {
        return (
            <div className="w-full overflow-x-auto">
                <table className='w-full text-xs'>
                    <thead>
                        <tr className="font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th className="px-4 py-3"><FormattedMessage id="OrderNum" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="InfoOrderNum" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="OrderTime" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="DealerName" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="Brand" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="DeliverAccountCode" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="ProductCategory" /></th>
                            <th className="px-4 py-3"><FormattedMessage id="CustomerName" /></th>
                            {this.state.status == OrderStatus.All ? <th className="px-4 py-3"><FormattedMessage id="Status" /></th> : ''}
                            <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        {orders.map(a =>
                            <tr key={a.id} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400">
                                <td className="px-3 py-1">{a.orderNum}</td>
                                <td className="px-3 py-1">{a.infoId}</td>
                                <td className="px-3 py-1">{a.orderTime ? toLocalString(a.orderTime) : ""}</td>
                                <td className="px-4 py-1">{a.dealerName}</td>
                                <td className="px-3 py-1">{a.brandName}</td>
                                <td className="px-3 py-1">{a.deliverAccountCode}</td>
                                <td className="px-3 py-1"><FormattedMessage id={a.productCategory == 1 ? "Eardrum" : "CustomMade"} /></td>
                                <td className="px-3 py-1">{a.customerName}</td>
                                {this.renderStatus(a.status)}
                                <td className="px-3 py-1">
                                    {this.rendActions(a)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForecastsTable(this.state.orders, this.handleEdit, this.handleDeleted);
        return (
            <div className="">
                <div className="w-full overflow-hidden rounded-lg shadow-xs mb-2 text-sm">
                    <div className="flex">
                        <div className="grid grid-cols-4">
                            <div className="mx-2 flex justify-between gap-x-1">
                                <label className="place-self-center"><FormattedMessage id="OrderNum" /></label>
                                <input type="text" onChange={this.onChange} name="orderNum" className="py-1 px-1 rounded-md bg-white shadow-sm sm:text-sm dark:bg-gray-800 border-2 border-gray-400 dark:border-gray-700 focus:border-indigo-500 focus:outline-none" />
                            </div>
                            <div className="mx-2 flex justify-between gap-x-1">
                                <label className="place-self-center"><FormattedMessage id="DealerName" /></label>
                                <input type="text" onChange={this.onChange} name="dealerName" className="py-1 px-1 rounded-md bg-white shadow-sm sm:text-sm dark:bg-gray-800 border-2 border-gray-400 dark:border-gray-700 focus:border-indigo-500 focus:outline-none" />
                            </div>
                            <div className="mx-2 flex justify-between gap-x-1">
                                <label className="place-self-center"><FormattedMessage id="DeliverAccountCode" /></label>
                                <input type="text" onChange={this.onChange} name="deliverAccountCode" className="py-1 px-1 rounded-md bg-white shadow-sm sm:text-sm dark:bg-gray-800 border-2 border-gray-400 dark:border-gray-700 focus:border-indigo-500 focus:outline-none" />
                            </div>
                            <div className="mx-2 flex justify-between gap-x-1">   <label className="mr-3"><FormattedMessage id="CustomerName" /></label>
                                <input type="text" onChange={this.onChange} name="customerName" className="py-1 px-1 rounded-md bg-white shadow-sm sm:text-sm dark:bg-gray-800 border-2 border-gray-400 dark:border-gray-700 focus:border-indigo-500 focus:outline-none" />
                            </div>
                            <div className="mx-2  my-1 flex justify-between gap-x-1">
                                <label className="place-self-center"><FormattedMessage id="OrderStartTime" /></label>
                                <DatePicker name="orderStartTime" className="w-168 py-1 px-1 rounded-md bg-white shadow-sm sm:text-sm dark:bg-gray-800 border-2 border-gray-400 dark:border-gray-700 focus:border-indigo-500 focus:outline-none"
                                    //  onSelect={handleDateSelect} //when day is clicked
                                    onChange={this.onOrderStartTimeChange} //only when value has changed
                                    selected={this.state.orderStartTime}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>

                            <div className="mx-2  my-1 flex justify-between gap-x-1">
                                <label className="place-self-center"><FormattedMessage id="OrderEndTime" /></label>
                                <DatePicker name="orderEndTime" className="w-168 py-1 px-1 rounded-md bg-white shadow-sm sm:text-sm dark:bg-gray-800 border-2 border-gray-400 dark:border-gray-700 focus:border-indigo-500 focus:outline-none"
                                    //  onSelect={handleDateSelect} //when day is clicked
                                    onChange={this.onOrderEndTimeChange} //only when value has changed
                                    selected={this.state.orderEndTime}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div className="mx-2  my-1 flex justify-between gap-x-1">
                                <label className="place-self-center"><FormattedMessage id="Brand" /></label>
                                <select name="brandId" onChange={this.onChange} className="py-1 px-1 w-30 rounded-md bg-white shadow-sm sm:text-sm dark:bg-gray-800 border-2 border-gray-400 dark:border-gray-700 focus:border-indigo-500 focus:outline-none" style={{ width: 168, height: 34 }}>
                                    <FormattedMessage id="PickOne">
                                        {value => <option value="">{value}</option>}
                                    </FormattedMessage>
                                    {this.state.products.map((item, index) => {
                                        return (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        )
                                    })}

                                </select>
                            </div>
                            <div className="mx-2 my-1 flex justify-between gap-x-1">
                                <label className="place-self-center"><FormattedMessage id="ProductCategory" /></label>
                                <select name="productCategory" onChange={this.onChange} className="py-1 px-1 w-30 rounded-md bg-white shadow-sm sm:text-sm dark:bg-gray-800 border-2 border-gray-400 dark:border-gray-700 focus:border-indigo-500 focus:outline-none" style={{ width: 168, height: 34 }}>
                                    <FormattedMessage id="PickOne">
                                        {value => <option value="">{value}</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="CustomMade">
                                        {value => <option value="0">{value}</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="Eardrum">
                                        {value => <option value="1">{value}</option>}
                                    </FormattedMessage>
                                </select>
                            </div>

                        </div>

                        <div className="">
                            <div className="m-2 flex">
                                <button className="hover:bg-blue-500 hover:text-blue-800 group flex items-center rounded-md bg-blue-600 text-white text-sm font-medium px-4 py-2 mr-2" onClick={this.handleSearch}><FormattedMessage id="Search" /></button>                                
                                <ModalActionButton className="border border-blue-400 rounded-sm bg-blue-300" displayName="Export" desc="ExportingData"
                                    actionAsnc={this.handleExport}/>
                                <CreateOrderButton module={AuthorityModules.Order} action={AuthorityActions.Create} history={this.props.history} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex justify-auto items-center h-14 header-right">
                        <SearchButton url="order/GetOrders"
                            placeholder="OrderSearchPlaceholder"
                            onSearching={this.handleSearching}
                            onSearchDid={this.handleSearchDid} />
                        <ul className="flex items-center">
                            <li>
                                {<CreateOrderButton module={AuthorityModules.Order} action={AuthorityActions.Create} history={this.props.history} /> } 
                            </li>
                        </ul>
                    </div>*/}
                </div>
                <BaseTabControl items={["Draft", "Submitted", "Retreat", "Reviewed", "Finish", "All"]} defaultItem={"All"} onTabItemChanged={this.handleStatusChagned} />
                <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    {contents}
                    <Pager currentPage={this.state.currentPage} pageCount={this.state.pageCount} totalCount={this.state.totalCount} onPageChange={this.handlePageChange} />
                </div>
                <InfoIdDialog onRef={(ref) => { this.infoIdDialog = ref }} open={this.state.open} onClose={this.closeInfoIdDialog} populateListData={this.populateListData} />
                <ConfirmAlert onRef={(ref) => { this.confirmRef = ref }} onCancel={this.onReviewClose} onOk={(e) => { this.handleReview(e) }} open={this.state.confirmOpen} messageId="AuditPass" />
            </div>
        );
    }

    async populateListData() {
        const token = await authService.getAccessToken();
        const orderNum = this.state.orderNum;
        const dealerName = this.state.dealerName;
      //  const brandName = this.state.brandName;
        const brandId = this.state.brandId;
        let brandName = "";
        if (brandId) {
            brandName = this.state.products.filter(x => x.id == brandId)[0].name;
        }   
        const customerName = this.state.customerName;
        const productCategory = this.state.productCategory;
        const deliverAccountCode = this.state.deliverAccountCode;
        const orderStartTime = this.state.orderStartTime;
        const orderEndTime = this.state.orderEndTime;
        const response = await fetch('order/GetOrders', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
            body: JSON.stringify({
                'pageIndex': this.state.currentPage,
                'orderNum': orderNum,
                'dealerName': dealerName,
                'brandName': brandName,
                "brandId": brandId,
                'customerName': customerName,
                'productCategory': productCategory ? parseInt(productCategory) : null,
                'deliverAccountCode': deliverAccountCode,
                'orderStartTime': orderStartTime,
                'orderEndTime': orderEndTime,
                status: this.state.status
            })
        });
        const data = await response.json();
        this.setState({ orders: data.entities, pageCount: data.pageCount, totalCount: data.totalCount, loading: false });
    }
}


