import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class BasePasswordBox extends Component {    
       
    render() {
        var props = this.props;
        var className = "shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5";
        if (props.classnames) {
            className += ' ' + props.classnames;
        }
        if (props.errmessage) {
            className += ' ' + 'error-message'
            return (
                <FormattedMessage id={props.errmessage} >
                    {placeholder =>
                        <input {...props} type="password" placeholder={placeholder} className={className} />
                    }
                </FormattedMessage>
            );
        }
        return (
            <input {...props} type="password" className={className} />
        );
    }    
}
