import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormattedMessage } from "react-intl";
import React from 'react';

let Toast = {
    success: (msg) => {
        toast.success(({ closeToast }) => <div><FormattedMessage id={msg} /></div>, { position: "top-center", autoClose:3000 });
    },
    warn: (msg) => {
        toast.warn(({ closeToast }) => <div><FormattedMessage id={msg} /></div>, { position: "top-center", autoClose: 3000});
    },
    error: (msg) => {
        toast.error(({ closeToast }) => <div><FormattedMessage id={msg} /></div>, { position: "top-center", autoClose: 3000});
    },
    info: (msg) => {
        toast.info(({ closeToast }) => <div><FormattedMessage id={msg} /></div>, { position: "top-center", autoClose: 3000});
    }
}
export default Toast;
