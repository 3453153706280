import ExcelJS from 'exceljs';
import saveAs from "file-saver";
import XLSX from 'xlsx';

function ImportExcel(file) {
    return new Promise((resolve, reject) => {        
        const { files } = file.target;        
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;               
                const workbook = XLSX.read(result, { type: 'binary' });
                let data = [];           
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // sheet_to_json 
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; //just read first sheet
                    }
                }
                resolve(data);
            } catch (e) {
                
                reject(e);                
            }
        };
        
        fileReader.readAsBinaryString(files[0]);
    });  
}
var stringFromCharCode = function (i) {
    var n = parseInt(i + 65)
    if (n > 90) {
        n = String.fromCharCode(65) + String.fromCharCode(i + 39);
        return n;
    } else {
        n = String.fromCharCode(n);
        return n;
    }
}

function ExportExcel(headers, data, fileName = 'test.xlsx', colsFormat) {
   if(!colsFormat){
       colsFormat=[{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 300 }]
   }

   const _headers = headers
       .map((item, i) => Object.assign({}, { key: item.key, title: item.title, position: stringFromCharCode(i) + 1 }))
       .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { key: next.key, v: next.title } }), {});

   const _data = data
       .map((item, i) => headers.map((key, j) => Object.assign({}, { content: item[key.key], position: stringFromCharCode(j) + (i + 2) })))       
       .reduce((prev, next) => prev.concat(next))      
       .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { v: next.content } }), {});

   // merge headers 和 data
   const output = Object.assign({}, _headers, _data);   
   const outputPos = Object.keys(output);
   // ["A1",..., "H2"]
   const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;
  
   const wb = {
       SheetNames: ['mySheet'],
       Sheets: {
           mySheet: Object.assign(
               {},
               output,
               {
                   '!ref': ref,
                   '!cols': colsFormat,
               },
           ),
       },
   };

   // export Excel
   XLSX.writeFile(wb, fileName);
}

function ExportExcelWithStyle(headers, data, fileName = 'ExportFile.xlsx') {
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
    var columnsData = headers.map((column) => {
        const width = column.width;
        return {
            header: column.title,
            key: column.dataIndex,
            //width: isNaN(width) ? 20 : width / 10,           
        };
    });
    worksheet.columns = columnsData;
    worksheet.addRows(data);
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        if (rowNumber === 1) {
            row.eachCell(function (cell, colNumber) {
                cell.style.font = { name: 'Microsoft YaHei', bold: true, horizontal: 'center' };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.style.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        }
        else {
            row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                cell.style.font = { name: 'Microsoft YaHei' };
                cell.style.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        }
    });

    ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName
        );
    });
};
export default { ImportExcel, ExportExcel, ExportExcelWithStyle };