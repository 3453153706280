import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import authService from '../api-authorization/AuthorizeService'
import { PaperClipIcon } from '@heroicons/react/solid'
import ExcelUtil from '../utils/ExcelUtil';
import { Loading } from '../controls/Loading';
import Toast from '../controls/BaseToast'


export class ImportButton extends Component {
    constructor(props) {
        super(props);
        this.state = { isAllow: false, options: [], url: this.props.url, callback: this.props.callback };
        this.handleImport = this.handleImport.bind(this);
    }
    async componentDidMount() {
        if (this.props.module && this.props.action) {
            const isAllow = await authService.isAllow(this.props.module, this.props.action);
            this.setState({
                isAllow: isAllow
            });
        }
    }
    handleImport(e) {
        this.setState({ options: [] });
        this.loadingRef.showLoading();
        const closeLoading = this.loadingRef.closeLoading;
        const callback = this.state.callback;
        const url = this.state.url;
        ExcelUtil.ImportExcel(e).then(function (data) {
            console.log(data);
            if (data.length > 0) {
                data.map((item) => {
                    for (var obj in item) {
                        console.log(item[obj]);
                        if (typeof item[obj] == "number") {
                            item[obj] = item[obj].toString();
                        }
                    }
                  
                });
                authService.getAccessToken().then(function (token) {
                    fetch(url, {
                        method: 'POST',
                        headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                        body: JSON.stringify(data)
                    }).then(function (response) {
                        closeLoading();
                        if (response.status == 200) {
                             response.json().then(function (data) {
                                console.log(data);
                                if (data) {
                                    if (data.status == 0) {
                                        callback&&callback();
                                    }
                                    else {
                                          Toast.warn(data.message);
                                    }
                                }
                            });
                        }
                        else {
                            console.error('response.status:' + response.status)
                        }
                    });
                });
            }
        }, function (err) {
            closeLoading();
            console.warn(err);
        });
    }
    render() {
        return this.state.isAllow && (<div className="inline-block">
            <button {...this.props} className="py-2 px-4 mx-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600" style={{ display: 'inline' }}><FormattedMessage id="import" />
                <input type="file" style={{ fontSize: 0 }} className="absolute p-4 -mt-2 -ml-10 opacity-0" accept=".xls,.xlsx" onChange={this.handleImport} />
            </button>
            <a href={this.props.template} className="font-medium text-indigo-600 hover:text-indigo-500 text-sm ">
                <FormattedMessage id="DownloadTempalte" />
            </a>
            <Loading onRef={(ref) => { this.loadingRef = ref }} />
        </div>
        )
    }
}
