import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Pager } from '../controls/Pager'
import { DeliverAccountCodeDialog } from './DeliverAccountCodeDialog';
import { DealerProductManageDialog } from './DealerProductManageDialog';
import { ConfirmAlert } from '../controls/ConfirmAlert'
import ExcelUtil from '../utils/ExcelUtil';
import { BackButton } from '../controls/BackButton';


export class DeliverAccountCode extends Component {
    static displayName = DeliverAccountCode.name;

    constructor(props) {
        super(props);
        this.handleOnCreate = this.handleOnCreate.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleOnDeleted = this.handleOnDeleted.bind(this);
        this.populateListData = this.populateListData.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.deletedConfirm = this.deletedConfirm.bind(this);
        this.handleOnProductManage = this.handleOnProductManage.bind(this);
        this.onProductManageClose = this.onProductManageClose.bind(this);
        this.handleOnExport = this.handleOnExport.bind(this);
        this.state = { dealerId: this.props.match.params["id"], dealerName: "", currentPage: 0, pageCount: 0, totalCount: 0, deliverAccountCodes: [], saleAccountCodes: [], open: false, confirmOpen: false, deleteId: null, productManageOpen: false };
    }
    componentDidMount() {
        var id = this.props.match.params["id"];
        if (id) {
            this.loadData();
            this.populateListData();
        }
    }
    async loadData() {
        const token = await authService.getAccessToken();
        await fetch('dealer/GetDealer/' + this.state.dealerId, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({ dealerName: data.name, saleAccountCodes: data.salesAccountCodeModels });
            });
    }
    async populateListData() {
        const token = await authService.getAccessToken();
        const response = await fetch('dealer/GetDeliverAccountCodesWithDealerId?page=' + this.state.currentPage + '&dealerId=' + this.state.dealerId, {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ deliverAccountCodes: data.entities, pageCount: data.pageCount, totalCount: data.totalCount });
    }


    onBack(e) {
        e.preventDefault();
        window.history.back(-1);
    }
    handleOnCreate(e) {
        this.setState({ open: true });
    }
    handleOnEdit(id, e) {
        e.preventDefault();
        const deliverAccountCode = this.state.deliverAccountCodes.filter(x => x.id == id)[0];
        deliverAccountCode.dealerId = this.state.dealerId;
        this.dialogForm.bindDeliverAccountCode(deliverAccountCode);
        this.setState({ open: true });
    }
    onClose(e) {
        this.setState({ open: false });
    }
    handlePageChange(index) {
        this.setState({ currentPage: index });
        this.populateListData();
    }
    onConfirmClose() {
        this.setState({ confirmOpen: false, deleteId: null });
    }
    onProductManageClose() {
        this.setState({ productManageOpen: false });
    }
    deletedConfirm(id, e) {
        e.preventDefault();
        this.setState({ confirmOpen: true, deleteId: id });
    }
    handleOnProductManage() {
        this.setState({ productManageOpen: true });
        this.dealerProductManageForm.productData();
    }
    handleOnExport() {
        const initColumn = this.excelColumn();
        const excelName = "发货代码.xlsx";
        const data = this.state.deliverAccountCodes.map((item, index) => { return { num: index + 1, code: item.code, brandName: item.product.name, address: item.address, salesAccountCodes: item.salesAccountCodes.map(x => { return x.code }).join(',') } });
        ExcelUtil.ExportExcelWithStyle(initColumn, data, excelName)
    }
    excelColumn() {
        return [
            {
                title: '序号',
                dataIndex: 'num',
                key: 'num',
                className: 'text-monospace',
            },
            {
                title: '发货账户代码',
                dataIndex: 'code',
                key: 'code',
                className: 'text-monospace',
            },
            {
                title: '品牌',
                dataIndex: 'brandName',
                key: 'brandName',
                className: 'text-monospace',
            },
            {
                title: '地址',
                dataIndex: 'address',
                key: 'address',
                className: 'text-monospace',
            },
            {
                title: '销售账户代码',
                dataIndex: 'salesAccountCodes',
                key: 'salesAccountCodes',
                className: 'text-monospace',
            }]
    }

    async handleOnDeleted(e) {
        e.preventDefault();
        var id = this.state.deleteId;
        if (!id) { return; }
        this.setState({
            deliverAccountCodes: this.state.deliverAccountCodes.filter((rec) => {
                return (rec.id != id);
            })
        });
        const token = await authService.getAccessToken();
        await fetch('dealer/DeleteDeliverAccountCode/' + id, {
            method: 'DELETE',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` }
        }).then(data => {
        });
        this.setState({ confirmOpen: false, deleteId: null });
    }

    render() {
        return (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900"><FormattedMessage id="DeliverAccountCodeManagerment" /></h3>
                </div>

                <div className="border-t border-gray-200">
                    <dl>
                        <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="col-span-3 sm:col-span-2">
                                    <label><FormattedMessage id="DealerName" /></label>：
                                            <span>{this.state.dealerName}</span>
                                </div>
                                <div className="col-span-3 sm:col-span-2 mt-4 mb-2 float-right">
                                    <button className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4" onClick={this.handleOnCreate}><FormattedMessage id="Create" /></button>
                                    <button className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4" onClick={this.handleOnProductManage}><FormattedMessage id="ProductManage" /></button>
                                    <button className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4" onClick={this.handleOnExport}><FormattedMessage id="export" /></button>
                                    <BackButton />
                                </div>
                            </div>
                        </div>
                    </dl>
                </div>
                <div className="w-full overflow-x-auto">
                    <table className='w-full'>
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                <th className="px-4 py-3"><FormattedMessage id="DeliverAccountCode" /></th>
                                <th className="px-4 py-3"><FormattedMessage id="products" /></th>
                                <th className="px-4 py-3"><FormattedMessage id="Address" /></th>
                                <th className="px-4 py-3"><FormattedMessage id="SaleAccountCode" /></th>
                                <th className="px-4 py-3"><FormattedMessage id="Actions" /></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                            {

                                this.state.deliverAccountCodes.map((item) => {
                                    return (
                                        <tr key={item.id} className="bg-gra-50 hover:bg-gray-100 text-gray-700 dark:text-gray-400">
                                            <td className="px-4 py-3 text-sm">{item.code}</td>
                                            <td className="px-4 py-3 text-sm">{(item.product ? item.product.name : "")}</td>
                                            <td className="px-4 py-3 text-sm">{item.address}</td>
                                            <td className="px-4 py-3 text-sm">{
                                                item.salesAccountCodes.map((childItem) => {
                                                    return (
                                                        childItem.code
                                                    )
                                                }).join(',')
                                            }</td>
                                            <td className="px-4 py-3 text-sm">
                                                <a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={(e) => this.handleOnEdit(item.id, e)}><FormattedMessage id="edit" /></a>|
                            <a className="text-indigo-600 hover:text-indigo-900" href="#" onClick={(e) => this.deletedConfirm(item.id, e)}><FormattedMessage id="delete" /></a>
                                            </td>
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </table>
                    <Pager currentPage={this.state.currentPage} pageCount={this.state.pageCount} totalCount={this.state.totalCount} onPageChange={this.handlePageChange} />
                </div>

                <DeliverAccountCodeDialog onRef={(ref) => { this.dialogForm = ref }} populateListData={this.populateListData} open={this.state.open} onClose={this.onClose} saleAccountCodes={this.state.saleAccountCodes} dealerId={this.state.dealerId}>
                </DeliverAccountCodeDialog>
                <DealerProductManageDialog dealerId={this.state.dealerId} onRef={(ref) => { this.dealerProductManageForm = ref }} populateListData={this.populateListData} open={this.state.productManageOpen} onClose={this.onProductManageClose}>
                </DealerProductManageDialog>
                <ConfirmAlert onRef={(ref) => { this.confirmRef = ref }} onCancel={this.onConfirmClose} onOk={(e) => { this.handleOnDeleted(e) }} open={this.state.confirmOpen} />
            </div>
        );
    }
}