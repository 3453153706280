import { map } from 'jquery';
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';

export class BaseTabControl extends Component {    
    constructor(props) {
        super(props);
        this.state = { selectedItem: "" };
        this.handleTabClick = this.handleTabClick.bind(this);
    }

    handleTabClick(e) {
        e.preventDefault();
        var item = e.target.name;
        this.setState({ selectedItem: item});
        var handler = this.props.onTabItemChanged;
        if (handler) {
            handler(item);
        }
    }
   
    render() {        
        var items = this.props.items;
        var selectedItem = this.state.selectedItem;
        if (!selectedItem) {
            selectedItem = this.props.defaultItem;
        }
       
        return (                   
            <div className="bg-white rounded-t-md" >
                <nav className="flex flex-col sm:flex-row">
                    {
                        items.map(item => {
                            if (item === selectedItem) {
                                return <button key={item} name={item} className="text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 border-b-2 font-normal text-sm border-purple-500"><FormattedMessage id={item} /></button>
                            }
                            else {
                                return <button key={item} name={item} onClick={this.handleTabClick} className="text-gray-600 py-4 px-6 block hover:text-purple-500 text-sm focus:outline-none"><FormattedMessage id={item}/></button>
                            }
                        })
                    }             
                </nav>
            </div >
        );
    }    
}

