import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { BaseTextBox } from '../controls/BaseTextBox';
import { BaseCombobox } from '../controls/BaseCombobox';
import { BackButton } from '../controls/BackButton';
import { BasePasswordBox } from '../controls/BasePasswordBox';
import Toast from '../controls/BaseToast';
import validator from 'validator';
import { ModalView } from '../common/ModalView';

export class ModalTableTag extends ModalView {    
    constructor(props) {
        super(props);
        this.state = { open: false, name: '', selectedRoleId: '', description: '', roles: [], loadingRoles: true, errMessage: '' };
        this.handleBaseTextBoxChange = this.handleBaseTextBoxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTableTagGroupChange = this.handleTableTagGroupChange.bind(this);
        this.handleRoleSelectChange = this.handleRoleSelectChange.bind(this);
    }

    async loadRoles() {
        const token = await authService.getAccessToken();
        const response = await fetch('role/GetRoles', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        var selectRoleId = '';
        if (data.length > 1) {
            selectRoleId = data[0].id;
        }
        this.setState({ roles: data, selectedRoleId: selectRoleId, loadingRoles: false });

        var id = this.props.id;
        if (id) {
            this.locaData(id);
        }
    }

    componentDidMount() {
        this.loadRoles();
    }

    async locaData(id) {
        const token = await authService.getAccessToken();
        fetch('tableTag/GetTableTag/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    name: data.name, description: data.description
                });
            });
    }


    handleTableTagGroupChange(event) {
        event.preventDefault();
        const target = event.target;
        const value = target.value;

        this.setState({
            accountGroup: value
        });
    }
    handleRoleSelectChange(event) {
        event.preventDefault();
        const target = event.target;
        const value = target.value;

        this.setState({
            selectedRoleId: value
        });
    }
    handleBaseTextBoxChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const name = this.state.name.trim();
        const description = this.state.description.trim();
        

        //  send request to the server
        const token = await authService.getAccessToken();
        const isNew = this.props.isNew;
        let response = null;
        if (name.length == 0) {
            this.setState({ errMessage: 'Required' });            
        }

        if (isNew) {
           
            response = await fetch('TableTag/Create', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'name': name,
                    'description': description,
                   
                })
            });


        }
        else {
            response = await fetch('TableTag/Edit', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'name': name,
                    'id': this.props.id,
                    'description': description,
                    
                })
            });
        }

        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    super.setOpen(false);
                }
                else {
                    Toast.warn(data.message);                    
                }
            }
        }
        else {
            console.error('response.status:' + response.status)           
        }
    }

   

    renderContent() {
        
        const isNew = this.props.isNew;
                
        return (
            <div className="">
                <dl>
                    <form onSubmit={this.handleSubmit}>
                        <div className="overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Name" /></label>
                                        <BaseTextBox classnames="mt-1" type="text" name="name" placeholder="" value={this.state.name} onChange={this.handleBaseTextBoxChange} errmessage={this.state.errMessage} />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="roleSelect" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Role" /></label>
                                        <BaseCombobox classnames="mt-1" name="roleSelect" id="roleSelect" onChangeCapture={this.handleRoleSelectChange} value={this.state.selectedRoleId}>
                                            {this.state.roles.map((item, index) => (<option key={index} value={item.id}>{item.name}</option>)
                                            )}

                                        </BaseCombobox>

                                    </div>
                                                                                                           
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="description" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Description" /></label>
                                        <BaseTextBox classnames="mt-1" type="text" name="description" placeholder="" value={this.state.description} onChange={this.handleBaseTextBoxChange} />
                                    </div>

                                </div>
                            </div>                           
                        </div>
                    </form>
                </dl>
            </div>
        );
    }
}
