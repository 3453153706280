import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import Toast from '../controls/BaseToast'
import { FormattedMessage } from 'react-intl';
import { BackButton } from '../controls/BackButton';

export class RoleActions extends Component {
    static displayName = RoleActions.name;

    constructor(props) {
        super(props);
        this.state = { model: null, loading: true };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        var id = this.props.match.params["id"];
        if (id) {
            this.locaData(id);
        }
    }

    async locaData(id) {
        const token = await authService.getAccessToken();
        fetch('role/GetRoleActions/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({ model: data, loading: false });
            });
    }

    handleCheckboxChange(event) {
        const target = event.target;
        const checked = target.checked;
        const id = target.id;
        if (target.className.startsWith("opacity-50")) {
            return;
        }

        var model = this.state.model;
        for (var m in model.modules) {
            let actions = model.modules[m].actions;
            let stop = 0;
            if (model.modules[m].list.id == id) {
                model.modules[m].list.selected = checked;
                if (!checked) {
                    for (var i in actions) {                       
                        actions[i].selected = false;                                                   
                    }
                }
                stop = 1;                
            }
            else {
                for (var i in actions) {
                    if (actions[i].id == id) {
                        actions[i].selected = checked;
                        stop = 1;
                        break;
                    }
                }
            }
            
            if (stop > 0) {

                break;
            }
        }

        
        this.setState({
            model: model
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        var selectedlist = new Array();
        var model = this.state.model;
        for (var m in model.modules) {
            let actions = model.modules[m].actions;

            if (model.modules[m].list.selected) {
                selectedlist.push(model.modules[m].list.id);     
                for (var i in actions) {
                    if (actions[i].selected) {
                        selectedlist.push(actions[i].id);
                    }
                }    
            }                       
        }

        //  send request to the server
        const token = await authService.getAccessToken();
        var id = this.props.match.params["id"];
        const response = await fetch('role/EditActions', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
            body: JSON.stringify({
                'id': id,
                'actions': selectedlist
            })
        });

        if (response.status == 200) {
            let data = await response.json();           
            if (data == 0) {
                window.history.back(-1);
            }
            else {
                Toast.warn(data.message);               
            }            
        }
        else {
            console.error('role actions response.status:' + response.status)
        }
    }

    groupBy = (list, fn) => {
        const groups = {};
        list.forEach(function (o) {
            const group = JSON.stringify(fn(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return groups;
    }


    GetActions(model, handleCheckboxChange) {
        var modules = model.modules;       
        return (
            <div className="grid grid-flow-row auto-rows-max">
                {modules.map(function (m) {
                    return (<div key={m.module} className="rounded bg-gray-300 p-3 m-2">                        
                        <div className="flex justify-center pb-2">
                            <div className="place-self-center inline-flex items-center">
                                <span className="mr-2 text-sm font-semibold"><FormattedMessage id={m.module} /></span>
                                <input type="checkbox" id={m.list.id} checked={m.list.selected} onChange={handleCheckboxChange} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />                                
                            </div>
                        </div>        

                        <div className="flex flex-wrap gap-10 bg-white p-4">
                            {m.actions.map(a =>
                                <div className="flex items-start" key={a.id}>
                                    <div className="flex items-center h-5">
                                        <input type="checkbox" id={a.id} checked={a.selected} onChange={handleCheckboxChange} className={m.list.selected ? "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" : "opacity-50 cursor-not-allowed focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" }/>
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label className="font-medium text-gray-700"><FormattedMessage id={a.action}/></label>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>);
                }

                )}
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.GetActions(this.state.model, this.handleCheckboxChange);

        return (
            <div className="max-w-4xl mx-auto py-6 sm:px-3 lg:px-4">
                <div className="md:grid md:grid-cols-1 md:gap-2">
                    <div className="md:col-span-1">
                        <div className="px-2 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900 ml-2"><FormattedMessage id="RoleAuthority"/></h3>
                        </div>
                    </div>
                    {contents}
                    <div className="w-full text-right px-2">
                        <button onClick={this.handleSubmit} className="mx-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <FormattedMessage id="Submit" />
                        </button>
                        <BackButton/>
                    </div>
                </div>
            </div>            
        );
    }
}
