import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import productService from './ProductService'
import { FormattedMessage } from 'react-intl';
import { BaseTextBox } from '../controls/BaseTextBox';
import { BaseUploadFile } from '../controls/BaseUploadFile';
import { BackButton } from '../controls/BackButton';
import Toast from '../controls/BaseToast'
import { BaseTextArea } from '../controls/BaseTextArea';
import { CheckIcon, BanIcon } from '@heroicons/react/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export class ProductForm extends Component {
    static displayName = ProductForm.name;

    constructor(props) {
        super(props);
        this.state = { name: "", description: "", id: this.props.id, errMessage: '', categories:[], loading: true };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCategoryCheck = this.handleCategoryCheck.bind(this);
    }

    handleCategoryCheck(e) {
        let target = e.target;
        let id = target.id;
        let items = this.state.categories;
        for (let i = 0; i < items.length; i++) {
            if (id === items[i].id) {
                items[i].checked = !items[i].checked;
                break;
            }
        }
        this.setState({ categories: items });
    }

    componentDidMount() {        
        var id = this.props.id;
        if (id) {
            this.locaData(id);
        }
        else {
            this.loadCategories();
        }
    }

    async loadCategories() {
        let allCategories = await productService.getCategories(false);
        let categories = [];
        for (let i = 0; i < allCategories.length; i++) {
            categories.push({ id: allCategories[i].id, name: allCategories[i].name, checked: false });
        }
        this.setState({ categories: categories, loading: false });
    }

    async locaData(id) {
        let allCategories = await productService.getCategories(false);
        const token = await authService.getAccessToken();
        fetch('product/get/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                let selCategoryIds = data.categoryIds;
                let categories = [];
                for (let i = 0; i < allCategories.length; i++) {
                    if (selCategoryIds.length > 0 && selCategoryIds.indexOf(allCategories[i].id) >= 0) {
                        categories.push({ id: allCategories[i].id, name: allCategories[i].name, checked:true });
                    }
                    else {
                        categories.push({ id: allCategories[i].id, name: allCategories[i].name, checked: false  });
                    }
                }
                
                this.setState({ name: data.name, description: data.description, categories: categories, loading: false  });
                this.uploadFileDialog.updateState(data.logoUrl);
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    async handleSubmit(e) {
        e.preventDefault();
        const name = this.state.name.trim();
        const logoFile = this.uploadFileDialog.state.file;
        const description = this.state.description;
        if (!name) {
            this.setState({ errMessage: 'Required' });
            return;
        }
        const token = await authService.getAccessToken();
        const isNew = this.props.isNew;
        const formData = new FormData();
        formData.append("FormFile", logoFile);
        formData.append("Name", name);
        formData.append("Description", description??"");
        formData.append("Id", this.state.id ?? null);
        var categoryIds = new Array();
        var categories = this.state.categories;
        for (var i = 0; i < categories.length; i++) {
            if (categories[i].checked) {
                categoryIds.push(categories[i].id);
            }            
        }
        formData.append("CategoryIds", categoryIds);

        let response = null;
        if (isNew) {
             response = await fetch('product/Create', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `${token}` },
                body: formData
            });
        }
        else {
             response = await fetch('product/Edit', {
                method: 'POST',
                 headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                 body: JSON.stringify({
                   'name': name,
                   'FormFile': logoFile,
                   'Description': description ?? "",
                   'categoryIds': categoryIds,                   
                   'id': this.state.id ?? "",
                   
               })
            });
        }

        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    window.history.back(-1);
                }
                else {
                    Toast.warn(data.message);
                    //this.setState({ errMessage: data.message });
                }
            }
        }
        else {
            console.error('response.status:' + response.status)
        }
    }

    render() {
        if (this.state.loading) {
            return <p><em>Loading...</em></p>;
        }
        return (
            <div className="mt-5 md:mt-0 md:col-span-1">
                <form onSubmit={this.handleSubmit} encType="multipart/form-data">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                            <div className="grid grid-cols-4 gap-6">
                                <div className="col-span-6 sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Name" /></label>
                                    <BaseTextBox classnames="w-full mt-2"  type="text" name="name" value={this.state.name} onChange={this.handleInputChange} errmessage={this.state.errMessage}/>
                                </div>
                                <div className="col-span-6 sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Description" /></label>
                                    <BaseTextArea  name="description" value={this.state.description} onChange={this.handleInputChange} />
                                </div>
                                <div className="col-span-6 sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Logo" /></label>

                                    <div className="mt-2">
                                        <BaseUploadFile accept="image/*"  onRef={(ref) => { this.uploadFileDialog = ref }}/>
                                    </div>

                                </div>
                                <div className="col-span-6 sm:col-span-4">
                                    <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Category" /></label>
                                    <div className="flex flex-wrap gap-2">
                                        {this.state.categories.map(c =>
                                            <div onClick={this.handleCategoryCheck} id={c.id} key={c.id} className={c.checked ? "mt-3 w-full inline-flex justify-center rounded-md border border-indigo-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                : "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"}>
                                                <label>{c.name}</label>
                                                <span className="pointer-events-none flex items-center pl-3">
                                                    {
                                                        c.checked ? <CheckIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" /> :
                                                        <BanIcon className="h-5 w-5 text-red-500 group-hover:text-red-400" aria-hidden="true" />}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <FormattedMessage id="Submit">
                                {
                                    value => <input type="submit" value={value} className="inline-flex mr-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" />
                                }
                            </FormattedMessage>
                            <BackButton />
                        </div>



                    </div>
                </form>
            </div>
        );
    }
}