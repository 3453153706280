import React, { Component, Fragment } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react';
import { BaseUploadFile } from '../controls/BaseUploadFile';
import { Loading } from '../controls/Loading';
import orderService from './OrderService';
import { OrderCommitAction } from './OrderService';
import Toast from '../controls/BaseToast'

export default class ScanFileDialog extends Component {
    constructor(props) {
        super(props);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.state = { orderId: this.props.id, urls: this.props.urls, open: false, files: null }
        this.syncFiles = this.syncFiles.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }
    close() {
        this.setState({ open: false })
    }
    open() {
        this.setState({open:true})
    }
    syncFiles(files) {
        this.setState({ files: files });
    }
    async handleOnSubmit(e) {
        e.preventDefault();
        const orderId = this.state.orderId;
        const scanFiles = this.uploadFileDialog.state.files;
        if (!scanFiles || scanFiles.length == 0) { return; }

        this.loadingRef.showLoading();
        const formData = new FormData();
        scanFiles.map(item => {
            formData.append("FormFiles", item);
        });
        formData.append("orderId", orderId);
        const token = await authService.getAccessToken();
        const response = await fetch('order/UploadScanFile', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `${token}` },
            body: formData
        });
        
        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    await orderService.commit(OrderCommitAction.Submit, scanFiles);
                    window.history.back(-1);
                }
                else {
                    Toast.warn(data.message);
                }
            }
        }
        else {
            console.error('response.status:' + response.status)
        }
        this.loadingRef.closeLoading();
        this.close();

    }
    render() {
        const open = this.state.open;
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    open={open}
                    onClose={this.close}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <form onSubmit={this.handleOnSubmit} encType="multipart/form-data" style={{ width: '100vw' }}>
                                <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="">
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900"><FormattedMessage id="UploadScan" /></Dialog.Title>
                                                <div className="mt-2">
                                                    <label className="inline-block w-18 text-right mr-2"><FormattedMessage id="ScanFile" /></label>
                                                    <div className="mt-2">
                                                        <BaseUploadFile multiple="multiple" accept="*" onRef={(ref) => { this.uploadFileDialog = ref }} syncFiles={this.syncFiles} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={this.close}><FormattedMessage id="cancel" /></button>
                                        <button type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm" onClick={this.handleOnSubmit}><FormattedMessage id="Submit" /></button>

                                    </div>
                                </div>
                            </form>

                        </Transition.Child>
                    </div>
                    <Loading onRef={(ref) => { this.loadingRef = ref }} />
                </Dialog>

            </Transition.Root >
        )
    }
}