import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


export class BackButton extends Component {
    constructor(props) {
        super(props);
        this.handleOnBack = this.handleOnBack.bind(this);
    }
    handleOnBack(e) {
        e.preventDefault();
        window.history.back(-1);
    }
    render() {
        return (
            <button {...this.props} className="inline-block mx-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={(e) => { this.handleOnBack(e) }}><FormattedMessage id="Back" /></button>
        )
    }
}
