export const ApplicationName = 'OrderSystem';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LoginActions = {
  Login: 'login',
  LoginFailed: 'login-failed', 
};

const prefix = '/authentication';

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,  
    
};

export const AuthorityModules = {
    Account : 1,
    Role:2,
    Dealer:3,
    Product:4,
    ProductOptions:5,
    Order: 6,
    Store: 7
}

export const AuthorityActions = {
    List : 1,
    Create:2,
    Delete:3,
    Edit: 4,
    View: 5,
    Export: 6,
    SaleAccountCode:7,
    DeliverAccountCode:8,
    ExportDeliverAccountCode: 9,
    ConfirmOrder: 10,
    Retreat: 11,
    RoleActions:12
}
