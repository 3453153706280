import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { BaseTextBox } from '../controls/BaseTextBox';
import { BackButton } from '../controls/BackButton';
import Toast from '../controls/BaseToast'

export class RoleForm extends Component {
    static displayName = RoleForm.name;

  constructor(props) {
      super(props);
      this.state = { name: '', description: '', errMessage: '' };
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      var id = this.props.id;
      if (id) {
          this.locaData(id);
      }
    }

    async locaData(id) {
        const token = await authService.getAccessToken();
        fetch('role/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({ name: data.name, description: data.description });
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const name = this.state.name.trim();
        const description = this.state.description.trim();
        if (!name) {
            this.setState({ errMessage: 'Required' });
            return;
        }
        //  send request to the server
        const token = await authService.getAccessToken();
        const isNew = this.props.isNew;
        let response = null;
        if (isNew) {
                response = await fetch('role/Create', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'name': name,
                    'description': description
                })
            });
            
        }
        else {
                response = await fetch('role/Edit', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'id': this.props.id,
                    'name': name,
                    'description': description
                })
            });
        }
               
        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    window.history.back(-1);
                }
                else {
                    Toast.warn(data.message);
                  //  this.setState({ errMessage: data.message });
                }
            }
        }
        else {
            console.error('response.status:' + response.status)
        }
    }    

  render() {    
      return (
          <div className="mt-5 md:mt-0 md:col-span-1">
              <form onSubmit={this.handleSubmit}>            
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                          <div className="grid grid-cols-4 gap-6">
                              <div className="col-span-6 sm:col-span-4">
                                  <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Name" /></label>
                                  <BaseTextBox classnames="w-full mt-2"  type="text" name="name" value={this.state.name} onChange={this.handleInputChange} errmessage={this.state.errMessage} />
                               </div>
                              <div className="col-span-6 sm:col-span-4">     
                                  <label className="block text-sm font-medium text-gray-700"><FormattedMessage id="Description" /></label>
                                  <BaseTextBox classnames="w-full mt-2"  type="text" name="description" value={this.state.description} onChange={this.handleInputChange}/>
                               </div>
                  
                      </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <FormattedMessage id="Submit">
                              {
                                  value => <input type="submit" value={ value} className="inline-flex mr-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" />
                              }
                          </FormattedMessage>
                          <BackButton />
                      </div>
                  </div>
              </form>
          </div>         
      
    );
  }  
}
