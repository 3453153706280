import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
export class EardrumCheckBoxGroup extends Component {   
    constructor(props) {
        super(props);
        
        let leftValue= "-1";
        let rightValue = "-1";
        let value = this.props.value;
        if (value) {
            var vals = value.split(',');
            if (vals.length == 2) {
                leftValue = vals[0];
                rightValue = vals[1];
            }
        }
        this.state = { items: props.items, leftValue: leftValue, rightValue: rightValue};
        this.handleLeftValueChange = this.handleLeftValueChange.bind(this);
        this.handleRightValueChange = this.handleRightValueChange.bind(this);
    }

    handleLeftValueChange(e) {
        let checked = e.target.checked;
        let value = e.target.value;
        let leftValue = value;
        if (!checked) {
            leftValue = '-1';
        }
        
        this.setState({ leftValue: leftValue }, () => {
            var handle = this.props.onSelectValueChanged;
            if (handle) {
                var e = this.state.leftValue + ',' + this.state.rightValue;
                handle(e);
            }
            });
            
        
    }

    handleRightValueChange(e) {
        let checked = e.target.checked;
        let value = e.target.value;
        let rightValue = value;
        if (!checked) {
            rightValue = '-1';
        }

        this.setState({ rightValue: rightValue }, () => {
            var handle = this.props.onSelectValueChanged;
            if (handle) {
                var e = this.state.leftValue + ',' + this.state.rightValue;
                handle(e);
            }
        });

    }    

    render() {
        const title = this.props.title;
        if (!this.state.items) {
            return null;
        }

        let header = this.props.header;
        return (
            <table className={header === 'RIC' ? "text-sm w-full border-b-2 border-blue-200" : "text-sm w-full  border-b-2 border-purple-200"}>
                <tbody>
                <tr>
                    <td colSpan="3" className={header === 'RIC' ? "bg-blue-50 border-b-2 border-gray-200" :"bg-purple-50  border-b-2 border-gray-200"}>
                            <span className="font-bold mr-1">{header}<FormattedMessage id={title} /></span>
                    |<span className="ml-1"><FormattedMessage id={this.props.desc} /></span>
                    </td>                       
                    </tr>
                    <tr className="text-center">                        
                    <td className="border border-gray-100 px-1"><FormattedMessage id="TypeHeader"/></td>
                    <td className="border border-gray-100">
                        <div className="flex items-center justify-center">
                                <span className="mx-auto"><FormattedMessage id="Left" /></span>
                                <span className="mx-auto text-white inline-block bg-blue-500 rounded-full  h-4 w-4 lg:h-4 lg:w-4 flex items-center justify-center">L</span>
                        </div>
                    </td>
                    <td className="border border-gray-100">
                        <div className="flex items-center justify-center">
                                <span className="mx-auto"><FormattedMessage id="Right" /></span>
                                <span className="mx-auto text-white inline-block bg-red-500 rounded-full  h-4 w-4 lg:h-4 lg:w-4 flex items-center justify-center">R</span>
                        </div>
                    </td>
                </tr>
                    {
                        this.state.items.map((item, index) =>
                            <tr className="text-center" key={ index}>
                                <td className="border border-gray-100 px-1">{item.name}</td>
                                <td className="border border-gray-100 text-center">
                                    <input type="checkbox" name="left" onChange={this.handleLeftValueChange} value={item.id} checked={item.id === this.state.leftValue} />
                                </td>
                                <td className="border border-gray-200 text-center">
                                    <input type="checkbox" name="right" onChange={this.handleRightValueChange} value={item.id} checked={item.id === this.state.rightValue} />
                                </td>
                        </tr>              
                    )
                    }            
                    </tbody>
                </table>
        );

    }

}
