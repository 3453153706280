import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { BaseTextBox } from '../controls/BaseTextBox';
import { BaseCombobox } from '../controls/BaseCombobox';
import { BackButton } from '../controls/BackButton';
import { BasePasswordBox } from '../controls/BasePasswordBox';
import Toast from '../controls/BaseToast';
import validator from 'validator';
import { ModalView } from '../common/ModalView';
import { BaseTextArea } from '../controls/BaseTextArea';

export class StoreForm extends ModalView {
    static displayName = StoreForm.name;

    constructor(props) {
        super(props);
        this.state = { open: false, name: '', selectedDelearId: '', phone: '', address: '', roles: [], loadingRoles: true, errMessage: '', passwordErrMessage: '', confirmPasswordErrMessage: '' };
        this.handleBaseTextBoxChange = this.handleBaseTextBoxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStoreGroupChange = this.handleStoreGroupChange.bind(this);
        this.handleRoleSelectChange = this.handleRoleSelectChange.bind(this);
    }

    async loadRoles() {
        const token = await authService.getAccessToken();
        const response = await fetch('role/GetRoles', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        var selectRoleId = '';
        if (data.length > 1) {
            selectRoleId = data[0].id;
        }
        this.setState({ roles: data, selectedDelearId: selectRoleId, loadingRoles: false });

        var id = this.props.id;
        if (id) {
            this.locaData(id);
        }
    }

    componentDidMount() {
        //this.loadRoles();TODO load dealers when admin create a store
    }

    async locaData(id) {
        const token = await authService.getAccessToken();
        fetch('Store/getStore/' + id, { headers: !token ? {} : { 'Authorization': `${token}` } })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    name: data.name, selectedDelearId: data.dealer?.id, phone: data.phone, address: data.address
                });
            });
    }


    handleStoreGroupChange(event) {
        event.preventDefault();
        const target = event.target;
        const value = target.value;

        this.setState({
            StoreGroup: value
        });
    }
    handleRoleSelectChange(event) {
        event.preventDefault();
        const target = event.target;
        const value = target.value;

        this.setState({
            selectedRoleId: value
        });
    }
    handleBaseTextBoxChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const name = this.state.name.trim();        
        const dealerId = this.state.selectedDelearId;        
        const phone = this.state.phone.trim();
        const address = this.state.address.trim();
        

        //  send request to the server
        const token = await authService.getAccessToken();
        const isNew = this.props.isNew;
        let response = null;
        if (name.length == 0) {
            this.setState({ errMessage: 'Required' });
        }

        if (isNew) {
                       
            response = await fetch('Store/Create', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'id': '',
                    'name': name,                    
                    'dealerId': dealerId,                    
                    'phone': phone,
                    'address': address,
                    'description':''
                })
            });


        }
        else {
            response = await fetch('Store/Edit', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
                body: JSON.stringify({
                    'name': name,
                    'id': this.props.id,                    
                    'dealerId': dealerId,
                    'phone': phone,
                    'address': address,
                })
            });
        }

        if (response.status == 200) {
            let data = await response.json();
            if (data) {
                if (data.status == 0) {
                    super.setOpen(false);
                }
                else {
                    Toast.warn(data.message);
                }
            }
        }
        else {
            console.error('response.status:' + response.status)
        }
    }
       
    renderContent() {
        
        const isNew = this.props.isNew;
               
        return (

            <form onSubmit={this.handleSubmit}>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Name" /></label>
                        <BaseTextBox classnames="mt-1" type="text" name="name" id="name" placeholder="" value={this.state.name} onChange={this.handleBaseTextBoxChange} errmessage={this.state.errMessage} />
                    </div>
                    {/*<div className="col-span-6 sm:col-span-3">*/}
                    {/*    <label htmlFor="roleSelect" className="block text-sm font-medium text-gray-700"><FormattedMessage id="Role" /></label>*/}
                    {/*    <BaseCombobox classnames="mt-1" name="roleSelect" id="roleSelect" onChangeCapture={this.handleRoleSelectChange} value={this.state.selectedDelearId}>*/}
                    {/*        {this.state.roles.map((item, index) => (<option key={index} value={item.id}>{item.name}</option>)*/}
                    {/*        )}*/}

                    {/*    </BaseCombobox>*/}

                    {/*</div>TODO show dealers when admin create a store*/}
                                                           
                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700"><FormattedMessage id="cellphone" /></label>
                        <BaseTextBox classnames="mt-1" type="text" name="phone" id="phone" placeholder="" value={this.state.phone} onChange={this.handleBaseTextBoxChange} />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="address" className="block text-sm font-medium text-gray-700"><FormattedMessage id="address" /></label>
                        <BaseTextArea classnames="mt-1" name="address" id="address" placeholder="" value={this.state.address} onChange={this.handleBaseTextBoxChange} />
                    </div>

                </div>
            </form>

        );
    }
}
