import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class BaseUploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = { file: null, files: [], url: this.props.url ?? ""}
        this.onFileChange = this.onFileChange.bind(this);
        this.updateState = this.updateState.bind(this);
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    onFileChange = event => {
        let files = this.state.files;
        if (this.props.multiple == "multiple") {
            let temp = files;
            if (files.length == 0) {
                Array.from(event.target.files).map(x => {
                    temp.push(x);
                });
            } else {
                Array.from(event.target.files).map(file => {
                    let isExists = false;
                    files.map(item => {
                        if (item.name == file.name) {
                            isExists = true;
                        }
                    });
                    if (!isExists) {
                        temp.push(file);
                    }
                });
            }
            this.setState({ files: temp });
            this.props.syncFiles && this.props.syncFiles(temp);
        } else {
            Array.from(event.target.files).map(x => {
                files.push(x);
            });
            this.setState({ file: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) });
        }
    };
    updateState(url) {
        this.setState({ url: url });
    }
    onDeleteFile(name,e) {
        e.preventDefault();
        this.setState({ files: this.state.files.filter(x => x.name != name)});
    }
    onDeleteExistsFile(name, e) {
        e.preventDefault();
        var urls = this.state.urls.filter(x => x != name);
        this.setState({ urls: urls });
    }
    render() {
        return (
            <div>
                <ul className="mb-2 max-h-20 overflow-auto">
                  {/*  {this.state.urls.filter(x=>x != "").map((item, index) => {
                        
                        return (<li key={index}>{ index+1}.{item.substring(item.lastIndexOf('/')+1)}<a href="#" onClick={(e) => { this.onDeleteExistsFile(item, e) }} className="ml-2 text-red-600">X</a></li>)
                    })
                    }*/}
                    {this.state.files.map((item, index) => {
                        return (<li key={index}>{index + 1}.{item.name}<a href="#" onClick={(e) => { this.onDeleteFile(item.name, e) }} className="ml-2 text-red-600">X</a></li>)
                    })
                    }
                </ul>
                <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">

                    <div className="absolute left-1 top-0">
                        <div className="h-36 w-36 flex justify-center items-center overflow-hidden ">
                            {
                                this.props.multiple == "multiple" ?
                                    ""
                                    : <img src={this.state.url} alt="" className="h-20" />}
                        </div>
                    </div>
                    <div className="absolute">
                        <div className="flex flex-col items-center "> <i className="fa fa-cloud-upload fa-3x text-gray-200"></i> <span className="block text-gray-400 font-normal"><FormattedMessage id="AttachYouFilesHere" /></span> <span className="block text-gray-400 font-normal"><FormattedMessage id="Or" /></span> <span className="block text-blue-400 font-normal"><FormattedMessage id="BrowseFiles" /></span> </div>
                    </div>
                    <input {...this.props} type="file" style={{ fontSize: 0 }} onChange={this.onFileChange} className="h-full w-full opacity-0" />
                </div>
            </div>
        )

    }
}
