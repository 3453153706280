import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import authService from '../../api-authorization/AuthorizeService';
import { OrderTextBox } from '../OrderTextBox'
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import orderService from '../OrderService';
import { SalesCodeComboBox } from './SalesCodeComboBox';

export class DealerInfo extends Component {
    static displayName = DealerInfo.name;
    constructor(props) {
        super(props);
        const { id, dealerName, deliverAccountCode, saleAccountCode, addressee, zipcode, phone, address } = props.model;
        this.state = { dealerId: id, dealerName: dealerName, deliverCodes: [], salesCodes: [], deliverAccountCode: deliverAccountCode, saleAccountCode: saleAccountCode, addressee: addressee, zipcode: zipcode, phone: phone, address: address, uploading: false };
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnHover = this.handleOnHover.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);         
        this.handleSelectChanged = this.handleSelectChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSalesCodeChanged = this.handleSalesCodeChanged.bind(this);
       
    }

    handleSalesCodeChanged(e) {
        this.setState({ saleAccountCode:e });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleSelectChanged(e) {
        var handler = this.onSelectChanged;
        if (handler) {
            handler(e);
        }
    }

    componentDidMount() {       
        this._subscription = orderService.subscribe(() => this.orderCommitting());
        this.populateDeliverCodeData();
    }

    orderCommitting() {
        orderService.updateDealerInfo(this.state);
    }

    componentWillUnmount() {
        orderService.unsubscribe(this._subscription);
    }

    handleOnSearch(string, results) {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    handleOnHover(result) {
        // the item hovered
        console.log(result)
    }

    handleOnSelect(item) {
        // the item selected
        var deliverCodes = this.state.deliverCodes;

        deliverCodes.forEach((deliverCode) => {
            if (deliverCode.code === item.code) {
                var selSalesCode = '';
                var salesCodes = deliverCode.salesAccountCodes;
                if (salesCodes.length > 0) {
                    selSalesCode = salesCodes[0]
                }
                this.setState({ salesCodes: salesCodes, saleAccountCode: selSalesCode, deliverAccountCode: deliverCode.code, addressee: deliverCode.addressee, zipcode: deliverCode.zipcode, phone: deliverCode.phone, address: deliverCode.address });
            }
        });
        console.log(item)
    }

    handleOnFocus() {
        console.log('Focused')
    }

    async populateDeliverCodeData() {
        const token = await authService.getAccessToken();
        const response = await fetch('dealer/GetDeliverAccountCodes/' + this.state.dealerId, {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        var dealerId = data.dealerId;
        var dealerName = data.dealerName;
        var deliverCodes = data.deliverAccountCodes;
               
        var length = deliverCodes.length;

        var selectedDeliverCode = this.state.deliverAccountCode;
        var selectedSalesCode = this.state.saleAccountCode;

        var salesCodes = [];

        if (selectedDeliverCode == '') {

            if (length >= 1) {

                selectedDeliverCode = deliverCodes[0].summary;

                if (selectedSalesCode == '' && deliverCodes[0].codes.length > 0) {

                    salesCodes = deliverCodes[0].codes;
                    selectedSalesCode = deliverCodes[0].codes[0];
                }
            }
        }
        else {
            deliverCodes.forEach((deliverCode) => {
                if (deliverCode.code === selectedDeliverCode) {                    
                    salesCodes = deliverCode.salesAccountCodes;
                    if (salesCodes.length > 0) {                        
                        if (selectedSalesCode === '') {
                            selectedSalesCode = salesCodes[0]
                        }
                    }                   
                }
            });
        }

        this.setState({ deliverCodes: deliverCodes, salesCodes: salesCodes, saleAccountCode: selectedSalesCode, dealerName: dealerName, loading: false });
    }

    renderDeliverCodes() {
        var deliverAccountCode = this.state.deliverAccountCode
        if (deliverAccountCode == null)
        {
            deliverAccountCode ='';
        }
        return (<ReactSearchAutocomplete
            items={this.state.deliverCodes}
            onSearch={this.handleOnSearch}
            onHover={this.handleOnHover}
            onSelect={this.handleOnSelect}
            onFocus={this.handleOnFocus}
            styling={{ borderRadius: "5px", height: "30px", margin: "5px", fontSize:"12px" }}
            fuseOptions={{ keys: ["code"] }}
            resultStringKeyName="code"
            inputSearchString={deliverAccountCode}
            showIcon={false}
            autoFocus
        />);
    }
      
    render() {
        let deliverContents = this.state.loading
            ? <p><em>loadingDeliverCode...</em></p>
            : this.renderDeliverCodes();
       
        return (
            <div className="font-bold">
                <div className="border-2 border-gray-100" >
                    <div className="flex flex-row border-b-2 border-gray-100 pl-1">
                        <label className="place-self-center"><FormattedMessage id="DealerName" /></label>
                        <OrderTextBox classnames="col-span-5 bg-gray-100 flex-auto" name="dealerName" readOnly="readonly" value={this.state.dealerName} />
                    </div>
                    <div className="grid grid-cols-2 pl-1">
                        <div className="flex flex-row">
                            <label className="place-self-center"><FormattedMessage id="DeliverAccountCode" /></label>
                            <div className="flex-auto mx-2" > {deliverContents}</div>
                        </div>     
                        <div className="flex flex-row">
                            <label className="place-self-center"><FormattedMessage id="SaleAccountCode" /></label>
                            <SalesCodeComboBox classnames="flex-auto my-1 mx-2" name="saleAccountCode" items={this.state.salesCodes} selectedValue={this.state.saleAccountCode} OnSelectValueChanged={this.handleSalesCodeChanged }/>
                        </div>                       
                    </div>                    
                </div>
                <div className="border-2 border-gray-100 mt-2" >
                    <div className="flex justify-between px-1">                    
                        <label className="flex-none place-self-center"><FormattedMessage id="Addressee" /></label>
                        <OrderTextBox classnames="flex-grow mx-1 w-24" name="addressee" value={this.state.addressee??""} onChange={this.handleInputChange} />                                                
                        <label className="flex-none place-self-center"><FormattedMessage id="Zipcode" /></label>
                        <OrderTextBox classnames="flex-grow mx-1 w-16" name="zipcode" value={this.state.zipcode??""} onChange={this.handleInputChange} />                        
                        <label className="flex-none place-self-center"><FormattedMessage id="Phone" /></label>
                        <OrderTextBox classnames="flex-grow mx-0 w-24" name="phone" value={this.state.phone??""} onChange={this.handleInputChange} />                                         
                    </div>
                    <div className="flex flex-col">
                        <label><FormattedMessage id="Address" /></label>
                        <textarea className="bg-gray-100 m-1 h-12" value={this.state.address??""} name="address" onChange={this.handleInputChange} />
                    </div>
                </div>
            </div>
           
    );
  }
}