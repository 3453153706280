import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { OtherOptions } from './OtherOptions';
import authService from '../../api-authorization/AuthorizeService'
import orderService from '../OrderService';
import { ComboBoxGroup } from './ComboBoxGroup';
import { BaseBarcode } from '../../controls/BaseBarcode';
import { DefaultImage } from '../../infrastructure/AppConstants';
import { OrderTextBox } from '../OrderTextBox';

export class CustomizedMachineModelInf extends Component {
    static displayName = CustomizedMachineModelInf.name;
    constructor(props) {
        super(props);

        var customizedMachineModel = props.model.customizedMachineModel;
        let leftEnclousureType = '-1';
        let leftWireless = '0';
        let leftLine = '-1';
        let leftSpecsMatrix = '-1';
        let leftColor = '-1';
        let rightEnclousureType = '-1';
        let rightWireless = '0';
        let rightLine = '-1';
        let rightSpecsMatrix = '-1';
        let rightColor = '-1';

        if (customizedMachineModel) {
            var array = customizedMachineModel.split(';');
            if (array.length === 2) {
                var childArray = array[0].split(',');
                if (childArray.length === 5) {
                    leftEnclousureType = childArray[0];
                    leftWireless = childArray[1];
                    leftLine = childArray[2];
                    leftSpecsMatrix = childArray[3];
                    leftColor = childArray[4];
                }
                var rChildArray = array[1].split(',');
                if (rChildArray.length === 5) {
                    rightEnclousureType = rChildArray[0];
                    rightWireless = childArray[1];
                    rightLine = rChildArray[2];
                    rightSpecsMatrix = rChildArray[3];
                    rightColor = rChildArray[4];
                }
            }
        }

        this.state = {
            uploading: false, data: null,
            leftEnclousureType: leftEnclousureType, leftWireless: leftWireless, leftLine: leftLine, leftSpecsMatrix: leftSpecsMatrix, leftColor: leftColor,
            rightEnclousureType: rightEnclousureType, rightWireless: rightWireless, rightLine: rightLine, rightSpecsMatrix: rightSpecsMatrix, rightColor: rightColor,
            antiEarwaxDevice: props.model.antiEarwaxDevice, vent: props.model.vent, onlyForRICSeries: props.model.onlyForRICSeries,
            attachment: props.model.attachment
        };
        this.handleLeftChange = this.handleLeftChange.bind(this);
        this.handleRightChange = this.handleRightChange.bind(this);
        this.handleSelectChanged = this.handleSelectChanged.bind(this);
        this.handleAntiEarwaxDeviceChanged = this.handleAntiEarwaxDeviceChanged.bind(this);
        this.handleVentChanged = this.handleVentChanged.bind(this);
        this.handleOnlyForRICSeriesChanged = this.handleOnlyForRICSeriesChanged.bind(this);
        this.handleAttachmentChanged = this.handleAttachmentChanged.bind(this);
    }

    handleAttachmentChanged(e) {
        if (this.state.data) {
            let attachment = e.target.value;
            this.setState({ attachment: attachment });
        }
    }

    handleOnlyForRICSeriesChanged(e) {
        if (this.state.data) {
            this.setState({ onlyForRICSeries: e });
        }
    }

    handleVentChanged(e) {
        if (this.state.data) {
            this.setState({ vent: e });
        }
    }
    handleAntiEarwaxDeviceChanged(e) {
        if (this.state.data) {
            this.setState({ antiEarwaxDevice: e });
        }
    }

    handleSelectChanged(e) {
        var handler = this.onSelectChanged;
        if (handler) {
            handler(e);
        }
    }

    componentDidMount() {
        this.populateProductOptionData();
        this._subscription = orderService.subscribe(() => this.orderCommitting());
    }

    orderCommitting() {
        orderService.updateCustomizedMachineProductOptions(this.state);
    }

    componentWillUnmount() {
        orderService.unsubscribe(this._subscription);
    }


    async populateProductOptionData() {
        const token = await authService.getAccessToken();
        const response = await fetch('ProductOption/GetCustomizedMachineProductOptions', {
            headers: !token ? {} : { 'Authorization': `${token}` }
        });
        const data = await response.json();
        this.setState({ data: data, loading: false });
    }


    handleLeftChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        if (name == 'leftEnclousureType') {
            this.setState({ leftEnclousureType: value });
        }
        else if (name == 'leftWireless') {
            this.setState({ leftWireless: value });
        }
        else if (name == 'leftLine') {
            this.setState({ leftLine: value });
        }
        else if (name == 'leftSpecsMatrix') {
            this.setState({ leftSpecsMatrix: value });
        }
        else if (name == 'leftEnclosureColor') {
            this.setState({ leftColor: value });
        }
    }

    handleRightChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        if (name == 'rightEnclousureType') {
            this.setState({ rightEnclousureType: value });
        }
        else if (name == 'rightWireless') {
            this.setState({ rightWireless: value });
        }
        else if (name == 'rightLine') {
            this.setState({ rightLine: value });
        }
        else if (name == 'rightSpecsMatrix') {
            this.setState({ rightSpecsMatrix: value });
        }
        else if (name == 'rightEnclosureColor') {
            this.setState({ rightColor: value });
        }
    }

    getLines(enclosureId) {
        let data = this.state.data;
        if (data) {
            var model = data.customizedMachineModel;
            var lines = new Array();
            for (let i = 0; i < model.length; i++) {
                if (model[i].enclosureType.id == enclosureId) {
                    let id = model[i].line.id;
                    if (!this.contains(lines, id)) {
                        lines.push({ id: id, name: model[i].line.name });
                    }

                }
            }
            return lines;
        }

        return null;
    }

    getMatrix(enclosure, line) {
        let data = this.state.data;
        if (data) {
            var model = data.customizedMachineModel;
            var matrixes = new Array();
            for (let i = 0; i < model.length; i++) {
                if (model[i].enclosureType.id == enclosure && model[i].line.id == line) {
                    let id = model[i].specsMatrix.id;
                    if (!this.contains(matrixes, id)) {
                        matrixes.push({ id: id, name: model[i].specsMatrix.name });
                    }
                }
            }
            return matrixes;
        }

        return null;
    }

    contains(array, id) {
        var returnValue = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                returnValue = true;
                break;
            }
        }

        return returnValue;
    }

    render() {
        let items = [];
        let leftLines = [];
        let leftSpecsMatrixes = [];
        let enclosureColors = [];
        let leftDesc = '';
        let leftProductCode = 'notexist';
        let leftRicCode = 'notexist';
        let rightLines = [];
        let rightSpecsMatrixes = [];
        let rightDesc = '';
        let rightProductCode = 'notexist';
        let rightRicCode = 'notexist';
        let antiEarwaxDeviceItems = null;
        let ventItems = null;
        let onlyForRICSeriesItems = null;
        let attachmentItems = [];
        let attachmentCode = '';
        let otherOptions = [];
        let cord = [];
        let shellCoating = [];
        let data = this.state.data;
        if (data) {
            antiEarwaxDeviceItems = data.antiEarwaxDevice;
            ventItems = data.vent;
            onlyForRICSeriesItems = data.onlyForRICSeries;
            attachmentItems = data.attachment;
            otherOptions = data.otherOptions;
            cord = data.cord;
            shellCoating = data.shellCoating;

            for (let i = 0; i < attachmentItems.length; i++) {
                if (attachmentItems[i].id == this.state.attachment) {
                    attachmentCode = attachmentItems[i].code;
                    break;
                }
            }
            items = data.enclosureTypes;

            const { leftEnclousureType, leftWireless, leftLine, leftSpecsMatrix } = this.state;
            if (leftEnclousureType != "-1") {
                leftLines = this.getLines(leftEnclousureType);
            }
            if (this.state.leftLine != "-1") {
                leftSpecsMatrixes = this.getMatrix(this.state.leftEnclousureType, this.state.leftLine);
            }
            if (this.state.leftSpecsMatrix != "-1") {
                if (leftLine != "-1" && leftSpecsMatrix != "-1") {
                    let data = this.state.data;
                    if (data) {
                        let model = data.customizedMachineModel;
                        let leftWL = parseInt(leftWireless);
                        for (let i = 0; i < model.length; i++) {
                            if (model[i].enclosureType.id == leftEnclousureType && model[i].line.id == leftLine && model[i].specsMatrix.id == leftSpecsMatrix && leftWL == model[i].wireless) {
                                leftDesc = model[i].description;
                                leftProductCode = model[i].productCode;
                                leftRicCode = model[i].ricCode;
                                break;
                            }
                        }
                    }
                }
            }
            const { rightEnclousureType, rightWireless, rightLine, rightSpecsMatrix } = this.state;
            if (rightEnclousureType != "-1") {
                rightLines = this.getLines(rightEnclousureType);
            }
            if (this.state.rightLine != "-1") {
                rightSpecsMatrixes = this.getMatrix(this.state.rightEnclousureType, this.state.rightLine);
            }
            if (this.state.rightSpecsMatrix != "-1") {
                if (rightLine != "-1" && rightSpecsMatrix != "-1") {
                    let data = this.state.data;
                    if (data) {
                        let model = data.customizedMachineModel;
                        let rightWL = parseInt(rightWireless);
                        for (let i = 0; i < model.length; i++) {
                            if (model[i].enclosureType.id == rightEnclousureType && model[i].line.id == rightLine && model[i].specsMatrix.id == rightSpecsMatrix && rightWL == model[i].wireless) {
                                rightDesc = model[i].description;
                                rightProductCode = model[i].productCode;
                                rightRicCode = model[i].ricCode;
                                break;
                            }
                        }
                    }
                }
            }
            enclosureColors = data.enclosureColors;
        }

        return (
            <table className="">
                <tbody>
                <tr className="align-top">
                    <td width="35%">
                        <div className="col-span-3">
                            <div className="border-b-2 border-blue-900 text-blue-800 font-bold"><h2><FormattedMessage id="CustomizedMachineModel" /></h2></div>
                            <div className="grid grid-cols-5 text-left">

                                <div className="grid grid-rows-3  py-1 border-b border-gray-100 text-left">
                                    <label></label>
                                    <div className="flex"><label className="place-self-center"><FormattedMessage id="EnclosureType" /></label></div>
                                    <div className="place-self-end text-red-500 transform scale-75"><label className="break-all"><FormattedMessage id="EnclosureTypeNote" /></label></div>
                                </div>
                                <div className="col-span-2">
                                    <div className="bg-blue-200 flex justify-center ">
                                        <label className="mr-1"><FormattedMessage id="Left" /></label>
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-4 w-4 lg:h-4 lg:w-4 rounded-full bg-blue-500 sm:mx-0 sm:h-5 sm:w-5">
                                            <label className="text-white">L</label>
                                        </div>
                                    </div>

                                    {items.map((item, index) =>
                                        <div key={index} className="flex justify-start items-center mt-1 py-2 border-b border-gray-100">
                                            <img className="w-24 h-12 bg-blue-200 rounded-md" src={item.imageUrl} />
                                            <input className="mx-2" type="radio" name="leftEnclousureType" onChange={this.handleLeftChange} checked={this.state.leftEnclousureType === item.id} value={item.id} />{item.name}
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-2">
                                    <div className="bg-red-200 flex justify-center">
                                        <label className="mr-1"><FormattedMessage id="Right" /></label>
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-4 w-4 lg:h-4 lg:w-4 rounded-full bg-red-500 sm:mx-0 sm:h-5 sm:w-5">
                                            <label className="text-white">R</label>
                                        </div>
                                    </div>
                                    {items.map((item, index) =>
                                        <div key={index} className="flex justify-start items-center mt-1 py-2 border-b border-gray-100">
                                            <img className="w-24 h-12 bg-red-200 rounded-md" src={item.imageUrl} />
                                            <input className="mx-2" type="radio" name="rightEnclousureType" onChange={this.handleRightChange} checked={this.state.rightEnclousureType === item.id} value={item.id} />{item.name}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td width="25%">
                        <div className="ml-4">
                            <div className="text-white"><h2>.</h2></div>
                            <table className="border-b-2 border-blue-300 w-full">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <div className="border-2 border-blue-300 flex flex-row">
                                                <div className="bg-blue-500 h-8 w-12 flex flex-row items-center">
                                                    <label className="text-white mr-1"><FormattedMessage id="Left" /></label>
                                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-4 w-4 lg:h-4 lg:w-4 rounded-full bg-gray-50 sm:mx-0 sm:h-5 sm:w-5">
                                                        <label className="text-blue-500">L</label>
                                                    </div>
                                                </div>
                                                <label className="place-self-center">{leftDesc}</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-2"><BaseBarcode value={leftProductCode} /></td>
                                        <td><BaseBarcode className="ml-2" value={leftRicCode} /></td>
                                    </tr>
                                    <tr className="text-blue-300 text-xs"><td><FormattedMessage id="ProductCode" /></td><td><FormattedMessage id="RicCode" /></td></tr>
                                </tbody>
                            </table>
                            <table className="border-b-2 border-red-300 w-full">
                                <tbody>
                                    <tr><td colSpan="2">
                                        <div className="border-2 border-red-300 flex flex-row mt-4">
                                            <div className="bg-red-500 h-8 w-12 flex flex-row items-center">
                                                <label className="text-white  mr-1"><FormattedMessage id="Right" /></label>
                                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-4 w-4 lg:h-4 lg:w-4 rounded-full bg-gray-50 sm:mx-0 sm:h-5 sm:w-5">
                                                    <label className="text-red-500">R</label>
                                                </div>
                                            </div>
                                            <label className="place-self-center">{rightDesc}</label>
                                        </div>
                                    </td></tr>
                                    <tr>
                                        <td className="py-2"><BaseBarcode value={rightProductCode} /></td>
                                        <td><BaseBarcode className="ml-2" value={rightRicCode} /></td>
                                    </tr>
                                    <tr className="text-red-300 text-xs"><td><FormattedMessage id="ProductCode" /></td><td><FormattedMessage id="RicCode" /></td></tr>
                                </tbody>
                            </table>

                            <div className="border-b-2 border-blue-900 text-blue-800 mt-4 font-bold"><h2><FormattedMessage id="Attachment" /></h2></div>
                            <div className="grid grid-cols-2 mt-2">
                                <select className="border-2 border-gray-100 h-8" value={this.state.attachment ?? ""} onChange={this.handleAttachmentChanged}>
                                    <FormattedMessage id="PickOne" >
                                        {text => <option value="-1">{text}</option>}
                                    </FormattedMessage>
                                    {attachmentItems == null ? '' : attachmentItems.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                                </select>
                                <input className="bg-gray-100 h-8 text-center" readOnly="readonly" value={attachmentCode} />
                            </div>
                        </div>
                    </td>
                    <td width="15%">                            
                        <ComboBoxGroup title="AntiEarwaxDevice" items={antiEarwaxDeviceItems} value={this.state.antiEarwaxDevice} onSelectValueChanged={this.handleAntiEarwaxDeviceChanged} />
                        <ComboBoxGroup title="Vent" items={ventItems} value={this.state.vent} onSelectValueChanged={this.handleVentChanged} />
                        <ComboBoxGroup title="OnlyForRICSeries" items={onlyForRICSeriesItems} value={this.state.onlyForRICSeries} onSelectValueChanged={this.handleOnlyForRICSeriesChanged} />                       
                    </td>
                    <td>
                        <div className="flex-1 ml-4">
                            {otherOptions.length > 0 ? <OtherOptions otherOptions={otherOptions} otherOptionsValue={this.props.model.otherOptions}
                                cord={cord} cordValue={this.props.model.cord} shellCoating={shellCoating} shellCoatingValue={this.props.model.shellCoating} /> : null}
                        </div>
                    </td></tr>
                <tr className="align-top">
                    <td>
                        <div className="grid place-content-stretch gap-y-4 h-full">
                            <div className="grid grid-cols-5 text-left">
                                <label><FormattedMessage id="IsWireless" /></label>
                                <div className="col-span-2">
                                    <input className="mr-4" type="radio" name="leftWireless" onChange={this.handleLeftChange} checked={this.state.leftWireless === "0"} value="0" />无
                            <input className="mx-4" type="radio" name="leftWireless" onChange={this.handleLeftChange} checked={this.state.leftWireless === "1"} value="1" />WL
                            </div>
                                <div className="col-span-2">
                                    <input className="mr-4" type="radio" name="rightWireless" onChange={this.handleRightChange} checked={this.state.rightWireless === "0"} value="0" />无
                            <input className="mx-4" type="radio" name="rightWireless" onChange={this.handleRightChange} checked={this.state.rightWireless === "1"} value="1" />WL
                        </div>
                            </div>
                            <div className="grid grid-cols-5 text-left">
                                <div className="flex">
                                    <label className="place-self-center"><FormattedMessage id="Line" /></label>
                                </div>
                                <select className="col-span-2 border-2 border-gray-100 h-8" name="leftLine" value={this.state.leftLine} onChange={this.handleLeftChange}>
                                    <FormattedMessage id="PickOne" >
                                        {text => <option value="-1">{text}</option>}
                                    </FormattedMessage>
                                    {leftLines.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                                </select>
                                <select className="col-span-2 border-2 border-gray-100 h-8" name="rightLine" value={this.state.rightLine} onChange={this.handleRightChange}>
                                    <FormattedMessage id="PickOne" >
                                        {text => <option value="-1">{text}</option>}
                                    </FormattedMessage>
                                    {rightLines.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                                </select>
                            </div>
                            <div className="grid grid-cols-5 text-left">
                                <div className="flex">
                                    <label className="place-self-center"><FormattedMessage id="SpecsMatrix" /></label>
                                </div>
                                <select className="col-span-2 border-2 border-gray-100 h-8" name="leftSpecsMatrix" value={this.state.leftSpecsMatrix} onChange={this.handleLeftChange}>
                                    <FormattedMessage id="PickOne" >
                                        {text => <option value="-1">{text}</option>}
                                    </FormattedMessage>
                                    {leftSpecsMatrixes.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                                </select>
                                <select className="col-span-2 border-2 border-gray-100 h-8" name="rightSpecsMatrix" value={this.state.rightSpecsMatrix} onChange={this.handleRightChange} >
                                    <FormattedMessage id="PickOne" >
                                        {text => <option value="-1">{text}</option>}
                                    </FormattedMessage>
                                    {rightSpecsMatrixes.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                                </select>
                            </div>
                            <div className="grid grid-cols-5 text-left">
                                <div className="flex">
                                    <label className="place-self-center"><FormattedMessage id="EnclosureColor" /></label>
                                </div>
                                <select className="col-span-2 border-2 border-gray-100 h-8" name="leftEnclosureColor" value={this.state.leftColor} onChange={this.handleLeftChange}>
                                    <option value="-1">默认 Default Color</option>
                                    {enclosureColors.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                                </select>
                                <select className="col-span-2 border-2 border-gray-100 h-8" name="rightEnclosureColor" value={this.state.rightColor} onChange={this.handleRightChange}>
                                    <option value="-1">默认 Default Color</option>
                                    {enclosureColors.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                                </select>
                            </div>
                        </div>
                    </td>
                    <td colSpan="3">
                        <table className="col-span-5">
                            <tbody>
                                <tr>
                                    <td width="75%">
                                        <div className="bg-red-50 mx-4 text-red-800">
                                            <h2 className="text-xs"><FormattedMessage id="OrderFillingInstructions" /></h2>
                                            <div className="transform scale-90">
                                                <label><FormattedMessage id="OrderFillingInstructionsContent" /></label>
                                            </div>
                                        </div>
                                    </td>
                                    <td rowSpan="2">
                                        <div className="text-gray-100 text-xs italic">
                                            Template: SZH_F_ITE_017 - V1
                                            Template effective: 2021-07-01
                                            Template Editor: Ma Rongrong, Yuan Jiyi
                                            Copyright © WSAUD A/S.
                                            All rights reserved.
                                            Change Number :
                                            QMS_CR_2021_2522
                                            Restricted
                                            page 1 of 2
                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td rowSpan="2" className="text-gray-300 text-xs italic" width="65%">
                                                        <div className="transform scale-90">
                                                            <FormattedMessage id="OrderFillingInstructionsClaim" />
                                                        </div>
                                                    </td>
                                                        <td><label className="place-self-center"><FormattedMessage id="FillingPerson" /></label></td>
                                                        <td className="pr-5"><OrderTextBox classnames="w-full" readOnly="readOnly" /></td>
                                                </tr>
                                                <tr>
                                                        <td><label className="place-self-center"><FormattedMessage id="Date" /></label></td>
                                                        <td className="pr-5"><OrderTextBox classnames="w-full" readOnly="readOnly"  /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td></tr>
                </tbody>
            </table>
        );
    }
}
