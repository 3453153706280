import React, { Component } from 'react';
import JsBarcode from 'jsbarcode';

export class BaseBarcode extends Component {
    constructor(props) {
        super(props);
        this.state = { value: this.props.value };
    }


    componentDidMount() {
        this.createBarcode();
    }

    

    createBarcode = () => {

        if (!this.barcode) return;

        const {

            width = 1, height = 30, margin = 0, value, displayValue = true,

        } = this.props;

        if (!value) {

            return;

        }
        
        JsBarcode(this.barcode, value, {
            
            displayValue: displayValue,

            width: width,

            height: height,

            margin: margin,

        });

        this.setState({ value: value });
    };

    render() {

        const {

            labelClassName, value, labelStyle, className, style, displayValue = true,

        } = this.props;
        if (value != this.state.value) {
            this.createBarcode();
        }
        return (

            <div className={className} style={style}>

                <svg

                    ref={(ref) => {

                        this.barcode = ref;

                    }}

                />

                {displayValue ? null : <p className={labelClassName} style={labelStyle}>{value}</p>}

            </div>

        );

    }

}
