import React, { Component, Fragment } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { FormattedMessage } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react';
import Select from 'react-select';

export class AttachAccountDialog extends Component {
    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.bindDealer = this.bindDealer.bind(this);
        this.state = { selected: [], matchItems: [], dealer: null }
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    async handleOnSubmit(e) {
        e.preventDefault();
        var accountIds = this.state.selected.map(x => { return x.value });
        var id = this.state.dealer.id;
        if (!id || accountIds.length == 0) {
            return;
        }
        const token = await authService.getAccessToken();
        await fetch('dealer/AttachAccount', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `${token}` },
            body: JSON.stringify({
                'accountIds': accountIds,
                'dealerId': id
            })
        }).then(data => {
            this.onClose();
            this.props.populateListData();
        })
    }
    bindDealer(dealer) {
        this.setState({ dealer: dealer, selected: dealer.accounts.map(x => { return { label: x.name, value: x.id } }) });
    }
    onClose() {
        this.props.onClose();
        this.clearForm();
    }
    onSelectChange = selectedOption => {
        this.setState({
            selected: selectedOption
        })
    };
    handleOnSearch(keyword, e) {
        if (keyword.trim().length > 0) {
            authService.getAccessToken().then(token => {
                fetch('account/GetAccounts?keyword=' + keyword + "&hasDealer=false", { headers: !token ? {} : { 'Authorization': `${token}` } })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({ matchItems: data.entities.map((item) => { return { value: item.id, label: item.name } }) });
                    })
            });
        }
    }
    clearForm() {
        this.setState({ selected: [], matchItems: [], dealer: null });
    }
    render() {
        const open = this.props.open;
        const IndicatorSeparator = ({ innerProps }) => {
            return <span style={{
                alignSelf: 'stretch',
                marginBottom: 8,
                marginTop: 8,
                width: 1,
            }} {...innerProps} />;
        };
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    open={open}
                    onClose={this.onClose}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <form onSubmit={this.handleOnSubmit} style={{ width: '100vw' }}>
                                <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="">
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900"><FormattedMessage id="AttachAccount" /></Dialog.Title>
                                                <div className="mt-2">
                                                    <label className="inline-block w-20 text-right mr-2"><FormattedMessage id="Account" /></label>
                                                    <FormattedMessage id="InputAccountName" >
                                                        {placeholder =>
                                                            <Select className="min-w-0 w-4/5 inline-block" onChange={this.onSelectChange}
                                                                closeMenuOnSelect={true}
                                                                components={{ IndicatorSeparator }}
                                                                value={this.state.selected}
                                                                isMulti
                                                                options={this.state.matchItems}
                                                                onInputChange={this.handleOnSearch}
                                                                placeholder={placeholder}
                                                            />
                                                        }</FormattedMessage>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={this.onClose}><FormattedMessage id="cancel" /></button>
                                        <button type="submit"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"><FormattedMessage id="Submit" /></button>

                                    </div>
                                </div>
                            </form>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root >
        )
    }
}