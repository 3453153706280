import React, { Component } from 'react';
import { Login } from './api-authorization/Login';
import { NavMenu } from './NavMenu';
import { Sidebar } from './Sidebar';
import authService from './api-authorization/AuthorizeService';
import { LoginActions } from './api-authorization/ApiAuthorizationConstants';

export class Layout extends Component {
  static displayName = Layout.name;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isAuthenticated: false,
            user: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            user: user,
            loading: false
        });
    }

    render() {
        const { loading, isAuthenticated, user } = this.state;
        if (loading) {
            return null;
        }
        if (!isAuthenticated) {            
            return this.loginView();
        } else {            
            return this.authenticatedView(user);
        }
    }

    loginView() {
        return (
            <div className="">
                <NavMenu />                
                <Login action={LoginActions.Login} />
                <div className="text-center"><a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022006725号-1</a>
                    <p>Copyright © 2022 WYF All Rights Reserved</p>
                </div>
            </div>
        );
    }

    authenticatedView (user) {
    return (
        <div className="">
            <NavMenu user={user} />
            <div className="flex overflow-hidden bg-white pt-16">
                <Sidebar user={user} />
                <div className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
                <div id="main-content" className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
                    <main>
                        
                            {this.props.children}
                       
                    </main>
                </div>
            </div>            
      </div>
    );
  }
}
